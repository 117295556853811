import LabeledSpanPair from "../../../../../../lib/components/decorations/LabeledSpanPair";
import SimpleRow from "../../../../../../lib/components/page/SimpleRow";
import { BaseComponent } from "../../../../../../lib/types";
// import SomeDate from "../../dc_base/components/utility/SomeDate";
import { Payloader } from "../../services/types";
import {strToVariant} from "../../../../../../lib/utils/strToVariant";

const theme = (status:string) => {
    switch(status){
        case "Expired":
            return "text-fuchsia-700"
        case "Cancelled":
            return "text-neutral-600"
        case "Failed":
            return "text-red-600"
        case "Pending":
            return "text-amber-600"
        case "Started":
            return "text-blue-600"
        case "Succeeded":
            return "text-green-700"
        default:
            return "text-black"
    }
}

const themeBgColor = (status:string) => {
    switch(status){
        case "Expired":
            return "bg-fuchsia-200"
        case "Cancelled":
            return "bg-neutral-50"
        case "Failed":
            return "bg-red-200"
        case "Pending":
            return "bg-amber-200"
        case "Started":
            return "bg-blue-200"
        case "Succeeded":
            return "bg-green-200"
        default:
            return "bg-neutral-200"
    }
}
const DeviceOperationsSummary : BaseComponent<{
    deviceSequenceInstance:Payloader.Base.DeviceSequence
}> = (props) => {
    let even=false;
    return <>
        {/* <div class="p-2">
            {props.deviceSequenceInstance?.state?.message}
        </div> */}
        {props.deviceSequenceInstance?.operationInstances?.map((op,it)=>{
            even=!even;


            return <SimpleRow id={`device-${props.id}-op-${it}`}
                        label={op?.operation?.type + `${op.operation?.fileName
                            ?` - ${op.operation?.fileName}`
                            :""
                        }`}
                        value={<div class={theme(op?.state?.status)}>
                            <LabeledSpanPair 
                                id={`device-${props.id}-op-${it}-status`}
                                title="Operation Status"
                                type={strToVariant(op?.state?.status)}
                                labelText={op?.state?.status}
                                valueText={op?.state?.completedTime
                                    ?? op?.state?.startedTime
                                    ?? op?.state?.createdTime??"No Date"}
                            />
                        </div>}
                        even={even}
                    />
                    
        })?.length
            ?props.deviceSequenceInstance?.operationInstances?.map((op,it)=>{
                even=!even;
    
                return <SimpleRow id={`device-${props.id}-op-${it}`}
                            label={<div class={theme(op?.state?.status)}>
                                <LabeledSpanPair 
                                    id={`device-${props.id}-op-${it}-status`}
                                    title="Operation Status"
                                    type={strToVariant(op?.state?.status)}
                                    labelText={op?.state?.status}
                                    valueText={
                                        op?.operation?.type + `${op.operation?.fileName
                                        ?` - ${op.operation?.fileName}`
                                        :""
                                    }`}
                                />
                            </div>}
                            value={op?.state?.completedTime
                                    ?? op?.state?.startedTime
                                    ?? op?.state?.createdTime??"No Date"
                                }
                            even={even}
                        />
                        
            })
        :<div class="p-1">No operations for this device.</div>}
    </>
}

export default DeviceOperationsSummary;