import { Component, ParentProps, createContext, useContext } from "solid-js";
import manager from "./manager";

const TokenContext = createContext(manager);

const TokenProvider:Component<ParentProps> = (props) => {
    return <TokenContext.Provider value={manager}>
        {props.children}
    </TokenContext.Provider>
};

const useToken = () => {
    return useContext(TokenContext);
};

export {useToken, TokenProvider};
