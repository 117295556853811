import { Accessor, Resource, ResourceReturn, createResource } from "solid-js";
import tmsService from "../../services/tms.js";
import pscsService from "../../services/pscs.js";
import { useToken } from "../Token/TokenProvider.js";
import type * as Payloader from "../../services/types/tms.d.ts";
import { RouteLoadFunc, RouteLoadFuncArgs } from "@solidjs/router";
import { useAlerts } from "../../../../../../lib/context/alert/AlertProvider.jsx";
import {Base} from "../../services/types/tms";
import ItemSpec = Base.ItemSpec;
import Job = Base.Job;
import SomeJob = Base.SomeJob;
import { PartAlert } from "../../../../../../lib/context/alert/manager.js";


const [_, {addAlert}] = useAlerts();

const handleTMSError = (err:any) => {
    addAlert({
        type:"error",
        code: err?.response?.data?.title??err.code,
        message: err?.response?.data?.detail??err.message
    });
}

const [{tmsToken, pscsToken}] = useToken();

// remove all the other aspects of tmsService
const tmsCaller = tmsService.tmsCaller(tmsToken);

const models : Array<Payloader.Base.Model> =[]; 
/************************************  V2 *************************************/
// TODO: fix addAlert

// LINKING CODE
const fetchLinkingCode = async () =>{
    try {
        return (await tmsCaller.getLinkingCode())?.code;
    } catch(err) {
        console.log(err);
        return "Could not retrieve linking code.";
    }
};

/* SLOTS */

//models
const fetchModelsList = async () => {
    try{
        const modelDetails : Array<Payloader.Base.ModelV2Details> = [];
        const modelsList = (await tmsCaller.getModelsList());
        return modelsList;
    }catch(err){
        handleTMSError(err);
    }
}
const loadModelsList = () => {
    return createResource(
        fetchModelsList
    )
}


// TODO: this should be one endpoint!!!!!!!
const fetchModelsOnlyList = async () => {
    try{
        const modelsList = (await tmsCaller.getModelsList());
        
        return modelsList;
    }catch(err){
        handleTMSError(err);
    }
}
const loadModelsOnlyList = () => {
    return createResource(
        fetchModelsOnlyList
    )
}

//slots
const fetchSlotsList = async () => {
    try{
        return (await tmsCaller.getSlotsList())
    }catch(err){
        handleTMSError(err);
    }
}
const fetchModelSlotsList = async (modelName:string) => {
    try{
        return await tmsCaller.getModelSlotsList({
            DeviceModel:modelName
        })
    }catch(err){
        handleTMSError(err);
    }
}
const loadSlotsList = () => {
    return createResource(
        ()=>true,
        fetchSlotsList
    )
}
const loadModelSlotsList = (modelName:Accessor<string>) => {
    return createResource(
        modelName,
        fetchModelSlotsList
    )
}

/* PAYLOADS */
const fetchPayloadList = async () => {
    try{
        return ((await tmsCaller.getPayloads()))
    }catch(err){
        handleTMSError(err);
    }
}
const fetchPayload = async (id:string) => {
    try{
        return (await tmsCaller.getPayload(id))
    }catch(err){
        handleTMSError(err);
    }
}
const loadPayloadList = () => {
    return createResource(fetchPayloadList);
}
const loadPayloads = () => {
    return createResource(fetchPayloadList);
}
const loadSinglePayload = (id:string) => {
    return createResource(()=>id,fetchPayload);
}
const loadPayload = (args:RouteLoadFuncArgs) => {
    return createResource(()=>args.params.id,fetchPayload);
}
const sendDeletePayload = async (id: string) => {
    try{
        await tmsCaller.deletePayload({
            id
        });
    }catch(err){
        handleTMSError(err);
    }
}
const sendCreatePayload = async (name:string,description:string) => {
    try{
        return await tmsCaller.createPayload({
            name,
            description
        })
    }catch(err){
        addAlert({
            message:"Could not create payload.",
            code:"Error",
            type:"error"
        });
        throw err;

    }
}
const sendEditPayload = async (id:string, details:{name?:string,description?:string}) => {
    try{
        return await tmsCaller.editPayload({
            id,
            ...details
        })
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
}
// const sendSetPayloadSlottedItems = async (id:string,
//     slots:{[keyof:string]:Payloader.Base.SlotSpec}
// ) => {
//     try{
//         return await tmsCaller.setPayloadSlottedItems({
//             id,
//             slots
//         })
//     }catch(err){
//         handleTMSError(err);
//     }
// }
const sendAddPayloadSlottedItems = async (
    id:string,
    deviceModelId:string,
    slotId:string,
    itemId:string,
    specifications:{[keyof:string]:string}
) => {

    try{
        return await tmsCaller.addPayloadSlottedItems({
            id,
            deviceModelId,
            slotId,
            itemId,
            specifications
        })
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
}
const sendRemovePayloadSlottedItems = async (
    id:string, 
    deviceModelId:string,
    slotId:string,
    itemId:string,
) => {
    try{
        return await tmsCaller.removePayloadSlottedItems({
            id,
            deviceModelId,
            slotId,
            itemId
        })
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
}

/* JOBS */
const createJobStatuses = (job:Payloader.Base.SomeJob) => {
    
    job.state.counts = {
        Succeeded:0,
        Failed:0,
        Cancelled:0,
        Expired:0,
        Pending:0,
        Started:0
    };

    for(const deviceSequence in job.deviceSequenceInstances){
        job.state.counts[job.deviceSequenceInstances[deviceSequence].state.status]++;
    }

    return job;
}

const fetchJobs = async () => {
    try{
        return (await tmsCaller.getJobs())?.map(createJobStatuses);
    }catch(err){
        handleTMSError(err);
    }
}
const fetchJobsForDevice = async (id:string) => {
    try{
        return ((await tmsCaller.getJobs()))?.filter(job=>job.deviceIds?.includes(id))?.map(createJobStatuses);
    }catch(err){
        handleTMSError(err);
    }
}
const loadJobsForDevice = (id:string) => {
    return createResource(()=>id,fetchJobsForDevice)
}
const loadJobsList = () => {
    return createResource(fetchJobs)
}


const fetchItems = async () => {
    try{
        return (await tmsCaller.getItems());
    }catch(err){ 
        handleTMSError(err);
    }
}
const loadItemsList = () => {
    return createResource(fetchItems)
}
const fetchItemsById = async (ids:Array<string>) => {
    if(ids.length===0){
        return [];
    }
    try{
        return (await tmsCaller.getItemsByIds({ids}));
    }catch(err){
        handleTMSError(err);
    }
}
const loadItemsListById = (ids:Accessor<Array<string>>) => {
    return createResource(ids, fetchItemsById)
}

const fetchJob = async (id:string) =>{
    try{
        const job = ((await tmsCaller.getJob({ids:id})))[0]
        return createJobStatuses(job)
    }catch(err){
        handleTMSError(err);
    }

}

const sendRequestLegacyPayloaderUpgrades = async(
    clients:Payloader.Base.ClientGrouping
)=>{
    try{
        return (await tmsCaller.requestLegacyPayloaderUpgrades(clients));
    } catch(err){
        handleTMSError(err);;
        throw err;
    }
};

const loadJob = (args:RouteLoadFuncArgs) => {
    return createResource(()=>args.params?.id, fetchJob)
}
const sendScheduleCodedJob = async (
    code:string,
    scheduledTime:string,
    expiredTime:string,
    clients:Payloader.Base.ClientGrouping    
) => {
    try{
        return (await tmsCaller.scheduleCodedJob(
            {code,scheduledTime,expiredTime,...clients}
        ));
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
};
const sendScheduleApplyPayloadJob = async (
    payloadId:string,
    scheduledTime:string,
    expiredTime:string,
    clients:Payloader.Base.ClientGrouping    
) => {
    try{
        return (await tmsCaller.scheduleApplyPayloadJob(
            {
                payloadId,
                scheduledTime,
                expiredTime,
                ...clients
            }
        ));
    }catch(err){
        handleTMSError(err);
        throw err;
    }
};
const sendCancelJob = async (id:string)=>{
    try{
        return (await tmsCaller.cancelJob(
            {id}
        ));
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
};

/* JOB GROUPS */
const fetchDeviceGroupsList = async () => {
    try{
        return (await tmsCaller.getDeviceGroupsList());
    }catch(err){
        handleTMSError(err);
    }
}
const loadDeviceGroupsList = () => {
    return createResource(fetchDeviceGroupsList)
}
const sendRemoveDeviceGroupClients = async (id:string, clients:{
    deviceIds?:Array<string>,
    deploymentIds?:Array<string>
}) => {
    try{
        return await tmsCaller.removeDeviceGroupClients(
            Object.assign({id},clients)
        )
    }catch(err){
        handleTMSError(err);
    }
}
const sendAddDeviceGroupClients = async (id:string, clients:{
    deviceIds?:Array<string>,
    deploymentIds?:Array<string>
}) => {
    try{
        return await tmsCaller.addDeviceGroupClients(
            Object.assign({id},clients)
        )
    }catch(err){
        handleTMSError(err);;
        throw(err);
    }
}
const sendSetDeviceGroupClients = async (id:string, clients:{
    deviceIds?:Array<string>,
    deploymentIds?:Array<string>
}) => {
    try{
        return await tmsCaller.setDeviceGroupClients(
            Object.assign({id},clients)
        )
    }catch(err){
        handleTMSError(err);;
        throw(err);
    }
}
const sendDeleteDeviceGroup = (refetch:Function) => async (id: string) => {
    try{
        await tmsCaller.deleteDeviceGroup({
            id
        });
        refetch();
    }catch(err){
        handleTMSError(err);
    }
}
const sendCreateDeviceGroup= async (name:string, clients:{
    deviceIds?:Array<string>,
    deploymentIds?:Array<string>
}) => {
    try{
        return await tmsCaller.createDeviceGroup(
            Object.assign({name},clients)
        )
    }catch(err){
        handleTMSError(err);;
        throw err;
    }
}


/* ITEMS */ //TODO - finish item endpoints
const sendUploadImageItem = async (form: FormData) => {
    try{
        return await tmsCaller.uploadImageItem(form);
    } catch(err){
        handleTMSError(err);;
        throw err;
    }
}
const sendUploadPaxPackageItem = async (form: FormData) => {
    try{
        return await tmsCaller.uploadPaxPackageItem(form);
    } catch(err){
        handleTMSError(err);;
        throw err;
    }
}
const fetchItemDownload = async(id:string) => {
    try{
        return await tmsCaller.downloadItem({Id:id});
    } catch(err){
        handleTMSError(err);
    }
}
const sendDeleteItem = async (id: string) => {
    try{
        await tmsCaller.deleteItem({
            id
        });
    }catch(err){
        handleTMSError(err);
    }
}

const fetchSlottableItemsList = async ([model, slot]:[string,string]) => {
    try{
        const items = (await tmsCaller.getSlottableItems({deviceModelId:model,slotId:slot}))
        return items??[]
    }catch(err){
        handleTMSError(err);
        throw err;
    }
}
const loadSlottableItemsList = (slot:Accessor<[string,string]>) => {
    return createResource(
        slot,
        fetchSlottableItemsList
    )
}

const fetchDeviceDetailsList = async (deviceIds:Array<string>) => {
    if(!deviceIds?.length) return [];
    try{
        const deviceList = await pscsService.getLinkedPinPadList(await pscsToken());
        const devices : Array<Element> = [];

        for(const device of deviceList?.querySelectorAll("LinkedPinPad")??[]){
            if(deviceIds.includes(device?.querySelector("PinPadSerialNum")?.textContent)){
                devices.push(device);
            }
        }
        return devices;
    }catch(err){
        handleTMSError(err);
    }
}
const loadDeviceDetailsList = (deviceIds?:Accessor<Array<string>>)=>{
    return createResource(deviceIds, fetchDeviceDetailsList);
}
// const fetchSingleDeviceDetails = async (id:string) => {
//     try{
//         const deviceList = await pscsService.getLinkedPinPadList(await pscsToken());
//         const deviceOut : Payloader.Base.Device = {
//             id:null,
//             deploymentId:null,
//             groupIds:null,
//             model:null
//         }

//         for(const device of deviceList?.querySelectorAll("LinkedPinPad")){
//             if(device?.querySelector("PinPadSerialNum")?.textContent===id){
//                 deviceOut.id = device?.querySelector("PinPadSerialNum")?.textContent
//                 deviceOut.deploymentId = device?.querySelector("DeploymentID")?.textContent
//                 // deviceOut.id = device?.querySelector("PinPadSerialNum")?.textContent
//                 deviceOut.model = device?.querySelector("Model")?.textContent
//             }
//         }
//         return deviceOut;
//     }catch(err){
//         handleTMSError(err);
//     }
// }
const loadSingleDeviceDetails = (args:RouteLoadFuncArgs)=>{
    return createResource(()=>[args.params.id], fetchDeviceDetailsList);
}


const loadLinkingCode = () => {
    return createResource(()=>true,fetchLinkingCode);
}

// const callApiaddAlert = async(apiFunction:Function,...args : Array<any>)=>{
//     try{
//         return await apiFunction(await tmsToken(), ...args);
//     }catch(err){
//         handleTMSError(err);
//     }
// }

// const doThing = async () : Promise<Payloader.Response.DeviceGroups> => {
//     return callApiaddAlert(tmsService.getJobs)
// }

const sendVerifyPayLoaderCode = async (id: string) => {
    const resp = await tmsCaller.getPayLoaderCodes({ids:id});
    if(resp.length){
        return resp;
    }
    addAlert({
        type:"error",
        code:"Incorrect Payloader Code",
        message:`Payloader code ${id} not found.`
    });
    throw {
        type:"error",
        code:"Incorrect Payloader Code",
        message:`Payloader code ${id} not found.`
    }
}

const loadPayLoaderCodes = async () => {
    return createResource(()=>true,tmsCaller.getAllPayLoaderCodes);
}

const manager = {
    // getters/objects
    state : {
        models
    },
    // setters/fetchers/operations
    calls : {

        //v2
        sendDeletePayload,
        sendCreatePayload,
        sendEditPayload,
        // sendSetPayloadSlottedItems,
        sendAddPayloadSlottedItems,
        sendRemovePayloadSlottedItems,
        sendScheduleApplyPayloadJob,
        sendRemoveDeviceGroupClients,
        sendAddDeviceGroupClients,
        sendSetDeviceGroupClients,
        sendDeleteDeviceGroup,
        sendCreateDeviceGroup,
        sendUploadImageItem,
        sendUploadPaxPackageItem,
        sendCancelJob,
        sendScheduleCodedJob,
        sendRequestLegacyPayloaderUpgrades,
        sendVerifyPayLoaderCode
    },
    // resource factory functions
    resources : {

        //v2
        loadSlotsList,
        loadModelSlotsList,
        loadDeviceGroupsList,
        loadDeviceDetailsList,
        loadItemsList,
        loadSlottableItemsList,
        loadModelsList,
        loadModelsOnlyList,
        loadJobsForDevice,
        loadPayloads,
        loadSinglePayload,
        loadJobsList,
        loadLinkingCode,
        loadPayLoaderCodes,
        loadItemsListById,

    },
    dataFunctions:{

        //v2
        loadJob,
        loadSingleDeviceDetails,
        loadPayloadList, // for PayloadList
        loadPayload, // for Payload Page (should be endpoint on server but fine as is for now)
        loadJobsList, // for JobHistory

    }
};

export default manager;
