import {Component, createMemo} from "solid-js"
import icons from '../icons-config/icons'
import {getLayoutFromLocalStorage} from '../../layout/core'

type Props = {
    class?: string
    iconType?: 'duotone' | 'solid' | 'outline'
    iconName: string
}

const KTIcon: Component<Props> = (props) => {

    const iconType = createMemo(() => props.iconType ?? getLayoutFromLocalStorage().main?.iconType??"duotone");

    return (
        <i class={`ki-${iconType()} ki-${props.iconName} ${props.class??""}`}>
            {iconType() === 'duotone' &&
                [...Array(icons[props.iconName])].map((_e, i) => {
                    return (
                        <span
                            id={`${iconType()}-${props.iconName}-${props.class ?? ""}-path-${i + 1}`}
                            class={`path${i + 1}`}
                        ></span>
                    )
                })}
        </i>
    )
}

export {KTIcon}
