import { ParentProps } from "solid-js";
import { BaseComponent} from "../../types";

const Some : BaseComponent<ParentProps<{array:Array<any>, item:string}>> = (props) => {
    return <>
        {props.array?.length===0
            ?<div class="opacity-70">{`No ${props.item}`}</div>
            :<>{props.children}</>}
    </>
}

export default Some;