

declare module Base {

    export type ItemSpec = {
        [keyof:string]:{
            [keyof:string]:string
        }
    }

    export type LinkingCode = {
        id: string,
        code: string,
        userId: number
    }

    //v2 
    export type ClientGrouping = {
        deviceGroupIds?:Array<string>,
        deploymentIds?:Array<string>,
        deviceIds?:Array<string>,
    }

    //v2
    export type ModelV2<T={}> = {
        id?:string,
        name:string,
        oem:string,
        slots?:{[keyof:string]:ModelSlotType}
    } & T;
    //v2
    export type ModelSlotV2<T extends {type:string} = {type:string}> = Omit<{
        type:string,
        id:string,
        name:string,
        description:string,
        deviceModel:string,
        maxItems:number,
        loadPriority:number,
        specificationConstraints:{
            [keyof:string]:{
                type:string,
                maxLength:number,
                description:string
            }
        }
    }, "type"> & T

    //v2
    export type ImageSlot = ModelSlotV2<{
        type:"ImageSlot",
        minWidth:number,
        minHeight:number,
        aspectRatio:string,
        maxWidth:number,
        maxHeight:number,
        allowedContentTypes:Array<string>,
    }>


    //v2
    export type PaxPackageSlot = ModelSlotV2<{
        type:"PaxPackageSlot",
        packageName:string | null,
        packageVersion:string | null
    }>
    //v2
    export type ModelSlotType = ModelSlotV2<ImageSlot | PaxPackageSlot>
    //v2
    export type ModelV2Details = ModelV2<{
        slots:Array<ModelSlotType>
    }>
    export type ModelV2Only = ModelV2<{
        slotIds:Array<string>
    }>


    //v2
    interface OwnedObj{
        id:string,
        name:string,
        companyId:string
    }
    
    //v2
    interface Versioned {
        version:string
    }
    //v2
    interface Signable {
        isSigned:boolean
    }

    type FileData = {
        path:string,
        size:number,
        contentType:string,
        hash:string
    }

    type VersionedItem<AdditionalDetails={}> = {
        fileData:never,
        latestVersionName: string,
        versions: {
            [keyof:string]:{
                versionName:string,
                fileData:FileData
            } & AdditionalDetails
        }
    };

    type NonVersionedItem = {
        fileData:FileData,
        latestVersionName: never,
        versions:never
    };

    type TypedItem<
        TypeName,
        AdditionalAttrs = {},
        VersionSchema = NonVersionedItem
    > = {type: TypeName} 
        & AdditionalAttrs
        & OwnedObj
        & VersionSchema;

    
    export type ImageItem = TypedItem<
        "ImageItem",
        {
            height:number,
            width:number
        }
    >;

    export type PaxFormPackageItem = TypedItem<
        "PaxFormPackageItem",
        {
            formPackageName:string,
            uninstallerFileData?:FileData
        },
        VersionedItem
    >;
    export type AxiumFormPackageItem = TypedItem<
        "AxiumFormPackageItem",
        {
            formPackageName:string,
        }
    >;

    export type PaxApplicationItem = TypedItem<
        "PaxApplicationItem",
        {
            packageName:string,
            uninstallerFileData?:FileData
        },
        VersionedItem<{
            versionCode:number,
            minSdkVersion:number
        }>
    >;
    export type AxiumApplicationItem = TypedItem<
        "AxiumApplicationItem",
        {
            packageName:string,
            uninstallerFileData?:FileData
        },
        VersionedItem<{
            versionCode:number,
            minSdkVersion:number
        }>
    >;
    
    export type Item = ImageItem 
        | PaxFormPackageItem | AxiumFormPackageItem 
        | PaxApplicationItem | AxiumApplicationItem;

    
    //v2
    export type DeviceGroupBase<T = {id:string}> = T & {

        deviceIds?:Array<string>,
        deploymentIds?:Array<string>
    }
    //v2
    export type XMLDeviceGroupBase<T = {id:string}> = T & {
        deviceIds?:Array<Element>,
        deploymentIds?:Array<Element>
    }
    //v2
    export type DeviceGroupDetails = Base.DeviceGroupBase<OwnedObj>
    //v2
    export type DeviceGroupDetailsInternal = Base.DeviceGroupBase<OwnedObj> & {isNew?:boolean};
    //v2
    export type XMLDeviceGroupDetails = Base.XMLDeviceGroupBase<OwnedObj>
    
    //v2
    export interface Typed {
        type:string
    }

    //v2
    export interface Job extends Typed {}

    //v2
    export interface ApplyPayloadJob extends Job {
        type:"ApplyPayloadJob",
        payload:Payload
    }
    export interface CodedJob  extends Job {
        type:"CodedJob",
        code:string
    }


    //v2

    export type State<T="",V={}> = {
            status:T,
            createdTime:string,
            scheduledTime:string,
            message?:string,
            startedTime?:string,
            completedTime?:string,
        } & V

    //v2 
    export type Operation = {
        type:string,
        fileNameOverride?:string,
        fileName?:string
    }
    //v2
    export type DeviceSequence ={
        id:string,
        state:State<"Succeeded"|"Cancelled"|"Failed"|"Expired"|"Pending"|"Started">,
        operationInstances:Array<{
            id:string,
            state:State<"Succeeded"|"Cancelled"|"Failed"|"Expired"|"Pending"|"Started">,
            operation:Operation
        }>
    }

    //v2

    export interface SomeJob<T extends Job = Job> extends OwnedObj {
        state:State<"Completed"|"Pending"|"Started"|"Cancelled", {
            counts: {
                Succeeded?:number,
                Cancelled?:number,
                Failed?:number,
                Expired?:number,
                Pending?:number,
                Started?:number
            }
        }>,
        job:T,
        deviceIds:Array<string>
        deviceSequenceInstances?:{[keyof:string]:DeviceSequence}
    }

    //v2
    export type SlottedItem = {
        slotId:string,
        itemId:string
    }

    //v2
    export interface Payload extends OwnedObj {
        content:{[keyof:string]:{[keyof:string]:ItemSpec}},
        description?:string
    } 

    //v2
    export type Device = {
        id:string,
        deploymentId?:string,
        groupIds?:Array<string>,
        model?:string
    }



    type IdentifiedObj = {
        guid:string,
        name:string
    };
    export type DescribedObj = IdentifiedObj & {
        description?:string
    };
    export type Client = {
        id : string,
        type : string
    };
    export type SlotProperty = {
        name:string,
        value:number,
        constraint:string,
        required:boolean
    };
    export type ModelSlot = DescribedObj & {
        supertype:string,
        subtype?:string,
        properties?:Array<SlotProperty>
    };
    export type Model = IdentifiedObj & {
        oem:string,
        slots:Array<ModelSlot>
    };

    export type JobGroup = IdentifiedObj & {
        clients?:Array<Client>,
        theme?:DescribedObj,
        customware?:DescribedObj
    };

    export type PayLoaderCode = IdentifiedObj& {
        "description": "Reboots the device",
        "allowedDeviceModelNames": null,
        "sequence": { // THIS is an unnecessary level I believe, unless we use DeviceSequences here instead
            "operations":Array<Operation>
        }
    }
}
    
export const isImageSlot = (slot : Base.ModelSlotV2 | Base.ImageSlot) : slot is Base.ImageSlot => {
    if(slot.type ==="ImageSlot"){
        return true;
    }

    return false;
};

declare module Response{


    // both
    type Body<T> = T;
    // both


    //v2
    export type DeviceGroupDetails = Array<Base.DeviceGroupDetails>
    //v2
    export type ListedItems = Array<Base.Item>
    //v2
    export type ListedSlottableItems =Array<Base.Item>
    //v2
    export type ListedPayloads = Array<Base.Payload>
    //v2
    export type SinglePayload = Base.Payload

    //v2
    export type ListedDeviceGroups = Array<Base.DeviceGroupDetails>

    //v2
    export type Jobs = Array<Base.SomeJob>

    //v2
    export type JobCreated = Base.SomeJob

    //v2
    export type UpgradeScheduled =Base.ClientGrouping

    //v2 
    export type PayloadCreated = Base.Payload

    //v2
    export type LinkingCode = Base.LinkingCode

    //v2
    export type ListedModelSlots = Array<Base.ModelSlotType>
    //v2
    export type ListedModels =Array<Base.ModelV2Details>
    //v2
    export type ListedModelsOnly = Array<Base.ModelV2Only>

    //v2.5
    export type PreListedModels = Array<{
            deviceModel:{
                id:string
            },
            slots:{
                result:Array<Base.ModelSlotType>
            }
        }>

    //v2
    export type ItemCreate<T extends Base.Item> = T

    export type JobGroups = Array<Base.IdentifiedObj>
    export type JobGroup =Array<Base.JobGroup>

    export type Customwares = Array<Base.DescribedObj>
    export type Themes = Array<Base.DescribedObj>

    export type Models =Array<Base.Model>

    export type ModelSlots = Array<Base.ModelSlot>

    export type CreateJobGroup = Body<Request.JobGroup>;
    export type CreateTheme = Body<Request.Theme>;
    export type CreateCustomware = Body<Request.Customware>;
    export type CreateItem = Body<Request.Item>;
    export type GetPayLoaderCodes = Body<Array<Base.PayLoaderCode>>

}

declare module Request {

    //v2
    import ItemSpec = Base.ItemSpec;
    export type PayloadCreate = {
        name:string,
        description:string
    }
    //v2
    export type PayloadEdit = {
        id:string
    } & Partial<PayloadCreate>

    export type PayloadAddSlotItems = {
        id:string,
        deviceModelId:string,
        slots: { [keyof:string]: ItemSpec}
    }
    export type PayloadAddSpecItems = {
        id:string,
        deviceModelId:string,
        slotId:string,
        itemId:string,
        specifications?: {[keyof:string]:string}
    }
    export type PayloadRemoveItems = {
        id:string,
        deviceModelId:string,
        slotId:string,
        itemId:string
    }
    export type PayloadAddItem = {
        id:string,
        slottedItem:Base.SlottedItem
    }

    //v2
    export type JobCreate<T> = Base.ClientGrouping & {
        scheduledTime:string,
        expiredTime:string
    } & T;
    //v2
    export type PayloadJobCreate = JobCreate<{
        payloadId:string
    }>;
    //v2
    export type CodedJobCreate = JobCreate<{
        code:string
    }>;

    export type SomeJobCreate = PayloadJobCreate | CodedJobCreate | JobCreate<{}>;

    //v2
    export type DeviceGroupCreate = Base.DeviceGroupBase<{name:string}>

    //v2 
    export type DeviceGroupBase = Base.DeviceGroupBase

    //v2
    export type Forcible<T={}> = {
        Force?:boolean
    } & T;

    //v2
    export type ById = {
        id:string
    }

    //v2
    export type ForcibleById = Forcible<ById>

    export type JobGroup<T={}> = {
        JobGroupGuid:string
    } & T;
    export type Theme<T={}> = {
        themeGuid:string
    } & T;
    export type Customware<T={}>={
        customwareGuid:string
    } & T;
    export type DeviceGroupTheme<T={}>=Forcible<JobGroup<Theme>> & T;
    export type DeviceGroupCustomware<T={}>=JobGroup<Customware> & T;


    export type Item = {
        itemGuid: string
    };
    export type Client = {
        clientGuid?: string,
        id?: string,
        type:string
    };
    export type Slot = {
        slotGuid:string
    };

    export type ThemeItem = Forcible<Theme<Slot&Item>>;

    export type DeviceGroupClients = Forcible<JobGroup<{
        forceAdd?:boolean,// TODO : remove
        clients:Array<Client>
    }>>;
    
    // complex requests
    export type CreateDeviceGroup = {
        DeviceGroupName:string,
        DeviceGroupParentGuid?:string
    };
    export type CreateCustomware = {
        customwareName:string,
        customwareDescription:string
    };
    export type CreateTheme = {
        themeName:string,
        themeDescription:string
    };
}

export const isApplyPayloadJob = (job: Base.Job): job is Base.ApplyPayloadJob => {
    return job?.type==="ApplyPayloadJob"
}


export {Base, Response, Request};
