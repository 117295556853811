import { JSX, JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import { HTMLProps } from "../../types/props";
import Icon, { IconClassList, PathClassList } from "../../icons/Icon";

/* TYPES */
export type IconButtonClassList = {
    "tw-w-10":boolean,
    "tw-h-10":boolean,
    "tw-rounded-full":boolean,
    "tw-p-1":boolean,
    "tw-hover:bg-red-300":boolean,
    "tw-transition-all":boolean
};

export type IconButtonProps<P={}> = HTMLProps<
    JSX.ButtonHTMLAttributes<HTMLButtonElement>,
    {
        type?:"button"|"submit"|"reset",
        title?:string,
        mdiPath:string,

        iconClassList?: Partial<IconClassList> & {[key:string]:boolean},
        pathClassList?: Partial<PathClassList> & {[key:string]:boolean},
        children?:JSXElement
    }
> & P;

export type IconButtonComponent = BaseComponent<IconButtonProps>;


/* COMPONENT */
const defaultClassList : IconButtonClassList = {
    "tw-w-10":true,
    "tw-h-10":true,
    "tw-p-1":true,
    "tw-rounded-full":true,
    "tw-hover:bg-red-300":true,
    "tw-transition-all":true
};

const IconButton : IconButtonComponent = (props) => {

    const [local, rest] = splitProps(
        props,
        ["id", "classList", "type","title","mdiPath","iconClassList","pathClassList"]
    );

    return <button 
        id={local.id}
        type={local.type??"button"}
        title={local.title}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
        <Icon
            id={`icon-${local.id}`}
            mdiPath={local.mdiPath}
            title={local.title}
            classList={{
                // "tw-h-full":true,
                // "tw-w-full":true,
                "tw-h-5":false,
                "tw-w-5":false,
                ...local.iconClassList
            }}
            pathClassList={local.pathClassList}
        />
    </button>
};

export default IconButton;