import {Component, createMemo, createSignal, onMount, Setter} from "solid-js";
import { useTMS } from "../context/TMS/TMSProvider";
// import PresentResource from "../../../../../lib/components/utility/PresentResource";
import JobList from "./tms/JobList";
import PresentResource from "../../../../../lib/components/utility/PresentResource";
import Button from "../../../../../lib/components/form/controls/Button";
import { useNavigate } from "@solidjs/router";
import SearchArea from "../../../../../lib/components/search/SearchArea";
import { Payloader } from "../services/types";
import Card from "../../../../../lib/components/card/Card";
import {usePageData} from "../../../../../lib/_metronic/layout/core";
import Icon from "../../../../../lib/components/icons/Icon";
import {mdiPlus} from "@mdi/js";
import InputSelectLabeled from "../../../../../lib/components/form/inputs/InputSelectLabeled";
import SearchBar from "../../../../../lib/components/search/SearchBar";

const JobHistory : Component = (props)=>{
    const {dataFunctions:{loadJobsList}} = useTMS();

    // const [payloadList] = loadPayloads();
    const [jobsList] = loadJobsList();

    const nav = useNavigate();

    const [isAscending, setIsAscending] = createSignal<boolean>(false);
    const [sortTerm, setSortTerm] = createSignal<"scheduledTime"|"createdTime">("createdTime");

    const [searchVal, setSearchVal] = createSignal("");

    const jobsListMemo = createMemo(()=>{
        const jobs : Array<Payloader.Base.SomeJob> = [];
        for(const job of jobsList()??[]){
            switch (job.job?.type ){
                case "ApplyPayloadJob":
                    if((job.job as Payloader.Base.ApplyPayloadJob)?.payload?.name?.includes(searchVal()??"")){
                        jobs.push(job);
                    }
                    break;
                case "CodedJob":
                    if((job.job as Payloader.Base.CodedJob)?.code?.includes(searchVal()??"")){
                        jobs.push(job);

                    }
                    break;
                default:
                    jobs.push(job);
            }
        }
        jobs?.sort((a,b)=>{
            return (new Date(a?.state[sortTerm()??"createdTime"])?.getTime() - new Date(b?.state[sortTerm()??"createdTime"])?.getTime()) * (isAscending() ? -1 : 1)
        })
        return jobs;
    })

    const pageData = usePageData();

    onMount(()=>{
        pageData?.setPageToolbar(<Button
            id="create-job-link"
            onClick={()=>nav("/app/Jobs/Create")}
            classList={{"tw-w-fit":true, "btn-sm":true}}
            variant={"primary"}
        >
            <Icon mdiPath={mdiPlus} title={"Create a Job"}/> Create A Job
        </Button>);
    })

    

    return <PresentResource id="jobs-list-rsc" resource={jobsList}>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-end">
            <SearchBar
                label={"Search Jobs by Payload Name or Payloader Code"}
                id="search-jobs"
                name={`SearchBar`}
                setValue={setSearchVal}
                placeholder="Job Info"
            />
            <InputSelectLabeled
                label="Sort Jobs"
                id="select-sort-job"
                name="SortJob"
                setSignal={setSortTerm as Setter<string>}
            >
                
                <option></option>
                <option value="scheduledTime">Scheduled Time</option>
                <option value="createdTime">Created Time</option>
            </InputSelectLabeled>
            <Button class="btn-sm tw-max-h-12 pb-2" variant="light" onClick={()=>setIsAscending(s=>!s)}>
                {isAscending()?"Old-New":"New-Old"}
            </Button>
        </div>
        {/* <div class="tw-flex flex-row gap-2">
            <div class="tw-flex flex-column gap-1 grow">
                <label>Search</label>
                <input type="text" class="chisel-text-input border border-neutral-400" placeholder="type here..."/>
            </div>
            <div class="tw-flex flex-column gap-1 grow">
                <label>Search By</label>
                <select class="chisel-text-input border border-neutral-400">
                    <option></option>
                </select>
            </div>

        </div> */}

        {/* THIS SHOULD BE A <A/> ELEMENT STYLED TO LOOK LIKE BTN */}

        <Card>
            <JobList jobsList={jobsListMemo}/>
        </Card>
    </PresentResource>
}

export default JobHistory;