import { Component, JSXElement, Resource, createMemo } from "solid-js";
import PresentResource from "../../../../../../lib/components/utility/PresentResource";
import { Payloader } from "../../services/types";
import Card from "../../../../../../lib/components/card/Card";
import { A } from "@solidjs/router";
import DeviceOperationsSummary from "./DeviceOperationsSummary";
import { BaseComponent } from "../../../../../../lib/types";
import { isApplyPayloadJob } from "../../services/types/tms";
import {strToVariant} from "../../../../../../lib/utils/strToVariant";
import LabeledSpanPair from "../../../../../../lib/components/decorations/LabeledSpanPair";


const themeJobCard = (status:string, success_count?:number) => {

    const cardClass = {
        padding:"p-2",flexDirection:"flex-col", gap:"gap-2", justify:"justify-content-between",
        borderColor:"border-neutral-400",
        textColor:"text-neutral-700"
    }
    switch(status){
        case "Completed":
            cardClass.borderColor = success_count ? "border-green-800" : "border-red-800";
            cardClass.textColor = success_count ? "text-green-800" : "text-red-800";
            break;
        case "Started":
            cardClass.borderColor = "border-blue-400";
            cardClass.textColor = "text-blue-700";
            break;
        case "Pending":
            cardClass.borderColor = "border-amber-400";
            cardClass.textColor = "text-amber-700";
            break;
        case "Cancelled":
            cardClass.borderColor = "border-neutral-400";
            cardClass.textColor = "text-neutral-700";
            break;
        default:
    }
    return cardClass;
}

const JobCard : BaseComponent<{
    job:Payloader.Base.SomeJob,
    deviceId?:string,
    summary?:string,
}> = (props) => {
    if(!props.job){
        return <></>
    }

    const jobInfo = ()=>{
        const jobDetails = props.job?.job;
        if(isApplyPayloadJob(jobDetails)){
            return jobDetails?.payload?.name
        }
        return (jobDetails as Payloader.Base.CodedJob)?.code
    };

    const color = createMemo(()=>strToVariant(props.job?.state?.status))

    return <div  class={`tw-flex align-items-start gap-2`}>

        <div class="tw-flex flex-column gap-2 justify-content-between flex-grow-1 position-relative">
            <A  href={`/app/Jobs/${props.job?.id}`}  class={`streched-link tw-flex flex-row justify-content-between tw-font-bold hover:tw-underline link-${color()}`} >
                {/* eventually will need to be a component with type check, only one type of job rn */}
                {props.job?.job?.type} — {jobInfo()}
                <LabeledSpanPair id={`job-status-${props.id}`} divClass={"justify-content-end"} type={`light-${strToVariant(props.job?.state?.status)}`} title={"Status"} labelText={props.job?.state?.status} valueText={
                    ["Started","Completed"].includes(props.job?.state?.status)
                        ? ` (${props.job?.state?.counts?.Succeeded??0}/${props.job?.deviceIds?.length??0} Succeeded)`
                        :""
                }/>
            </A>
            <div class="tw-flex flex-row gap-1 justify-content-between text-sm text-muted w-100">
                <div>
                    <div>Created: {(new Date(props.job?.state?.createdTime))?.toUTCString()}</div>
                </div>
                <div>
                    <div>Scheduled: {(new Date(props.job?.state?.scheduledTime))?.toUTCString()}</div>
                </div>
            </div>
            {props.summary && props.deviceId
                ?<details class="w-100 text-muted tw-rotate-0 bg-transparent p-2 tw-h-fit">
                    <summary class={"tw-select-none"}>{props.summary}</summary>
                    <DeviceOperationsSummary id={`dos-${props.id}`} deviceSequenceInstance={props.job.deviceSequenceInstances[props.deviceId]}/>
                </details>
                :<></>
            }
        </div>
    </div>
};

export default JobCard;