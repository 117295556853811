import { Component } from "solid-js";
import CardLink, { CardLinkComponent, CardLinkProps } from "./CardLink";
import Card, { CardComponent, CardProps } from "./Card";
import { BaseComponentProps } from "../types/props";

const isCardLinkProps = (props:CardLinkOptionalProps) : props is BaseComponentProps<CardLinkProps> => {
    return "href" in props && props.href !== undefined;
}

export type CardLinkOptionalProps = BaseComponentProps<CardLinkProps | CardProps>;
export type CardLinkOptionalComponent<P={}> = Component<CardLinkOptionalProps & P>


const CardLinkOptional : CardLinkOptionalComponent = (
    props : CardLinkOptionalProps
) => {

    if(isCardLinkProps(props)){
        return <CardLink

            {...props}
        />
    }

    return <Card {...props}/>
};

export default CardLinkOptional;