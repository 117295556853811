import {Accessor, Component, createEffect, createMemo, createSignal, JSX, JSXElement, Setter, splitProps} from "solid-js";
import InputFile from "./InputFile";
import IconButton from "../controls/IconButton";
import {mdiAlphaX, mdiCheck, mdiPencil, mdiUndo} from "@mdi/js";
import InputText from "./InputText";
import ImageCropper from "../controls/ImageCropper";

const InputFileLabeled : Component<{
    label:JSXElement,
    includeCropper?:boolean,
    file:Accessor<File>,
    setFile:Setter<File>
}&Omit<JSX.InputHTMLAttributes<HTMLInputElement>, "type">> = (props) => {

    const [local, rest] = splitProps(props,["classList","label","includeCropper"]);
    let nameRef : HTMLInputElement;
    let inputRef : HTMLInputElement;

    const [isRenaming, setIsRenaming] = createSignal<boolean>(false);

    const renameControl = createMemo(()=>{
        if(isRenaming()) return <></>;
        return <button onClick={()=>setIsRenaming(true)} class={"btn btn-sm btn-light"}>rename</button>
    });

    const updateFileName = (name:string) => {
        props.setFile(fi=>new File([fi], name, {type:fi.type,lastModified:fi.lastModified}));
    };

    createEffect(()=>{
        if(props.file()){
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(props.file());
            inputRef.files=dataTransfer.files;
        }
    });

    const handleFileInput = () => {
        props.setFile(inputRef.files[0])
    }


    return <div id={`group-${rest.id}`}
        classList={{
            "tw-flex":true,
            "tw-flex-col":true,
            "tw-gap-1":true,
            ...(local.classList)
        }}
    >
        <label class={"w-100 tw-flex tw-flex-row tw-justify-between align-items-end"} style={{height:"35px"}} for={rest.id}>
            <div>{local.label}</div>
            {renameControl()}
        </label>
        {isRenaming()
            ?<div class={"tw-flex tw-flex-row tw-gap-2"}>
                <InputText ref={nameRef}/>
                <span class={"tw-flex tw-flex-row tw-gap-1"}>
                    <IconButton mdiPath={mdiCheck} 
                        onClick={()=>{
                            updateFileName(nameRef.value);
                            setIsRenaming(false);
                        }}/>
                    <IconButton mdiPath={mdiUndo} onClick={()=>setIsRenaming(false)}/>
                </span>
            </div>
            :<></>
        }
        <InputFile
            ref={inputRef}
            onInput={handleFileInput}
            hidden={isRenaming()} 
            {...rest}
        />
    </div>
}

export default InputFileLabeled;