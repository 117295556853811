import {Accessor, Component, createEffect, createMemo, createSignal, onMount, Setter} from "solid-js";
import Cropper from "cropperjs";
import Icon from "../../icons/Icon";
import {mdiCheck, mdiCropFree, mdiUndo, mdiUpload} from "@mdi/js";
import Button from "./Button";

const ImageCropper : Component<{
    id?:string,
    file:Accessor<File>,
    setFile:Setter<File>,
    aspectRatio:number,
    exportOptions?:Cropper.GetCroppedCanvasOptions,

}> = (props) =>{

    let imageElem : HTMLImageElement;
    let cropper : Cropper;

    const [isCropping, setIsCropping] = createSignal<boolean>(false);
    
    const imageUrl = createMemo<string>((url)=>{
        if(!props.file()) return "";
        if(url){
            URL.revokeObjectURL(url);
        }
        return URL.createObjectURL(props.file());
    });

    const destroyCropper = () => {
        cropper.destroy();
        setIsCropping(false);
    };
    const editImage = () => {
        cropper?.getCroppedCanvas(props.exportOptions)?.toBlob(blob=>{
            props.setFile(fi=>new File([blob], fi.name, {type:blob.type}));
            destroyCropper();
        }, "image/gif");
    };
    const startCropping = () => {
        cropper = new Cropper(imageElem, {
            aspectRatio: props.aspectRatio,
            viewMode:2
        });
        
        setIsCropping(true);
    };


    return <div class="tw-flex flex-row gap-2 w-100">
        
        {imageUrl()?.length 
            ? <>
                <div class="tw-flex flex-column tw-w-32 gap-2">
                {isCropping()
                    ? <>
                        <Button
                            id={`undo-cropper-${props.id}`}
                            classList={{
                                "tw-flex":true,
                                "p-2":false,
                                "p-1":true,
                                "flex-column":true,
                            }}
                            onClick={destroyCropper}
                        >
                            <Icon id={`crop-image-${props.id}`}
                                mdiPath={mdiUndo}
                                title="Undo"
                            />
                            <div>
                                Undo
                            </div>
                        </Button>
                        <Button
                            id={`cfm-cropper-${props.id}`}
                            classList={{
                                "tw-flex":true,
                                "p-2":false,
                                "p-1":true,
                                "flex-column":true,
                            }}
                            onClick={editImage}
                        >
                            <Icon id={`crop-image-${props.id}`}
                                mdiPath={mdiCheck}
                                title="Confirm"
                            />
                            <div class="w-100 break-words">
                                Confirm Edit
                            </div>

                        </Button>
                    </>
                    :<>
                        <Button
                            id={`open-cropper-${props.id}`}
                            classList={{
                                "p-2":false,
                                "p-1":true,
                                "flex-column":true,
                            }}
                            onClick={startCropping}
                        >
                            <Icon id={`crop-image-${props.id}`}
                                mdiPath={mdiCropFree}
                                title="Crop"
                            />
                            <div>
                                Crop
                            </div>
                        </Button>
                        <Button
                            id="upload-file-submit"
                            type="submit"
                            variant={"success"}
                            classList={{
                                "p-2":false,
                                "p-1":true,
                                "flex-column":true,
                            }}
                        >
                            <Icon id={`crop-image-${props.id}`}
                                mdiPath={mdiUpload}
                                title="Upload"
                            />
                            <div>
                                Upload
                            </div>
                        </Button>
                    </>
                }
            </div>
            <div class={"tw-h-80 tw-flex-grow tw-flex tw-border-dashed border-primary tw-border-1 align-content-center justify-content-center tw-rounded-sm"}>
                <img
                    ref={imageElem}
                    style={{
                        "max-height":"100%",
                        "width":"auto"
                    }}
                    src={imageUrl()}
                />
            </div> 
            </>
            : <></>
        }
    </div>
}

export default ImageCropper;