import { useNavigate } from "@solidjs/router";
import {Component} from "solid-js";

export const payloaderLogoUrl = new URL(`../../images/${import.meta.env.VITE_LOGO_PATH}`, import.meta.url).href;

export const payloaderDarkLogoUrl = new URL(`../../images/${import.meta.env.VITE_LOGO_DARK_PATH}`, import.meta.url).href;

export const payloaderSmallLogoUrl = new URL(`../../images/${import.meta.env.VITE_LOGO_SMALL_PATH}`, import.meta.url).href;
export const payloaderIconLogoUrl = new URL(`../../images/${import.meta.env.VITE_LOGO_ICON_PATH}`, import.meta.url).href;
export const payloaderTextLogoUrl : string = new URL(`../../images/${import.meta.env.VITE_LOGO_TEXT_PATH}`, import.meta.url).href;
export const payloaderBetaBadge : string = new URL(`../../images/${import.meta.env.VITE_LOGO_BETA_BADGE}`, import.meta.url).href;

const PayloaderLogo : Component<{logoUrl?:string, class?:string}> = (props) => {

    const nav = useNavigate();

    return <div onClick={()=>{nav("/app/Home")}} class={`tw-flex flex-column justify-items-center align-items-end tw-h-full tw-w-fit ${props.class??""}`}>
        <img class="tw-h-full tw-cursor-pointer" src={props.logoUrl??payloaderLogoUrl}/>
        <img class={"tw-w-6"} src={payloaderBetaBadge}/>
    </div>
    
};

export default PayloaderLogo;