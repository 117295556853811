import {Component, createMemo, onMount} from "solid-js";
import {Navbar, NavbarItem} from "../../../../../../lib/_metronic/layout/components/header/Navbar";
import {mdiAccountBox, mdiAlertCircle} from "@mdi/js";
import Dropdown from "../menus/Dropdown";
import {MenuComponent} from "../../../../../../lib/_metronic/assets/ts/components";
import AccountCard from "../../page/pill/AccountCard";
import AlertsList from "../../page/pill/AlertsList";
import {useTMS} from "../../context/TMS/TMSProvider";

const PayLoaderNavbar : Component = (props) => {

    const {resources:{loadLinkingCode}} = useTMS();

    const [linkingCode] = loadLinkingCode();

    const code = createMemo(()=>{
        if(linkingCode.loading){
            return "......";
        }
        if(linkingCode.error){
            return "Could not retrieve linking code."
        }
        return linkingCode();
    })

    return <Navbar>
        <div class={"tw-flex flex-column tw-h-full tw-items-center tw-justify-center tw-py-2"}>
            <div class={`tw-p-2 tw-rounded-md bg-muted`}
                style={
                    linkingCode.loading?{
                        "animation-name": "loading-ghost",
                        "animation-duration":"3.1s",
                        "animation-timing-function":"ease-in-out",
                        "animation-iteration-count":"infinite"
                    }:{}
                }
            >
                {code()}
            </div>
        </div>
        <NavbarItem targetId={"info-5"} ktiName={"information-5"}>
            <AlertsList id={"alerts-list"}/>
        </NavbarItem>
        <NavbarItem targetId={"user-square"} ktiName={"user-square"}>
            <AccountCard id={"acct-card"}/>
        </NavbarItem>
    </Navbar>
};

export default PayLoaderNavbar;