import {mdiFormSelect, mdiImage, mdiPackage, mdiPackageVariant} from "@mdi/js";

export const strToMDIPath = (str:string) : string => {
    switch (str){
        case "PaxApplicationSlot":
        case "PaxApplicationItem":
            return mdiPackage;
        case "PaxFormPackageItem":
        case "PaxFormPackageSlot":
            return mdiFormSelect;
        case "ImageSlot":
        case "ImageItem":
            return mdiImage;
        default:
            return "";
    }
}