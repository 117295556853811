
import Title from "../../../../../lib/components/page/Title";
import { useTMS } from "../context/TMS/TMSProvider";
import PresentResource from "../../../../../lib/components/utility/PresentResource";
import {For, ResourceReturn, createEffect, createMemo, createSignal, createContext, useContext} from "solid-js";
import { Payloader } from "../services/types";
import Card from "../../../../../lib/components/card/Card";
import Button from "../../../../../lib/components/form/controls/Button";
import ButtonWithIcon from "../../../../../lib/components/form/controls/ButtonWithIcon";
import { mdiApplicationArray, mdiFileQuestion, mdiImageAlbum, mdiImageArea, mdiPackage, mdiUpload } from "@mdi/js";
import { BaseComponent } from "../../../../../lib/components/types/components";
import AlertCard, { ExOutBtn } from "../../../../../lib/components/alert/AlertCard";
import Form from "../../../../../lib/components/form/Form";
import Icon from "../../../../../lib/components/icons/Icon";
import { displayName, iconItemType } from "../context/TMS/utils";
import SearchArea from "../../../../../lib/components/search/SearchArea";
import InputTextLabeled from "../../../../../lib/components/form/inputs/InputTextLabeled";
import InputLabeled from "../../../../../lib/components/form/inputs/InputLabeled";
import CustomSwitch from "../../../../../lib/components/utility/CustomSwitch";
import ItemCard from "../components/items/ItemCard";
import LoadingButton from "../../../../../lib/components/form/controls/LoadingButton";
import UploadItemForm from "../components/payload/UploadItemForm";
import {Base, isImageSlot} from "../services/types/tms";
import { Alert } from "../../../../../lib/context/alert/manager";
import Item = Base.Item;

const ItemsListContext = createContext<ResourceReturn<Item[]>>();

const UploadPaxApplication : BaseComponent = (props) => {

    const {calls:{
        sendUploadPaxPackageItem
    }} = useTMS();

    const [isLoading, setIsLoading] = createSignal<boolean>(false);

    const [alert,setAlert] = createSignal<Alert>();

    let formElem : HTMLFormElement;

    return <Form id={`upload-form`}
        ref={formElem}
        onSubmit={async(event)=>{
            event.preventDefault();
            setIsLoading(true);
            const formData = new FormData(event.currentTarget);

            try{
                const resp = await sendUploadPaxPackageItem(formData);
                setIsLoading(false);
                formElem.reset()
                setAlert({
                    code:"Success",
                    message:`Successfully uploaded application ${formData.get("ApplicationName")}.`,
                    type:"success",
                    show:true,
                    time:Date.now()
                });

            } catch (err) {
                setIsLoading(false);
                formElem.reset()
                setAlert({
                    code:"Error",
                    message:err.message??"An error occurred while uploading this file.",
                    type:"error",
                    show:true,
                    time:Date.now()
                });

            }
            

        }}
    >
        {alert()
            ?<AlertCard
                id="upload-alert"
                alert={alert()}
                onExOut={()=>{setAlert()}}
            />
            :<></>}
        <InputTextLabeled 
            classList={{"w-100":true}}
            name="ApplicationName"
            required={true}
            id="pax-app-name-entry"
            label={"Application Name"}
            placeholder="App Name"
        />
        <InputTextLabeled 
            classList={{"w-100":true}}
            name="PackageName"
            required={true}
            id="pax-app-pkg-name-entry"
            label={"Package Name"}
            placeholder="com.dcap.payments"
        />
        <InputTextLabeled 
            classList={{"w-100":true}}
            name="Version"
            required={true}
            id="pax-app-version-entry"
            label={"Application Version"}
            placeholder="3.13.1+4"
        />
        <InputLabeled 
            classList={{"w-100":true}}
            label="Application" 
            required={true}
            id={"pax-app-file-entry"} 
            name="ApplicationFile" 
            type="file"
            accept=".apk,application/vnd.android.package-archive"
        />
        <LoadingButton 
            id="submit-btn-upload-app"
            type="submit"
            isLoading={isLoading}
        >
            Upload Application
        </LoadingButton>
    </Form>
}
const UploadPaxPackage : BaseComponent = (props) => {
    return <></>
}
const UploadImage : BaseComponent = (props) => {
    const [_, {refetch}] = useContext(ItemsListContext);

    const {resources:{loadSlotsList}} = useTMS();
    const [slots] = loadSlotsList();

    const [selectedSlot, setSelectedSlot] = createSignal<Payloader.Base.ImageSlot>();

    const imageSlots = createMemo<Payloader.Base.ImageSlot[]>(()=>{
        const imageSlotsArr : Array<Payloader.Base.ImageSlot> = [];
        for(const slot of slots()??[]){
            if(isImageSlot(slot)){
                imageSlotsArr.push(slot)
            }
        }
        return imageSlotsArr;
    })

    createEffect(()=>{
        setSelectedSlot(()=>imageSlots()?imageSlots()[0]:undefined)
    })

    return <>
        <PresentResource id="slots-rsc" resource={slots}>
            <label class="" for="slots-select">Slot</label>
            <select classList={{
                    "h-12":true,
                    "rounded-md":true,
                    "p-2":true,
                    "border":true,
                    "border-neutral-500":true,
                    "tw-flex":true,
                    "selection:bg-slate-300":true,
                    "selection:text-slate-900":true
                }} 
                onChange={(event)=>{
                    setSelectedSlot(()=>{
                        return imageSlots().find(slot=>{
                            return slot?.id===event.target.selectedOptions[0]?.value
                        })
                    })
                }} 
                id="slots-select"
            >
                <For each={imageSlots()}>{(slot)=>(
                    <option value={slot.id} selected={slot.id===selectedSlot()?.id}>{slot.name} ({slot.deviceModel})</option>
                )}</For>
            </select>
        </PresentResource>
        {()=>selectedSlot()
            ?<UploadItemForm
            id="upload-item-form"
            slot={selectedSlot() as Payloader.Base.ImageSlot}
            onComplete={(item)=>{
                refetch();
            }}
        /> : <></>}
    </>
}

const UploadCard : BaseComponent<{
    return:()=>void
}> = (props) => {

    const [type, setType] = createSignal<"ImageItem"|"PaxApplicationItem"|"PaxFormPackageItem">("ImageItem");

    return  <Card id={`upload-area-${props.id}`}
        label={"Upload"}
        controls={
            <ExOutBtn onClick={props.return}/>
        }>
        <div class="tw-flex flex-row gap-2">
            <ButtonWithIcon id="select-image-item-upload"
                onClick={()=>{setType("ImageItem")}}
                classList={{
                    "bg-neutral-500":false,
                    "bg-slate-400":type()!=="ImageItem",
                    "bg-slate-800":type()==="ImageItem",
                    "grow":true
                }}
                mdiPath={mdiImageArea}
            >
                Image
            </ButtonWithIcon>
            <ButtonWithIcon id="select-package-item-upload"
                onClick={()=>{setType("PaxApplicationItem")}}
                classList={{
                    "bg-neutral-500":false,
                    "bg-slate-400":type()!=="PaxApplicationItem",
                    "bg-slate-800":type()==="PaxApplicationItem",
                    "grow":true

                }}
                mdiPath={mdiApplicationArray}
            >
                PAX Application
            </ButtonWithIcon>
            {/* <ButtonWithIcon id="select-app-item-upload"
                onClick={()=>{setType("PaxFormPackageItem")}}
                classList={{
                    "bg-neutral-500":false,
                    "bg-slate-400":type()!=="PaxFormPackageItem",
                    "bg-slate-800":type()==="PaxFormPackageItem",
                    "grow":true

                }}
                mdiPath={mdiPackage}
            >
                PAX Form Package
            </ButtonWithIcon> */}
        </div>
        
        <CustomSwitch
            id="switch-upload-type"
            default={<></>}
            switchValue={type}
            caseComponents={{
                "ImageItem":<UploadImage id="upload-mg-form"/>,
                "PaxApplicationItem":<UploadPaxApplication id="upload-pax-app"/>
            }}
        />
        
    </Card>
        
};

const ItemsList = () => {
    const {
        resources:{ loadItemsList}
    } = useTMS();

    const [items, {mutate,refetch}] = loadItemsList();


    const [isUploading, setIsUploading] = createSignal<boolean>();

    const [searchVal, setSearchVal] = createSignal<string>("");

    const itemsList = createMemo(()=>{
        return items()?.filter(item=>{
            return searchVal()?.length
                ?item.name?.toLowerCase()?.includes(searchVal().toLowerCase())
                :true
        })
    });

    return <ItemsListContext.Provider value={[items, {mutate,refetch}]}>
        <Title id="items-list-title">Items</Title>
        {/* <div>
            This is a list of all items (custom software, images, etc.) you 
            have uploaded. Here, you can upload new items, delete items 
            from your account, and view currently uploaded items. 
        </div> */}
        {isUploading()
            ?<UploadCard id={"uploadCard"} return={()=>{setIsUploading(false)}}/>
            :<ButtonWithIcon id="upload-item-btn"
                classList={{
                    "bg-neutral-500":false,
                    "hover:bg-neutral-400":false,
                    "bg-green-800":true,
                    "hover:bg-green-600":true
                }}
                mdiPath={mdiUpload}
                onClick={()=>{setIsUploading(true)}}
            >
                Upload Item
            </ButtonWithIcon>
        }
        <SearchArea
            id="search-items"
            setValue={setSearchVal}
            placeholder="Item Name"
            name="SearchItems"
        />

        <PresentResource id="items-rsc" resource={items}>
            <For each={itemsList()}>{(item,id)=>{
                return <ItemCard item={item} id={`${id()}`}/>

            }}</For>
        </PresentResource>
    </ItemsListContext.Provider>
};

export default ItemsList;