import { useToken } from "../../context/Token/TokenProvider";
import {A, useNavigate} from "@solidjs/router";

import {BaseComponent} from "../../../../../../lib/types";

const AccountCard : BaseComponent = (props) => {
    
    const [{idToken}, {revoke}] = useToken();

    //console.log(idToken());

    const nav = useNavigate();
    
    return <div
    id={props.id}
    class={
        `tw-flex flex-column h-52 w-72 p-4 `+
        `fold-out-height-delay`
    }
    // onMouseLeave={()=>setShow(false)}
    >
        <A class="hover:underline cursor-pointer font-bold" href="/app/Account" title={"Manage Account"}>{idToken().company}</A>
        <div>
            {idToken().location} {idToken().country === "US" ? "🇺🇸" : "🇨🇦"}
        </div>
        <div
            class={`hover:underline cursor-pointer`}
            onClick={revoke}
            >
            logout
        </div>
    </div>
};

export default AccountCard;