import clsx from 'clsx'
import {Component} from "solid-js"
import {toAbsoluteUrl} from '../../../helpers'
// import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('media/flags/united-states.svg'),
    },
    {
        lang: 'zh',
        name: 'Mandarin',
        flag: toAbsoluteUrl('media/flags/china.svg'),
    },
    {
        lang: 'es',
        name: 'Spanish',
        flag: toAbsoluteUrl('media/flags/spain.svg'),
    },
    {
        lang: 'ja',
        name: 'Japanese',
        flag: toAbsoluteUrl('media/flags/japan.svg'),
    },
    {
        lang: 'de',
        name: 'German',
        flag: toAbsoluteUrl('media/flags/germany.svg'),
    },
    {
        lang: 'fr',
        name: 'French',
        flag: toAbsoluteUrl('media/flags/france.svg'),
    },
]

const Languages: Component = () => {
    // const lang = useLang()
    const currentLanguage = {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('media/flags/united-states.svg'),
    }
    return (
        <div
            class='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
        >
            <a href='#' class='menu-link px-5'>
        <span class='menu-title position-relative'>
          Language
          <span class='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
              <img
                  class='w-15px h-15px rounded-1 ms-2'
                  src={currentLanguage?.flag}
                  alt='metronic'
              />
          </span>
        </span>
            </a>

            <div class='menu-sub menu-sub-dropdown w-175px py-4'>
                {languages.map((l) => (
                    <div
                        class='menu-item px-3'
                        id={l.lang}
                        onClick={() => {
                            // setLanguage(l.lang)
                        }}
                    >
                        <a
                            href='#'
                            class={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
                        >
              <span class='symbol symbol-20px me-4'>
                <img class='rounded-1' src={l.flag} alt='metronic'/>
              </span>
                            {l.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export {Languages}
