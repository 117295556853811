import {createEffect} from "solid-js"
import {ILayout, useLayout} from '../../core'
import DatacapLogo from "../../../../components/icons/DatacapLogo"

const Footer = () => {
    const {config} = useLayout()
    createEffect(() => {
        updateDOM(config())
    })
    return <>
        <div class='text-gray-900 order-2 order-md-1 d-flex flex-column '>
            <div class="tw-w-28 text-gray-800">
                <DatacapLogo/>
            </div>
            <div class="d-flex flex-row">
                <span class='text-muted fw-semibold me-2 tw-font-sm'>
                    {new Date().getFullYear().toString()}&copy;
                </span>
                <a
                    href={"https://datacapsystems.com/"}
                    target='_blank'
                    class='text-muted text-hover-primary tw-font-sm'
                >
                    Datacap Systems
                </a>
            </div>
        </div>

        <ul class='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
            <li class='menu-item'>
                <a href={"https://datacapsystems.com/about-datacap"} target='_blank' class='menu-link px-2'>
                    About
                </a>
            </li>

            <li class='menu-item'>
                <a href={"https://datacapsystems.com/support"} target='_blank' class='menu-link px-2'>
                    Support
                </a>
            </li>

            <li class='menu-item'>
                <a
                    href={"https://www.dsidevportal.com/"}
                    target='_blank'
                    class='menu-link px-2'
                >
                    Developer Portal
                </a>
            </li>
        </ul>
    </>
}

const updateDOM = (config: ILayout) => {
    if (config.app?.footer?.fixed?.desktop) {
        document.body.classList.add('data-kt-app-footer-fixed', 'true')
    }

    if (config.app?.footer?.fixed?.mobile) {
        document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
    }
}

export {Footer}
