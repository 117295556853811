import {Component} from "solid-js"
import {A} from '@solidjs/router'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {User} from "../../../types/User";

const HeaderUserMenu: Component<{ currentUser: User, logout: () => void }> = (props) => {
    // const {currentUser, logout} = useAuth()
    return (
        <div
            class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div class='menu-item px-3'>
                <div class='menu-content d-flex align-items-center px-3'>
                    <div class='symbol symbol-50px me-5'>
                        <img alt='Logo' src={toAbsoluteUrl('media/avatars/300-3.jpg')}/>
                    </div>

                    <div class='d-flex flex-column'>
                        <div class='fw-bolder d-flex align-items-center fs-5'>
                            {props.currentUser?.first_name} {props.currentUser?.first_name}
                            <span class='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
                        </div>
                        <a href='#' class='fw-bold text-muted text-hover-primary fs-7'>
                            {props.currentUser?.email}
                        </a>
                    </div>
                </div>
            </div>

            <div class='separator my-2'></div>

            <div class='menu-item px-5'>
                <A href={'/crafted/pages/profile'} class='menu-link px-5'>
                    My Profile
                </A>
            </div>

            <div class='menu-item px-5'>
                <a href='#' class='menu-link px-5'>
                    <span class='menu-text'>My Projects</span>
                    <span class='menu-badge'>
            <span class='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
                </a>
            </div>

            <div
                class='menu-item px-5'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='left-start'
                data-kt-menu-flip='bottom'
            >
                <a href='#' class='menu-link px-5'>
                    <span class='menu-title'>My Subscription</span>
                    <span class='menu-arrow'></span>
                </a>

                <div class='menu-sub menu-sub-dropdown w-175px py-4'>
                    <div class='menu-item px-3'>
                        <a href='#' class='menu-link px-5'>
                            Referrals
                        </a>
                    </div>

                    <div class='menu-item px-3'>
                        <a href='#' class='menu-link px-5'>
                            Billing
                        </a>
                    </div>

                    <div class='menu-item px-3'>
                        <a href='#' class='menu-link px-5'>
                            Payments
                        </a>
                    </div>

                    <div class='menu-item px-3'>
                        <a href='#' class='menu-link d-flex flex-stack px-5'>
                            Statements
                            <i
                                class='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='View your statements'
                            ></i>
                        </a>
                    </div>

                    <div class='separator my-2'></div>

                    <div class='menu-item px-3'>
                        <div class='menu-content px-3'>
                            <label class='form-check form-switch form-check-custom form-check-solid'>
                                <input
                                    class='form-check-input w-30px h-20px'
                                    type='checkbox'
                                    value='1'
                                    checked
                                    name='notifications'
                                />
                                <span class='form-check-label text-muted fs-7'>Notifications</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class='menu-item px-5'>
                <a href='#' class='menu-link px-5'>
                    My Statements
                </a>
            </div>

            <div class='separator my-2'></div>

            <Languages/>

            <div class='menu-item px-5 my-1'>
                <A href='/crafted/account/settings' class='menu-link px-5'>
                    Account Settings
                </A>
            </div>

            <div class='menu-item px-5'>
                <a onClick={props.logout} class='menu-link px-5'>
                    Sign Out
                </a>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
