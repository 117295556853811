const DatacapLogo = () =>{
    
    return <svg 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 582.03 91.53"
        fill="currentColor"
    >
            <path class="cls-1"d=" m19.88,50.67C5.49,50.67.15,45.92.15,39.11v-14.09c0-6.81,5.33-11.56,19.73-11.56l33.07-.23-.03-13.23h31.49l-.24,50.67H19.88Zm33.07-27.08c0-2.06-1.33-2.85-4.4-2.85h-12.53c-3.07,0-4.4.79-4.4,2.85v16.94c0,2.06,1.33,2.85,4.4,2.85h12.53c3.07,0,4.4-.79,4.4-2.85v-16.94h0Z"/>
            <path class="cls-3" d="m561.8,13.17c14.4,0,19.73,4.75,19.73,11.56v14.09c0,6.81-5.33,11.56-19.73,11.56l-33.06.23.03,13.23h-31.49l.24-50.67h64.29Zm-33.06,27.08c0,2.06,1.33,2.85,4.4,2.85h12.53c3.06,0,4.4-.79,4.4-2.85v-16.94c0-2.06-1.33-2.85-4.4-2.85h-12.53c-3.07,0-4.4.79-4.4,2.85v16.94h0Z"/>
            <path class="cls-4" d="m561.8,13.17c14.4,0,19.73,4.75,19.73,11.56v14.09c0,6.81-5.33,11.56-19.73,11.56l-33.06.23.03,13.23h-31.49l.24-50.67h64.29Zm-33.06,27.08c0,2.06,1.33,2.85,4.4,2.85h12.53c3.06,0,4.4-.79,4.4-2.85v-16.94c0-2.06-1.33-2.85-4.4-2.85h-12.53c-3.07,0-4.4.79-4.4,2.85v16.94h0Z"/>
            <path class="cls-1" d="m155.81,13.46c15.58,0,21.79,3.8,21.79,14.01l-.26,23.2h-67.46c-14.62,0-18.76-3.48-18.76-11.24v-2.69c0-7.76,4.14-11.24,18.76-11.24h29.65c4.55,0,5.52-.63,5.52-2.38s-.97-2.38-5.52-2.38l-43-.2-.03-7.08h59.3Zm-25.24,19.32c-4.96.08-6.9.47-6.9,3.17v4.27c0,2.69,1.93,3.09,6.9,3.17h7.59c6.21,0,6.9-.55,6.9-5.3v-5.3h-14.48Z"/>
            <path class="cls-1" d="m185.59,5.86l31.47-.48v8.08h13.19v7.28h-13.19v19.63c0,2.37.67,3.01,6.27,3.01l7.1.06-.02,7.22h-26.14c-10,0-18.67-2.37-18.67-11.64V5.86h0Z"/>
            <path class="cls-2" d="m359.21,39.59c.13,2.69,2.13,3.8,7.2,3.8l32.59-.04-.03,7.32h-51.5c-14.4,0-19.73-4.75-19.73-11.56v-14.09c0-6.81,5.34-11.56,19.73-11.56h51.5l.03,7.18-32.6.1c-5.07,0-7.07,1.11-7.2,3.8v15.04h0Z"/>
            <path class="cls-1" d="m299.59,13.46c15.58,0,21.79,3.8,21.79,14.01l-.26,23.2h-67.46c-14.62,0-18.76-3.48-18.76-11.24v-2.69c0-7.76,4.14-11.24,18.76-11.24h29.65c4.55,0,5.52-.63,5.52-2.38s-.96-2.38-5.52-2.38l-43-.2-.03-7.08h59.3Zm-25.24,19.32c-4.96.08-6.9.47-6.9,3.17v4.27c0,2.69,1.93,3.09,6.9,3.17h7.59c6.21,0,6.89-.55,6.89-5.3v-5.3h-14.48Z"/>
            <path class="cls-3" d="m467.38,13.46c15.59,0,21.79,3.8,21.79,14.01l-.26,23.2h-67.46c-14.62,0-18.76-3.48-18.76-11.24v-2.69c0-7.76,4.14-11.24,18.76-11.24h29.65c4.55,0,5.52-.63,5.52-2.38s-.96-2.38-5.52-2.38l-43-.2-.03-7.08h59.3Zm-25.24,19.32c-4.97.08-6.9.47-6.9,3.17v4.27c0,2.69,1.93,3.09,6.9,3.17h7.58c6.21,0,6.89-.55,6.89-5.3v-5.3h-14.48Z"/>
            <path class="cls-4" d="m467.38,13.46c15.59,0,21.79,3.8,21.79,14.01l-.26,23.2h-67.46c-14.62,0-18.76-3.48-18.76-11.24v-2.69c0-7.76,4.14-11.24,18.76-11.24h29.65c4.55,0,5.52-.63,5.52-2.38s-.96-2.38-5.52-2.38l-43-.2-.03-7.08h59.3Zm-25.24,19.32c-4.97.08-6.9.47-6.9,3.17v4.27c0,2.69,1.93,3.09,6.9,3.17h7.58c6.21,0,6.89-.55,6.89-5.3v-5.3h-14.48Z"/>
            <path class="cls-1" d="m42.89,78.39c0,3.25-3.13,4.87-9.38,4.87H1.17l.04-3.24h25.49c1.8,0,2.89-.57,2.89-1.45v-1.21c0-.88-1.09-1.44-2.89-1.44H8.37c-5.63,0-8.37-1.9-8.37-5.94v-2.65c0-4.04,2.74-5.9,8.37-5.9h32.45l.09,3.24H15.72c-1.64,0-2.42.43-2.42,1.31v1.02c0,.88.78,1.3,2.42,1.3h17.79c6.26,0,9.38,1.62,9.38,4.87v5.2h0Z"/>
            <path class="cls-1" d="m48.35,91.53v-3.43s24.87-.01,24.87-.01c2.42,0,3.36-.42,3.36-2.23v-2.62h-21.03c-5.47.01-8.84-1.94-8.84-5.05l-.07-16.76h13.67v17.23c0,.74.86,1.16,2.03,1.16h11.89c1.49,0,2.35-.42,2.35-1.16l.06-17.23h13.54v23.91c0,4.27-3.44,6.17-8.91,6.17h-32.92Z"/>
            <path class="cls-1" d="m163.88,64.69h-7.15v12.91c0,1.81.94,2.23,3.36,2.23h3.85s-.06,3.44-.06,3.44h-11.84c-5.47,0-8.91-1.9-8.91-6.18v-19.36l13.61.05v3.67h7.15v3.25h0Z"/>
            <path class="cls-1" d="m183.37,74.35v3.9c0,1.16.55,1.58,2.35,1.58l26.94.02-.04,3.42h-33.62c-6.26,0-9.23-1.81-9.23-5.85v-10.12c0-4.04,2.97-5.85,9.23-5.85h25.49c6.26,0,9.23,1.81,9.23,5.85v7.06h-30.34Zm16.73-3.44v-4.46c0-1.16-.55-1.58-2.35-1.58h-12.04c-1.8,0-2.35.42-2.35,1.58v4.46h16.73Z"/>
            <path class="cls-1" d="m281.73,61.44c6.44,0,9.5,1.81,9.5,5.85v15.97h-13.87l-.15-16.81c0-1.16-.56-1.58-2.42-1.58h-10.71c-1.85,0-2.41.42-2.41,1.58l.13,16.81h-14.26l.13-16.81c0-1.16-.56-1.58-2.42-1.58h-10.71c-1.85,0-2.42.42-2.42,1.58v16.81h-14v-21.82h63.6Z"/>
            <polygon class="cls-1" points="344.26 87.35 347.62 83.26 341.83 83.26 341.83 77.13 356.53 77.13 356.53 82.98 350.04 87.35 344.26 87.35 344.26 87.35"/>
            <path class="cls-1" d="m384.85,61.44l.06,21.82h-13.59v-21.82h13.53Zm-.23-7.34v5.2h-13.14v-5.2h13.14Z"/>
            <path class="cls-1" d="m424.03,61.44c6.26,0,9.23,1.81,9.23,5.85l.06,15.97h-13.59l-.07-16.81c0-1.16-.55-1.58-2.35-1.58h-11.57c-1.8,0-2.34.42-2.34,1.58v16.81h-13.6v-21.82h34.24Z"/>
            <path class="cls-1" d="m452.56,64.87c-1.8,0-2.35.42-2.35,1.58v11.79c0,1.16.55,1.58,2.35,1.58l21.62.02-.04,3.42h-28.3c-6.26,0-9.23-1.81-9.23-5.85v-10.12c0-4.04,2.97-5.85,9.23-5.85h28.3l.04,3.45-21.62-.02h0Z"/>
            <path class="cls-1" d="m136.94,78.39c0,3.25-3.13,4.87-9.38,4.87h-32.33l.04-3.24h25.49c1.8,0,2.89-.57,2.89-1.45v-1.21c0-.88-1.09-1.44-2.89-1.44h-18.34c-5.63,0-8.37-1.9-8.37-5.94v-2.65c0-4.04,2.74-5.9,8.37-5.9h32.45l.09,3.24h-25.19c-1.64,0-2.42.43-2.42,1.31v1.02c0,.88.78,1.3,2.42,1.3h17.79c6.25,0,9.38,1.62,9.38,4.87v5.2h0Z"/>
            <path class="cls-1" d="m339.03,78.39c0,3.25-3.13,4.87-9.38,4.87h-32.33l.04-3.24h25.49c1.8,0,2.89-.57,2.89-1.45v-1.21c0-.88-1.1-1.44-2.89-1.44h-18.34c-5.63,0-8.37-1.9-8.37-5.94v-2.65c0-4.04,2.74-5.9,8.37-5.9h32.45l.09,3.24h-25.19c-1.64,0-2.42.43-2.42,1.31v1.02c0,.88.78,1.3,2.42,1.3h17.79c6.26,0,9.38,1.62,9.38,4.87v5.2h0Z"/>
            <polygon class="cls-1" points="478.61 83.26 478.61 77.13 493.31 77.13 493.31 83.26 478.61 83.26 478.61 83.26"/>
    </svg>
};

export default DatacapLogo;