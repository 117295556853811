import {BaseComponent} from "../../../../../../lib/types";
import {Accessor, createEffect, createMemo, Resource, splitProps} from "solid-js";
import {Base} from "../../services/types/tms";
import Card, {CardProps} from "../../../../../../lib/components/card/Card";
import PresentResource from "../../../../../../lib/components/utility/PresentResource";
import Badge from "../../../../../../lib/components/decorations/Badge";
import Icon from "../../../../../../lib/components/icons/Icon";
import {strToMDIPath} from "../../../../../../lib/utils/strToIcon";
import {bytesToSize} from "../../../../../../lib/utils/bytesToSize";

const ItemCard : BaseComponent<{
    // slots:Resource<Array<Base.ModelSlotType>>,
    // items:Resource<Array<Base.Item>>
    slot?:Base.ModelSlotType,
    item:Base.Item
} & Omit<CardProps,"slot">> = (props) => {

    const [local, rest] = splitProps(props, [
        "slot","item","children"
    ])

    return <Card {...rest}>
        {local.slot && local.slot ?<Badge class={"badge-lg badge-light-dark"}>
            {local.slot?.name}
            {` (${local.slot?.deviceModel})`}
        </Badge>:<></>}
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-2 tw-w-full tw-flex-wrap">
            <div class="tw-flex tw-flex-row align-items-center tw-gap-2 tw-w-fit tw-flex-wrap">
                <Icon mdiPath={strToMDIPath(local.item?.type)}/>
                <div class="tw-w-fit tw-min-w-32 tw-break-all">{local.item?.name}</div>
            </div>
            <div class={"text-muted tw-text-sm"}>{local.item?.fileData ? bytesToSize(local.item?.fileData?.size) : local.item?.latestVersionName}</div>
        </div>
        {props.children}
    </Card>
};

export default ItemCard;