import {JSXElement, ParentComponent, Resource} from "solid-js";
import Spinner from "../loading/Spinner";


type ResourceProps<T,P={}> = P&{ id:string, resource: Resource<T>, errorText?:string };

type ResourceComponent<
    T,P={}
> = ParentComponent<ResourceProps<T,P>>;

const PresentResource : ResourceComponent<any> = (props) : JSXElement=> {
    return<>
    {()=>{switch(props.resource.state) {
        default:
        case "pending":
            return <Spinner id={`${props.id}-pending`}/>
        case "errored":
            return <div 
                id={`${props.id}-error`} 
                class={"tw-font-bold"}>⚠ {
                    props.resource?.error?.response?.data?.message??
                    props.errorText ?? "unable to access resource"
                } ⚠
            </div>;
        case "ready":
        case "unresolved":
            return <>{props.children}</>
        case "refreshing":
            return <div id={`${props.id}-refreshing`}
                class="infinite"
                style={{
                    "flex":"inherit",
                    "flex-direction":"inherit",
                    "gap":"inherit",
                    "animation-name": "loading-ghost",
                    "animation-duration":"3.1s",
                    "animation-timing-function":"ease-in-out",
                    "pointer-events":"none",
                    width:"100%",
                    height:"100%"
                }}
            >
                {props.children}
            </div>
    }}}
    </>
    
};

export default PresentResource;
