import {Component} from "solid-js"
import {Modal} from 'solid-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../helpers'
// import {ListsWidget4, ListsWidget5} from '../widgets'

type Props = {
    show: boolean
    handleClose: () => void
}

const SearchModal: Component<Props> = ({show, handleClose}) => {
    return (
        <Modal
            class='bg-body'
            id='kt_header_search_modal'
            aria-hidden='true'
            dialogclass='modal-fullscreen h-auto'
            show={show}
        >
            <div class='modal-content shadow-none'>
                <div class='container-xxl w-lg-800px'>
                    <div class='modal-header d-flex justify-content-end border-0'>
                        {/* begin::Close */}
                        <div class='btn btn-icon btn-sm btn-light-primary ms-2' onClick={handleClose}>
                            <KTIcon class='fs-2' iconName='cross'/>
                        </div>
                        {/* end::Close */}
                    </div>
                    <div class='modal-body'>
                        {/* begin::Search */}
                        <form class='pb-10'>
                            <input
                                autofocus
                                type='text'
                                class='form-control bg-transparent border-0 fs-4x text-center fw-normal'
                                name='query'
                                placeholder='Search...'
                            />
                        </form>
                        {/* end::Search */}

                        {/* begin::Shop Goods */}
                        <div class='py-10'>
                            <h3 class='fw-bolder mb-8'>Shop Goods</h3>

                            {/* begin::Row */}
                            <div class='row g-5'>
                                <div class='col-sm-6'>
                                    <div class='row g-5'>
                                        <div class='col-sm-6'>
                                            <div class='card card-custom overlay min-h-125px mb-5 shadow-none'>
                                                <div class='card-body d-flex flex-column p-0'>
                                                    <div
                                                        class='overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded'
                                                        style={{
                                                            "background-image": `url('${toAbsoluteUrl(
                                                                'media/stock/600x400/img-17.jpg'
                                                            )}')`,
                                                        }}
                                                    />
                                                    <div class='overlay-layer bg-body bg-opacity-50'>
                                                        <a href='#' class='btn btn-sm fw-bold btn-primary'>
                                                            Explore
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='card card-custom overlay min-h-125px mb-5 shadow-none'>
                                                <div class='card-body d-flex flex-column p-0'>
                                                    <div
                                                        class='overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded'
                                                        style={{
                                                            "background-image": `url('${toAbsoluteUrl(
                                                                'media/stock/600x400/img-1.jpg'
                                                            )}')`,
                                                        }}
                                                    />
                                                    <div class='overlay-layer bg-body bg-opacity-50'>
                                                        <a href='#' class='btn btn-sm fw-bold btn-primary'>
                                                            Explore
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <div
                                                class='card card-custom card-stretch overlay mb-5 shadow-none min-h-250px'>
                                                <div class='card-body d-flex flex-column p-0'>
                                                    <div
                                                        class='overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded'
                                                        style={{
                                                            "background-image": `url('${toAbsoluteUrl(
                                                                'media/stock/600x400/img-23.jpg'
                                                            )}')`,
                                                        }}
                                                    />
                                                    <div class='overlay-layer bg-body bg-opacity-50'>
                                                        <a href='#' class='btn btn-sm fw-bold btn-primary'>
                                                            Explore
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-sm-6'>
                                    <div class='card card-custom card-stretch overlay mb-5 shadow-none min-h-250px'>
                                        <div class='card-body d-flex flex-column p-0'>
                                            <div
                                                class='overlay-wrapper flex-grow-1 bgi-no-repeat bgi-size-cover bgi-position-center card-rounded'
                                                style={{
                                                    "background-image": `url('${toAbsoluteUrl(
                                                        'media/stock/600x400/img-11.jpg'
                                                    )}')`,
                                                }}
                                            ></div>
                                            <div class='overlay-layer bg-body bg-opacity-50'>
                                                <a href='#' class='btn btn-sm fw-bold btn-primary'>
                                                    Explore
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end::Row */}
                        </div>
                        {/* end::Shop Goods */}

                        {/* begin::Framework Users */}
                        <div>
                            <h3 class='text-gray-900 fw-bolder fs-1 mb-6'>Framework Users</h3>
                            {/*<ListsWidget4 class='bg-transparent mb-5 shadow-none' innerPadding='px-0' />*/}
                        </div>
                        {/* end::Framework Users */}

                        {/* begin::Tutorials */}
                        <div class='pb-10'>
                            <h3 class='text-gray-900 fw-bolder fs-1 mb-6'>Tutorials</h3>
                            {/*<ListsWidget5 class='mb-5 shadow-none' innerPadding='px-0' />*/}
                        </div>
                        {/* end::Tutorials */}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export {SearchModal}
