import { createContext, useContext, ParentComponent } from "solid-js";
import manager from "./manager";

const TMSContext = createContext(manager);

const TMSProvider : ParentComponent = (props) => {
    return <TMSContext.Provider value={manager}>
        {props.children}
    </TMSContext.Provider>
};
const useTMS = () => {
    return useContext(TMSContext);
};

export {useTMS, TMSProvider};
