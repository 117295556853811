// import {ReactNode} from "solid-js"
import {MenuComponent} from '../assets/ts/components'
import {JSXElement} from "solid-js";

type WithChildren = {
    children?: JSXElement
}

const reInitMenu = () => {
    setTimeout(() => {
        MenuComponent.reinitialization()
    }, 500)
}

export {type WithChildren, reInitMenu}
