import { Accessor, splitProps } from "solid-js";
import Button, { ButtonProps } from "./Button";
import Spinner from "../../loading/Spinner";
import { BaseComponent } from "../../types/components";

type LoadingButtonComponent<T={}>=ButtonProps<{
    isLoading:Accessor<boolean>
}&T>

const LoadingButton:BaseComponent<LoadingButtonComponent> = (props) => {

    const [local,rest] = splitProps(props,["isLoading","id", "children"])

    return <>{
        local.isLoading()
            ?<Spinner classList={{"relative":true}} id={`spinner-${local.id}`} />
            :<Button id={`btn-${local.id}`} {...rest}>{local.children}</Button>
    }</>
};

export default LoadingButton;