import { Component, Context, ParentProps, createContext, useContext } from "solid-js";
import manager from "./manager";

// Context
const BoardingContext = createContext(manager);

const BoardingProvider:Component<ParentProps> = (props) => {
    return <BoardingContext.Provider value={manager}>
        {props.children}
    </BoardingContext.Provider>
};

const useBoarding = () => {
    return useContext(BoardingContext);
};

export {useBoarding, BoardingProvider};
