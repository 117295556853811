import {Component, JSX, splitProps} from "solid-js";
import HTMLAttributes = JSX.HTMLAttributes;

const Label : Component<HTMLAttributes<HTMLLabelElement> & {for:string, required?:boolean}> = (props) =>{

    const [local,rest] = splitProps(props,["for","required","class", "children"])

    return <label for={local.for} class={`form-label ${local.required?"required":""} ${local.class??""}`} {...rest}>
        {local.children}
    </label>
};

export default Label;