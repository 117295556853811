import { createResource } from "solid-js";
import { useToken } from "../Token/TokenProvider";
import authService from "../../services/auth";
import { RouteLoadFuncArgs } from "@solidjs/router";
import { useAlerts } from "../../../../../../lib/context/alert/AlertProvider";


const [_, {addAlert}] = useAlerts();
const [{authApiToken, payload}] = useToken();

const fetchAcctInfo = async (tab:string) => {
    try{
        switch (tab){
            case "company":
                return await authService.getCompanyInfo(await authApiToken());
            case "user":
                return await authService.getUserInfo(await authApiToken());
            default:
                throw {code:"invalid request", message:"tab value not registered"};
        }
    } catch(err) {
        addAlert(err);
        throw err;
    }
}

const fetchUserAcctInfo = async () => {

    return await authService.getUserInfo(await authApiToken());

}

const loadAcctInfo = (params:Record<string,string>) => {
    return createResource(()=>params["tab"], fetchAcctInfo);
};
const loadUserAcctInfo = () => {
    return createResource(()=>true,fetchUserAcctInfo);
};

const sendInitAddMFA = async (method:string) => {
    try{
        return (await authService.initiateAddMFAKey(await authApiToken(),method))?.message;
    } catch(err) {
        addAlert(err);
        throw err;
    }
}

const openSupportLogin = async () =>{
    await authService.initiateSupportLogin(await authApiToken(), payload()?.family);
};

const fetchInitApiKey = async () => {
    return await authService.acquireApiKey(await authApiToken());
}
const sendResetApiKey = async () => {
    return await authService.resetApiKey(await authApiToken());
}

const manager = {
    loadAcctInfo,
    sendInitAddMFA,
    loadUserAcctInfo,
    openSupportLogin,
    fetchInitApiKey,
    sendResetApiKey
};

export default manager;
