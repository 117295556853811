import axiosWrapper from "../util/axiosWrapper";
import type * as PSCS from "./types/pscs";
import type {Getter, Poster} from "./types/service";

const base_url = `${import.meta.env.VITE_PSCS_URL}/API`;

const {runGet, runPost} = axiosWrapper.useAxiosBearer(base_url);

const defaultVersion = "2024-05-14";

type XRequestObject = {[key:string]:string|number};

const parser = new DOMParser();

const checkResponse = async (response:Document) => {
    if(response?.querySelector("Response>Status")?.textContent === "Error"){
        throw {
            message:response
                ?.querySelector("ErrorDescription")
                ?.textContent,
            code:response
                ?.querySelector("ErrorType")
                ?.textContent
        };
    }
};

const getParamValue : Getter<
    Document,PSCS.Request.ParamLookup
> = async (token, queries) =>{
    const resp = await runGet<string>(
        token, "LookupClearValue", queries
    );
    return parser.parseFromString(resp, "application/xml");
};

const getDeploymentList : Getter<
    Document,
    PSCS.Request.DeploymentList
> = async (
        token,
        queries
) => {
    const params:XRequestObject = {
        "API-Version":defaultVersion
    };
    if(isFinite(Number(queries.page_size))){
        params["PageSize"] = Number(queries.page_size) > 0 
            ? queries.page_size 
            : 10;
    } else {
        params["PageSize"] = 10;
    }
    if(isFinite(Number(queries.page_number))){
        params["PageNumber"] = Number(queries.page_number) > 0 
            ? queries.page_number 
            : 1;
    } else {
        params["PageNumber"] = 1;
    }

    // should this use an accept list?
    params["SortBy"] = queries.sort_by ?? "DateLastModified";


    if(queries.search_val?.length>0){
        params[queries.search_by ?? "DeploymentIDContains"] 
            = encodeURIComponent(queries.search_val);
    }

    params["SortType"] = queries.direction==="Desc" ? "Desc" : undefined;

    const resp = await runGet<string>(
        token, "DeploymentList", params
    );
    return parser.parseFromString(resp, "application/xml");
};

const getDeployment : Getter<
    Document,
    PSCS.Request.Deployment
> = async (token, queries) => {
    const finalQueries:XRequestObject = {
        IncludeAlt0:"Y",
        "API-Version":defaultVersion,
        "Securable":"Y",
        IncludeLinkedPinPads:"Y"
    };

    finalQueries["DeploymentID"] = queries.id;

    const resp = await runGet<string>(
        token, 
        "DeploymentInfo", 
        finalQueries
    );
    const doc : Document = parser.parseFromString(resp, "application/xml");

    doc.querySelectorAll("Label").forEach(e=>e.remove());

    return doc;
};

const getPackageList : Getter<
    Document
> = async (token) => {
    const resp = await runGet<string>(
        token, "PackageList", {"API-Version":defaultVersion}
    );
    
    return parser.parseFromString(resp, "application/xml");
};
const getDealerList : Getter<
    Document
> = async (token) => {
    const resp = await runGet<string>(
        token, "DealerList", {"API-Version":defaultVersion}
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const getLinkedPinPadList : Getter<
    Document
> = async (token) => {
    const resp = await runGet<string>(
        token, "LinkedPinPadList", {"API-Version":defaultVersion}
    );
    

    return parser.parseFromString(resp, "application/xml");
}

const getApplicationInfo : Getter<
    Document,
    PSCS.Request.ApplicationInfo
>  = async (token, queries?) => {
    const finalQueries : XRequestObject = {
        "API-Version":defaultVersion, 
        ForUseAsEmbedded: queries.isEmbedded ? "Y" : "N",
        ShowEmbeddedOptions:"Y",
        PartNo:queries.partNo
    };

    if(queries.version){
        finalQueries.Version=queries.version;
    }

    const resp = await runGet<string>(
        token, 
        "ApplicationInfo",
        finalQueries
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const getApplicationList : Getter<
    Document,
    PSCS.Request.ApplicationList
> = async (token, queries?) => {
    const finalQueries : XRequestObject = {
        "API-Version":defaultVersion, 
        ProductType:queries?.productType
    };
    const resp = await runGet<string>(
        token, 
        "ApplicationList", 
        finalQueries
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const createStageFile : Poster<
    Document,
    Document
> = async (token, body) => {
    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(body);
    const resp = await runPost<string>(
        token, 
        null,
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8",
            "API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(resp, "application/xml");
};


const activateStageFile : Poster<Document,Document> = async (token, body) => {
    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(body);
    const response = await runPost<string>(
        token, 
        null,
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8",
            "API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(response,"application/xml");
}

const deactivateStageFile : Poster<Document,Document> = async (token, body) => {
    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(body);
    const response = await runPost<string>(
        token, 
        null,
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8",
            "API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(response,"application/xml");
}

const changeFile : Poster<Document,Document> = async (token, body) => {
    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(body);
    const resp = await runPost<string>(
        token, 
        null,
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8",
            "API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const addLinkedPinPad : Poster<
    Document,
    PSCS.Request.DeploymentLinkPinPad
> = async (token, body) => {


    const dRequest = parser.parseFromString(
        `<?xml version="1.0" encoding="UTF-8"?>` +
        `<Request type="AddLinkedPinPad"></Request>`,
        "application/xml"
    );

    const requestElem = dRequest.querySelector("Request");
    const serialNumElem = dRequest.createElement("PinPadSerialNum");
    serialNumElem.textContent = body.pinpad;
    requestElem.appendChild(serialNumElem);

    if(body.deployment){
        const deploymentElem = dRequest.createElement("DeploymentID");
        deploymentElem.textContent = body.deployment;
        requestElem.appendChild(deploymentElem);

    }

    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(dRequest);
    const resp = await runPost<string>(
        token, 
        null, 
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8",
            "API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const removeLinkedPinPad: Poster<
    Document,
    PSCS.Request.Pinpad
> = async (token, body) => {
    
    const dRequest = parser.parseFromString(
        `<?xml version="1.0" encoding="UTF-8"?>` +
        `<Request type="RemoveLinkedPinPad">` +
            `<PinPadSerialNum/>` +
        `</Request>`, 
        "application/xml"
    );
    
    dRequest.querySelector("PinPadSerialNum").textContent = body.pinpad;

    const serializer = new XMLSerializer();
    const data = serializer.serializeToString(dRequest);
    const resp = await runPost(
        token, 
        null, 
        data, 
        {
            "Content-Type":"application/xml;charset=UTF-8","API-Version":defaultVersion
        }
    );
    
    return parser.parseFromString(resp, "application/xml");
};

const getEmptyDeployment : Getter<Document> = async (token) => {
    
    const dRequest = parser.parseFromString(
        `<?xml version="1.0" encoding="UTF-8"?>` +
        `<Request type="CreateStageFile">` +
            `<Application>`+
            `</Application>`+
            `<MerchantInformation>` +
            `</MerchantInformation>` +
            `<AccountInformation>` +
            `</AccountInformation>` +
        `</Request>`, 
        "application/xml"
    );
    return dRequest;
};

/***
 * this ought to be done on the server
 * including changing options/label/value naming
 * 
 */
const getMerchantRequirements : Getter<Document> = async (token) => {
    

    const packagesResp = (await runGet<string>(
        token, 
        "PackageList"
    ))
        ?.replaceAll("PackageName","Label")
        ?.replaceAll("PackageCode","Value")
        ?.replaceAll("Package", "Option");
    const dPackages = parser.parseFromString(packagesResp, "application/xml");
    checkResponse(dPackages);
    const sharedTokenTypeResp = (await runGet(
        token, "SharedTokenTypes"
    ))
        ?.replaceAll("SharedTokenType", "Option")
        ?.replaceAll("Name","Label")
        ?.replaceAll("ID","Value");
    const dSharedTokenTypes = parser.parseFromString(
        sharedTokenTypeResp, 
        "application/xml"
    );
    checkResponse(dSharedTokenTypes);

    const dMerchInfo = parser.parseFromString(
        `<Response type="MerchantInformationTemplate">`+
            `<Status>Success</Status>`+
            `<Name privacy="public">` +
                `<Validator>.*</Validator>`+
            `</Name>`+
            `<StoreID privacy="public">` +
                `<Validator>.*</Validator>`+
            `</StoreID>`+
            `<Contact privacy="public">` +
                `<Validator>.*</Validator>`+
            `</Contact>`+
            `<Phone privacy="public">` +
                `<Validator>.*</Validator>`+
            `</Phone>`+
            `<Address1 privacy="public">` +
                `<Validator/>`+
            `</Address1>` +
            `<Address2 privacy="public">` +
                `<Validator/>`+
            `</Address2>` +
            `<City privacy="public">` +
                `<Validator/>`+
            `</City>` +
            `<State privacy="public">` +
                `<Option observes="Country" visible="US">
                    <Label>Alabama (AL)</Label>
                    <Value>AL</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Arkansas (AK)</Label>
                    <Value>AK</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Arizona (AZ)</Label>
                    <Value>AZ</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>California (CA)</Label>
                    <Value>CA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Colorado (CO)</Label>
                    <Value>CO</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Connecticut (CT)</Label>
                    <Value>CT</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Delaware (DE)</Label>
                    <Value>DE</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Florida (DFL)</Label>
                    <Value>FL</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Georgia (GA)</Label>
                    <Value>GA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Hawaii (HI)</Label>
                    <Value>HI</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Idaho (ID)</Label>
                    <Value>ID</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Illinois (IL)</Label>
                    <Value>IL</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Indiana (IN)</Label>
                    <Value>IN</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Iowa (IA)</Label>
                    <Value>IA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Kansas (KS)</Label>
                    <Value>KS</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Kentucky (KY)</Label>
                    <Value>KY</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Louisiana (LA)</Label>
                    <Value>LA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Maine (ME)</Label>
                    <Value>ME</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Maryland (MD)</Label>
                    <Value>MD</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Massachusetts (MA)</Label>
                    <Value>MA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Michigan (MI)</Label>
                    <Value>MI</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Minnesota (MN)</Label>
                    <Value>MN</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Mississippi (MS)</Label>
                    <Value>MS</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Missouri (MO)</Label>
                    <Value>MO</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Montana (MT)</Label>
                    <Value>MT</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Nebraska (NE)</Label>
                    <Value>NE</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Nevada (NV)</Label>
                    <Value>NV</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>New Hampshire (NH)</Label>
                    <Value>NH</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>New Jersey (NJ)</Label>
                    <Value>NJ</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>New Mexico (NM)</Label>
                    <Value>NM</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>New York (NY)</Label>
                    <Value>NY</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>North Carolina (NC)</Label>
                    <Value>NC</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>North Dakota (ND)</Label>
                    <Value>ND</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Ohio (OH)</Label>
                    <Value>OH</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Oklahoma (OH)</Label>
                    <Value>OK</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Oregon (OR)</Label>
                    <Value>OR</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Pennsylvania (PA)</Label>
                    <Value>PA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Rhode Island (RI)</Label>
                    <Value>RI</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>South Carolina (SC)</Label>
                    <Value>MT</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>South Dakota (SD)</Label>
                    <Value>SD</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Tennessee (TN)</Label>
                    <Value>TN</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Texas (TX)</Label>
                    <Value>TX</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Utah (UT)</Label>
                    <Value>UT</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Vermont (VT)</Label>
                    <Value>VT</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Virginia (VA)</Label>
                    <Value>VA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Washington (WA)</Label>
                    <Value>WA</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>West Virginia (WV)</Label>
                    <Value>WV</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Wisconsin (WI)</Label>
                    <Value>WI</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Wyoming (WY)</Label>
                    <Value>WY</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>District of Columbia (DC)</Label>
                    <Value>DC</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>American Samoa (AS)</Label>
                    <Value>AS</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Guam (GU)</Label>
                    <Value>GU</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Northern Mariana Islands (MP)</Label>
                    <Value>MP</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Puerto Rico (PR)</Label>
                    <Value>PR</Value>
                </Option>`+
                `<Option observes="Country" visible="US">
                    <Label>Virgin Islands (VI)</Label>
                    <Value>VI</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Alberta (AB)</Label>
                    <Value>AB</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>British Columbia (BC)</Label>
                    <Value>BC</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Manitoba (MB)</Label>
                    <Value>MB</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>New Brunswick (NB)</Label>
                    <Value>NB</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Newfoundland and Labrador (NL)</Label>
                    <Value>NL</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Nova Scotia (NS)</Label>
                    <Value>NS</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Ontario (ON)</Label>
                    <Value>ON</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Prince Edward Island (PE)</Label>
                    <Value>PE</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Quebec (QC)</Label>
                    <Value>QC</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Saskatchewan (SK)</Label>
                    <Value>SK</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Northwest Territories (NT)</Label>
                    <Value>NT</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Nunavut (NU)</Label>
                    <Value>NU</Value>
                </Option>`+
                `<Option observes="Country" visible="CAN">
                    <Label>Yukon (YK)</Label>
                    <Value>YK</Value>
                </Option>`+
                `<DefaultValue>PA</DefaultValue>`+
            `</State>` +
            `<PostalCode privacy="public">` +
                `<Validator/>`+
            `</PostalCode>` +
            `<Country privacy="public">` +
                `<Option>
                    <Label>United States (US)</Label>
                    <Value>US</Value>
                </Option>`+
                `<Option>
                    <Label>Canada (CAN)</Label>
                    <Value>CAN</Value>
                </Option>`+
                `<DefaultValue>US</DefaultValue>`+
            `</Country>` +
            `<PackageCode privacy="public"></PackageCode>` +
            `<AdministrativeMID privacy="public">` +
                `<Validator/>`+
            `</AdministrativeMID>` +
            `<SharedTokenTypeName privacy="public"></SharedTokenTypeName>` +
        `</Response>`, 
        "application/xml"
    );

    dMerchInfo.querySelector("PackageCode").replaceChildren(
        ...(dPackages.querySelectorAll("Option"))
    );

    dMerchInfo.querySelector("SharedTokenTypeName").replaceChildren(
        ...(dSharedTokenTypes.querySelectorAll("Option"))
    );
    

    return dMerchInfo;
};


const getOrderInfoRequirements : Getter<Document> = async (token) => {
    

    const dealerResp = (await runGet(
        token, "DealerList"
    ))
        ?.replaceAll("DealerName","Label")
        ?.replaceAll("DealerCode","Value")
        ?.replaceAll("Dealer", "Option");
    const dDealers = parser.parseFromString(dealerResp, "application/xml");
    checkResponse(dDealers);

    const dOrderInfo = parser.parseFromString(
        `<Response type="OrderInformationTemplate">`+
            `<ShippingInformation>`+
                `<Status>Success</Status>`+
                `<DealerCode/>`+
                `<OrderedBy>` +
                    `<Validator/>`+
                `</OrderedBy>`+
                `<PurchaseOrder>` +
                    `<Validator/>`+
                `</PurchaseOrder>`+
                `<DeviceSelection>` +
                    `<Validator/>`+
                `</DeviceSelection>`+
                `<OrderDetail>` +
                    `<Quantity>` +
                        `<Validator/>`+
                    `</Quantity>`+
                    `<PartNo>` +
                        `<Validator/>`+
                    `</PartNo>`+
                `</OrderDetail>`+
                `<Shipping>` +
                    `<ShipTo>` +
                        `<Validator/>`+
                    `</ShipTo>`+
                    `<ShipAddress>` + 
                        `<Name>` +
                            `<Validator/>`+
                        `</Name>`+
                        `<Contact>` +
                            `<Validator/>`+
                        `</Contact>`+
                        `<Address1>` +
                            `<Validator/>`+
                        `</Address1>`+
                        `<Address2>` +
                            `<Validator/>`+
                        `</Address2>`+
                        `<City>` +
                            `<Validator/>`+
                        `</City>`+
                        `<State>` +
                            `<Validator/>`+
                        `</State>`+
                        `<PostalCode>` +
                            `<Validator/>`+
                        `</PostalCode>`+
                        `<Country>` +
                            `<Validator/>`+
                        `</Country>`+
                    `</ShipAddress>` + 
                    `<ShipMethod>` +
                        `<Validator/>`+
                    `</ShipMethod>`+
                    `<Courier>` +
                        `<Validator/>`+
                    `</Courier>`+
                `</Shipping>` +
                `<Notes>` +
                    `<Validator/>`+
                `</Notes>` +
                `<CreateBackup>` +
                    `<Validator/>`+
                `</CreateBackup>` +
            `</ShippingInformation>`+
        `</Response>`, 
        "application/xml"
    );

    dOrderInfo.querySelector("DealerCode").replaceChildren(
        ...(dDealers.querySelectorAll("Option"))
    );

    return dOrderInfo;
};

const pscsService = {
    getDeploymentList,
    getDeployment,
    getPackageList,
    getDealerList,
    getApplicationList,
    getApplicationInfo,
    getLinkedPinPadList,
    getMerchantRequirements,
    getOrderInfoRequirements,
    addLinkedPinPad,
    removeLinkedPinPad,
    changeFile,
    getEmptyDeployment,
    createStageFile,
    activateStageFile,
    deactivateStageFile,
    getParamValue
};

export default pscsService;
