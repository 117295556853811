import { Component, For } from "solid-js";
import { useAlerts } from "../../context/alert/AlertProvider";
import AlertCard from "./AlertCard";

const AlertOverlay : Component = () => {
    const [{recentAlerts},{deleteRecentAlert}] = useAlerts();
    return <div class="tw-absolute tw-overscroll-y-auto tw-top-0 tw-right-0 tw-py-5 tw-px-2" style={{"z-index":"1000", height:"95vh"}}>
        <div class='tw-flex tw-gap-1 tw-flex-col'>
            <For each={recentAlerts}>{(alert , id)=>{
                return <AlertCard classList={{"tw-shadow-md":true}} alert={alert} onExOut={()=>{
                    deleteRecentAlert(id())
                }}/>
            }}</For>
        </div>
    </div>
};

export default AlertOverlay;