import {Component, createEffect} from "solid-js";
import {Sidebar} from "../../../../../../lib/_metronic/layout/components/sidebar";
import {SidebarMenu} from "../../../../../../lib/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenu";
import {SidebarMenuItem} from "../../../../../../lib/_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import Icon from "../../../../../../lib/components/icons/Icon";
import {
    mdiBriefcase,
    mdiHelpCircle,
    mdiHome,
    mdiPackageVariant,
    mdiStorefront,
    mdiTabletCellphone
} from "@mdi/js";
import {payloaderDarkLogoUrl, payloaderLogoUrl, payloaderSmallLogoUrl} from "../branding/PayloaderLogo";

const PayLoaderSidebar : Component = () => {
    return <Sidebar
        homeUrl={"/app/user"}
        logoDark={payloaderDarkLogoUrl}
        logoLight={payloaderLogoUrl}
        logoSmall={payloaderSmallLogoUrl}
    >
        <SidebarMenu>
            <SidebarMenuItem
                href={"/app/user"}
                title={"Dashboard"}
                mdiIcon={mdiHome}
            />
            <SidebarMenuItem
                href={"/app/Jobs"}
                title={"Jobs"}
                mdiIcon={mdiBriefcase}
            />
            <SidebarMenuItem
                href={"/app/Deployments"}
                title={"Deployments"}
                mdiIcon={mdiStorefront}
            />
            <SidebarMenuItem
                href={"/app/Devices"}
                title={"Devices"}
                mdiIcon={mdiTabletCellphone}
            />
            <SidebarMenuItem
                href={"/app/Payloads"}
                title={"Payloads"}
                mdiIcon={mdiPackageVariant}
            />
            <SidebarMenuItem
                href={"https://datacapsystems.com/support"}
                title={"Support"}
                target={"_blank"}
                mdiIcon={mdiHelpCircle}
            />
        </SidebarMenu>
    </Sidebar>
};

export default PayLoaderSidebar