import { Component } from "solid-js";
import Icon from "../icons/Icon";
import { BaseComponent } from "../types/components";
import { mdiLoading } from "@mdi/js";

const Spinner : BaseComponent = (props) => {
    return <div>
        <Icon
            id={props.id??"base-spinner"}
            mdiPath={mdiLoading}
            classList={{
                "tw-h-12":true,
                "tw-w-12":true,
                "tw-h-5":false,
                "tw-w-5":false,
                "tw-animate-spin":true,
                "tw-transition-all":true
            }}
            pathClassList={{
                "tw-fill-red-900":true,
                "tw-fill-black":false
            }}
        />
    </div>
};

export default Spinner;