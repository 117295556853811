import {Accessor, Component, createMemo, For, JSXElement, Resource} from "solid-js";
import PresentResource from "../../../../../../lib/components/utility/PresentResource";
import { Payloader } from "../../services/types";
import JobCard from "./JobCard";
import Some from "../../../../../../lib/components/utility/Some";
import {strToVariant} from "../../../../../../lib/utils/strToVariant";


const JobList : Component<{
    jobsList:Accessor<Array<Payloader.Base.SomeJob>>,
    summary?:string,
    deviceId?:string,
    filter?:(job:Payloader.Base.SomeJob)=>boolean,
    target?:string,
}> = (props) => {
    const jobsList = createMemo(()=>{
        return props.jobsList()?.filter(props.filter??(()=>true))
    })

    return <div class="p-2 h-full w-100 overflow-y-auto">
        <div class="tw-flex flex-column gap-2">
            <Some id="job-list" item={`Jobs${props.target?` for this ${props.target}`:""}.`} array={jobsList()}>
                {jobsList()?.reverse()?.map((jobInstance, i)=>{
                    return <>
                        <JobCard
                            id={i?.toString()}
                            job={jobInstance}
                            deviceId={props.deviceId}
                            summary={props.summary}
                        />
                        <div class={`separator separator-dashed border-${strToVariant(jobInstance?.state?.status)}`}></div>
                    </>
                })}
            </Some>
        </div>
    </div>
};

export default JobList;