import { JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps } from "../types/props";
import { A, AnchorProps } from "@solidjs/router";

/* TYPES */

export type CardLinkClassList =  Record<string,boolean>;
export type LabelClassList = Record<string,boolean>;

export type CardLinkProps<P={}> = HTMLProps<
    AnchorProps,
    {
        href?:string,
        type?:string,
        classList?: Partial<CardLinkClassList>,
        labelClassList?: Partial<LabelClassList>
        label?:JSXElement,
        children?: JSXElement
    }
> & P;

export type CardLinkComponent<P={}> = BaseComponent<CardLinkProps<P>>;

/* COMPONENT */

const defaultClassList : CardLinkClassList = {
};

const defaultLabelClassList : LabelClassList = {
};

const CardLink : CardLinkComponent = (props) => {
    const [local, rest] = splitProps(
        props,
        [
            "id","ref","classList","href",
            "label","children","labelClassList"
        ]
    );

    return <A
        id={local.id}
        href={local.href}
        class={"card "}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
        <div class={"card-body"}>
            <h4 class={"card-title"} classList={{
                ...defaultLabelClassList,
                ...(local.labelClassList)
            }}>
                {local.label}
            </h4>
            <div class="p-2">
                {local.children}
            </div>

        </div>
    </A>
};

export default CardLink;