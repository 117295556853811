import { A } from "@solidjs/router";
import { Accessor, Component, For, Resource, ResourceReturn, Setter, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import PresentResource from "../../../../../lib/components/utility/PresentResource";
import { useTMS } from "../context/TMS/TMSProvider";
import { Payloader } from "../services/types";
import SearchArea from "../../../../../lib/components/search/SearchArea";
import Button from "../../../../../lib/components/form/controls/Button";
import DeviceGroupList from "../components/device/DeviceGroupList";
import Card, { CardComponent } from "../../../../../lib/components/card/Card";
import { createStore, produce, reconcile } from "solid-js/store";
import { mdiMinusCircle, mdiPencil } from "@mdi/js";
import { modelStrings } from "../context/consts";
import DeviceCard from "../components/device/DeviceCard";
import Title from "../../../../../lib/components/page/Title";
import ButtonWithIcon from "../../../../../lib/components/form/controls/ButtonWithIcon";
import {useBoarding} from "../context/Boarding/BoardingProvider";

type SearchByValue = "GroupName"|"DeviceID"|"DeploymentID"|"Model";
type PageState = "viewing"|"grouping";

/* FUNCTIONS */

const updateDevices = (
    pads:Array<Element>, 
    deviceGroups:Array<Payloader.Base.DeviceGroupDetails>,
    searchQuery:string, 
    searchType:SearchByValue
) => {
    const finalDevices : Array<Element>=[];

    switch(searchType){
        case "DeploymentID":
            for(const device of pads){
                if(
                    device.querySelector("DeploymentID")
                        ?.textContent
                        ?.includes(searchQuery??"")
                ) {
                    finalDevices.push(device);
                }
            }
            break;
        case "GroupName":
            for(const device of pads){

                if(!deviceGroups||!searchQuery||searchQuery===""){
                    finalDevices.push(device);
                    continue;
                }

                const deviceId = device
                    .querySelector("PinPadSerialNum")
                    ?.textContent;

                for(const dg of deviceGroups){
                    if(dg.name.includes(searchQuery)
                        && (dg.deviceIds?.includes(deviceId)??true)
                    ){
                        finalDevices.push(device)
                    }
                }
            }
            break;
        case "DeviceID":
            for(const device of pads){
                if(
                    device.querySelector("PinPadSerialNum")
                        ?.textContent
                        ?.includes(searchQuery??"")
                ) {
                    finalDevices.push(device);
                }
            }
            break;
        default:
            return Array.from(pads);
    }
    return finalDevices;
}

const updateDataList = (
    searchType: SearchByValue,
    devices:Document,
    deviceGroups:Array<Payloader.Base.DeviceGroupDetails>
) => {
    
    switch(searchType){
        case "Model":
            return {
                id:"models-list",
                list:modelStrings?.map(mod=>[mod?.split("_")?.join(" ")])
            }
        case "DeploymentID":

            // for some reason, just restricting an array push to
            // non-included string doesnt work. this is the same 
            // but requires a little more memory
            const deploymentIDs = new Set<string>();

            // replace with a deploymentIDs endpoint on PSCS
            for(const depl of devices.querySelectorAll("DeploymentID")){
                    deploymentIDs.add(depl.textContent);
                
            }

            const deploymentIDArr = [];

            for(const depl of deploymentIDs){
                deploymentIDArr.push([depl])
            }

            return {
                id:"search-deployment-id",
                list:deploymentIDArr
            };
        case "GroupName":
            return {
                id:"search-group-name",
                list:deviceGroups.map(dg=>{
                    return [dg.name]
                })
            }
        case "DeviceID":
        default:
            return {
                id:"search-device-id",
                list:[]
            };

    }
};


/* COMPONENTS */

const DeviceListArea : Component<{
    devices:Accessor<Document>,
    deviceGroups:Resource<Array<Payloader.Base.DeviceGroupDetails>>
}> = (props) => {
    
    const [searchBy, setSearchBy] = createSignal<SearchByValue>("DeviceID");
    const [searchVal, setSearchVal] = createSignal<string>("");

    const deviceListWithGroups = createMemo(()=>{
        const deviceList : Array<[
            Element,
            Array<Payloader.Base.DeviceGroupDetails>
        ]> = [];

        for(const device of props.devices()?.querySelectorAll("LinkedPinPad")??[]){
            
            const deviceGroups : Array<Payloader.Base.DeviceGroupDetails> = [];

            const deviceId = device
                .querySelector("PinPadSerialNum")
                ?.textContent;
            
            for(const dg of props.deviceGroups()){
                if(dg.deviceIds?.includes(deviceId)??true){
                    deviceGroups.push(dg);
                }
            }
            switch(searchBy()){
                case "Model":
                    if(device.querySelector("Model")?.textContent?.includes(searchVal()?.replaceAll(" ","_")?.toUpperCase()??"")){
                        deviceList.push([device,deviceGroups]);
                    }
                    break;
                case "DeploymentID":
                    if(device.querySelector("DeploymentID")?.textContent?.includes(searchVal()?.toUpperCase()??"")){
                        deviceList.push([device,deviceGroups]);
                    }
                    break;
                case "GroupName":
                    if(deviceGroups.some(dg=>dg?.name?.includes(searchVal()??""))){
                        deviceList.push([device,deviceGroups]);
                    }
                    break;
                case "DeviceID":
                default:
                    if(device.querySelector("PinPadSerialNum")?.textContent.includes(searchVal()??"")){
                        deviceList.push([device,deviceGroups]);

                    }
            }


        }
        return deviceList;
    });

    return <div class="tw-flex flex-column gap-4">
    <DeviceSearchArea
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        setSearchVal={setSearchVal}
        deviceGroups={props.deviceGroups}
    />
    {deviceListWithGroups()?.length
        ?deviceListWithGroups()?.map(([device, deviceGroups],id)=>{
            const deviceId = device
                .querySelector("PinPadSerialNum")
                ?.textContent;

            return <DeviceCard
                id={`device-card-${id}`}
                href={`/app/Devices/${deviceId}`}
                device={device}
                deviceGroups={deviceGroups.map(dg=>dg.name)}
            />
        })
        :"No devices."
    }
    </div>
};

const DeviceSearchArea : Component<{
    setSearchBy:Setter<SearchByValue>,  
    setSearchVal:Setter<string>,
    searchBy:Accessor<SearchByValue>,
    deviceGroups:Resource<Array<Payloader.Base.DeviceGroupDetails>>
}> = (props) => {

    const {dataFunctions:{loadLinkedPinPads}} = useBoarding();

    const [pinPads] = loadLinkedPinPads();
    const dataList = createMemo(()=>updateDataList(
        props.searchBy(),
        pinPads(),
        props.deviceGroups()
    ))
    return <SearchArea 
        id="search-devices"
        placeholder={"Search..."}
        classList={{
                "w-100":true
            }}
        dataList={dataList}
        setValue={ props.setSearchVal}
        typeList={[
                ["DeviceID","Device ID"],
                ["GroupName","Group Name"],
                ["DeploymentID","Deployment ID"],
                ["Model","Model Name"]
            ]}
        setType={props.setSearchBy}
    />
};

const GroupDevicesControls : Component<{onClick:()=>void}> = (props) => {
    return <Button id="change-state-grouping"
        onClick={props.onClick}
    >
        View Devices
    </Button>
};
const ViewDevicesControls : Component<{onClick:()=>void}> = (props) => {
    return <Button id="change-state-viewing"
        onClick={props.onClick}
    >
        Group Devices
    </Button>
};

const DeviceGroupInfoArea : Component = (props) => {
    return <></>
};


// TODO: separate out some of the deviceListContent stuff

// const DeviceGroupCard : Component = (props) => {

//     const [selectedDeviceGroup, setSelectedDeviceGroup] = createSignal<Payloader.Base.DeviceGroupDetails>();
//     const [isChoosingDeviceGroup,setIsChoosingDeviceGroup] = createSignal<boolean>(false);


//     const [devicesStore,setDevicesStore] = createStore<Array<{
//         deviceInfo:Element,
//         deploymentId:string,
//         deviceId:string,
//         deviceGroups:Array<string>,
//         isSelected:boolean
//     }>>([]);


//     return <Card
//         id="device-group-card"
//     >
//         <DeviceGroupInfoArea/>
//         {selectedDeviceGroup() && !isChoosingDeviceGroup()
//             ?<DeviceGroupList
//                 devices={devicesStore}
//                 setDevices={setDevicesStore}
//                 type="Device Group"
//                 deviceGroups={deviceGroups}
//                 deploymentIds={deploymentIds()}
//                 setSelectedDeviceIds={setSelectedDeviceIds}
//                 onComplete={()=>{
//                     setDeviceGroupDevices(selectedDeviceGroup())
//                 }}
//             />
//             :<></>
//         }
//     </Card>
// };

const UpgradeList : Component<{
    stage:Accessor<"Grouping"|"Success">,
    setStage:Setter<"Grouping"|"Success">,
    devices:Resource<Document>,
    deviceGroups:Resource<Array<Payloader.Base.DeviceGroupDetails>>,
    setResponse:Setter<{deviceIds:Array<string>}>
}> = (props) =>{ 

    const {calls:{sendRequestLegacyPayloaderUpgrades}} = useTMS();

    const [selectedGroupid, setSelectedGroupId] = createSignal<string>();


    const [devicesStore,setDevicesStore] = createStore<Array<{
        deviceInfo:Element,
        deviceModel:string,
        deploymentId:string,
        deviceId:string,
        deviceGroups:Array<string>,
        isSelected:boolean
    }>>([]);
    const deploymentIds = createMemo(()=>{
        return Array.from(new Set(Array.from(props.devices()?.querySelectorAll("DeploymentID")??[]).map(d=>d.textContent)));
    });

    createEffect(()=>{
        const deviceList : Array<{
            deviceInfo:Element,
            deviceModel:string,
            deploymentId:string,
            deviceId:string,
            deviceGroups:Array<string>,
            isSelected:boolean
        }> = [];

        if(!props.devices()||!props.deviceGroups()){
            return setDevicesStore([]);
        }

        for(const device of props.devices()?.querySelectorAll("LinkedPinPad")){
            const deviceId = device
                .querySelector("PinPadSerialNum")
                ?.textContent;
            const deploymentId = device
                .querySelector("DeploymentID")
                ?.textContent;
            
            const dgs : Array<string> = [];

            let isSelected = false;

            for(const dg of props.deviceGroups()??[]){
                const included = dg.deviceIds?.includes(deviceId);
                isSelected||=(dg.id===selectedGroupid()&&included);
                if(included??true){
                    dgs.push(dg.name);
                }
            }

            if(device?.querySelector("Model")?.textContent?.includes("PAX")){
                deviceList.push({
                    deviceInfo:device,
                    deviceModel:device?.querySelector("Model")?.textContent??"UNKNOWN",
                    deviceId:deviceId,
                    deploymentId:deploymentId,
                    deviceGroups:dgs,
                    isSelected:isSelected
                });

            }

        }
        return setDevicesStore(deviceList);
    });




    createEffect(()=>{console.log(props.stage())})

    return <DeviceGroupList
            devices={devicesStore}
            setDevices={setDevicesStore}
            type={"Upgrade Operation"}
            deploymentIds={deploymentIds()}
            deviceGroups={props.deviceGroups}
            onComplete={async ()=>{
                try{
                    const resp = await sendRequestLegacyPayloaderUpgrades({
                        deviceIds:devicesStore?.filter(d=>d.isSelected)?.map(d=>d.deviceId)
                    });

                    props.setResponse({deviceIds:devicesStore?.filter(d=>d.isSelected)?.map(d=>d.deviceId)});
                    props.setStage("Success");
                } catch {
                    props.setStage("Grouping");

                }
            }}
        
        />


}


const Upgrade : Component = () => {
    const {dataFunctions:{loadLinkedPinPads}} = useBoarding();

    const [devices] = loadLinkedPinPads();
    const {resources:{loadDeviceGroupsList}} = useTMS();
    const [deviceGroups, {refetch:refetchDeviceGroups}] = loadDeviceGroupsList();
    const [stage, setStage] = createSignal<"Grouping"|"Success">("Grouping");

    const [selectedDeviceIds,setSelectedDeviceIds] = createSignal<{deviceIds:Array<string>}>();
    const selectedDevices = createMemo(()=>{
        const selected = [];
        for(const dev of devices()?.querySelectorAll("LinkedPinPad")??[]){
            if(selectedDeviceIds()?.deviceIds.includes(dev?.querySelector("PinPadSerialNum")?.textContent)){
                selected.push(dev);
            }
        }
        return selected;
    })

    return <>
        <Title id="upgrade-payloader-title">Upgrade Payloader</Title>
        <div>
            Here you can upgrade terminals from PayLoader Version 1 to PayLoader Version 2.
            If you need to add devices to a deployment, you can do so in the <A 
                href={"/app/Deployments"}
                class="hover:underline font-bold text-darkred-700"
            > Deployment </A> view.
        </div>

        
        {stage()==="Grouping"
            ?<PresentResource resource={devices} id="devices-rsc">
                <PresentResource resource={deviceGroups} id="device-groups-rsc">
                    <UpgradeList setResponse={setSelectedDeviceIds} stage={stage} setStage={setStage} devices={devices} deviceGroups={deviceGroups}/>
                </PresentResource>
            </PresentResource>
            :<Card
                id="selected-devices"
                label="Success"
                labelClassList={{
                    "bg-neutral-300":false,
                    "bg-green-200":true,
                    "text-green-900":true
                }}
                classList={{
                    "border-neutral-600":false,
                    "border-green-900":true
                }}
            >
                <div>You have successfully scheduled PayLoader upgrades for the devices shown below. Next time these devices start, they will retrieve the newest major version of PayLoader. </div>
                <For each={selectedDevices()}>{(dev, id)=>{
                    const serial=dev.querySelector("PinPadSerialNum")?.textContent;
                    console.log(serial);
                    return <div class="tw-flex flex-row gap-2">
                        <DeviceCard
                            id={`device-${id()}`}
                            device={dev}
                            deviceGroups={[]}
                        />
                        <ButtonWithIcon
                            classList={{
                                "h-full":true,
                                "bg-neutral-500":false,
                                "bg-red-600":true,
                                "hover:bg-neutral-400":false,
                                "hover:bg-red-400":true,

                            }}
                            id={`cancel-${id()}`}
                            onClick={()=>{
                                setSelectedDeviceIds(ids=>{
                                    const new_ids = [];
                                    for(const id of ids?.deviceIds){
                                        if(id!==serial){
                                            new_ids.push(id);
                                        }
                                    }
                                    return {deviceIds:new_ids};
                                })
                            }}
                            mdiPath={mdiMinusCircle}
                        >
                            Cancel
                        </ButtonWithIcon>
                    </div>
                }}</For>
            </Card>
        }
    </>
};

export default Upgrade;