import { Component, JSX, JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps } from "../types/props";
import Card, { CardClassList, CardProps } from "../card/Card";
import Icon from "../icons/Icon";
import { mdiAlphaX, mdiClose } from "@mdi/js";
import { Alert } from "../../context/alert/manager";
import {strToVariant} from "../../utils/strToVariant";
import {Variant} from "solid-bootstrap";

/* TYPES */

export type AlertCardProps<P={}> = CardProps<{
    alert:Alert,
    onExOut?:()=>void,
    hideControls?:boolean
}>;

export type AlertCardComponent = BaseComponent<AlertCardProps>;

/* COMPONENT */

const themeCard = (alertType: "alert" | "error" | "success") : Partial<CardClassList> => {

    let alertClassList : Partial<CardClassList> = {
        "border-neutral-600":false
    };

    switch(alertType){
        case"error":
            alertClassList = {
                "tw-bg-rose-200":true,
                "tw-border-rose-600":true,
                "tw-text-rose-900":true,
                "tw-fill-rose-900":true,
                ...alertClassList
            }
            break;
        case"success":
            alertClassList = {
                "tw-bg-green-200":true,
                "tw-border-green-600":true,
                "tw-text-green-700":true,
                "tw-fill-green-900":true,
                ...alertClassList
            }
            break;
        case"alert":
        default:
            alertClassList = {
                "tw-bg-slate-200":true,
                "tw-border-slate-600":true,
                "tw-text-slate-700":true,
                "tw-fill-slate-700":true,
                ...alertClassList
            }
            break;
    }

    return alertClassList;
}

export const ExOutBtn : Component<{
    onClick?:()=>void,
    variant?:Variant
}>= (props) => {
    return <button
        type="button"
        class={`btn btn-icon btn-icon-${props.variant??"primary"}`}
        onClick={props.onClick}
    >
        <span class={"svg-icon"}>
            <Icon
                id="x"
                classList={{"hover:tw-bg-neutral-50":true, "tw-transition-all":true, "tw-rounded-full":true}}
                pathClassList={{"tw-fill-black":false}}
                mdiPath={mdiClose}
            />

        </span>
    </button>
};


const AlertCard : AlertCardComponent = (props) => {

    const [local,rest] = splitProps(props, ["alert","hideControls", "class"]);

    return <div id={props.id} class={`alert alert-${strToVariant(local.alert?.type)} ${local.class}`} {...rest}>
        <div class={"position-absolute top-0 end-0 m-2"}>

            {local.hideControls || !(props.onExOut)
                ?<></>
                :<ExOutBtn onClick={props.onExOut} variant={strToVariant(local.alert?.type)}/>
            }
        </div>
        <div class="fw-bolder fs-3 mb-5">{props.label ?? local.alert?.code ?? local.alert?.type ?? "Alert"}</div>

        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
        {props.children ?? local.alert?.message}
        {local.alert?.time
            ? <div class={"tw-text-xs mt-2 fw-light"}>{(new Date(local?.alert?.time))?.toString()}</div>
            :<></>
        }
    </div>
    // <Card
    //     id={props.id}
    //     class={`border-${strToVariant(local.alert?.type)} bg-light-${strToVariant(local.alert?.type)} text-${strToVariant(local.alert?.type)}`}
    //     label={props.label??local.alert.code??local.alert.type??"alert"}
    //     labelClassList={{
    //         [`border-${strToVariant(local.alert?.type)}`]:true,
    //         [`border-dashed`]:true,
    //         [`bg-light-${strToVariant(local.alert?.type)}`]:true,
    //         [`text-${strToVariant(local.alert?.type)}`]:true
    //     }}
    //     controls={
    //         local.hideControls
    //             ?<></>
    //             :<ExOutBtn onClick={props.onExOut}/>
    //     }
    //     {...rest}
    // >
    //     {props.children ?? local.alert?.message}
    // </Card>
};

export default AlertCard;