import { JSX, splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import { HTMLProps } from "../../types/props";
import { WideInput } from "../types";

/* TYPES */

export type InputClassList = Record<string,boolean>;

export type InputProps<P={}> = HTMLProps<
    JSX.InputHTMLAttributes<HTMLInputElement>,
    {
        name?:string,
        ref?:HTMLInputElement,
        type?:string,
        classList?: Partial<InputClassList>
    }
> & P;

export type InputComponent = BaseComponent<InputProps>;


/* COMPONENT */

const defaultClassList : InputClassList = {

};

const Input : InputComponent = (props) => {

    const [local, rest] = splitProps(props, ["id","name","ref","type","classList"])

    return <input 
        id={local.id}
        name={local.name}
        ref={local.ref}
        type={local.type??"text"}
        class={"form-control "}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
    </input>
};

export default Input;