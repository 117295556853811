import { splitProps } from "solid-js";
import CardLinkOptional, { CardLinkOptionalComponent } from "../../../../../../lib/components/card/CardLinkOptional";
import LabeledSpanPair from "../../../../../../lib/components/decorations/LabeledSpanPair";
import Card from "../../../../../../lib/components/card/Card";

const DeviceCard : CardLinkOptionalComponent<{
    device:Element,
    deviceGroups:Array<string>,
}> = (props) => {

    const [local, rest] = splitProps(
        props,
        ["id","device","deviceGroups"]  
    );

    return <CardLinkOptional
        id={local.id}
        classList={{"cursor-pointer":true}}
        // label={local.device.querySelector("PinPadSerialNum")?.textContent}
        {...rest}
    >
        <div class="tw-flex tw-flex-row tw-h-full w-100 justify-content-between">
            <div class="tw-flex tw-flex-col tw-h-full tw-w-2/5 tw-gap-2">
                <h4 class={`tw-font-black text-xl cursor:tw-pointer`}>
                    {local.device?.querySelector("PinPadSerialNum")?.textContent??"Unknown Device"}
                </h4>
                <LabeledSpanPair
                    id={`model-info-${props.id}`}
                    title="Model"
                    labelText="Model"
                    labelBgColor="bg-neutral-200"
                    divClass="tw-flex flex-row items-center flex-wrap"
                    valueText={`${
                        props.device
                            .querySelector("Model")
                            ?.textContent
                            ?.split("_")?.join(" ") ?? "UNKNOWN"
                    }`}
                />
                {local.device?.querySelector("DeploymentID")
                    ?<LabeledSpanPair
                        id={`model-info-${props.id}`}
                        title="Deployment"
                        labelText="Deployment"
                        labelBgColor="bg-neutral-200"
                        divClass="tw-flex flex-row items-center flex-wrap"
                        valueText={`${props.device
                            ?.querySelector("DeploymentID")
                            ?.textContent
                        }`}
                    />
                    :<></>
                }
                
            </div>
            <div class="tw-w-1/2">
                <Card id={`address-depl-${props.id}`} classList={{"card-dashed":true}}>
                    {local.device.querySelector("MerchantName")
                        ?<>
                            <div>
                                {local.device.querySelector("MerchantName")?.textContent}
                            </div>
                            <div>
                                {local.device.querySelector("MerchantAddress>Address1")?.textContent} {local.device.querySelector("MerchantAddress>Address2")?.textContent}
                            </div>
                            <div>
                                {local.device.querySelector("MerchantAddress>City")?.textContent?`${local.device.querySelector("MerchantAddress>City")?.textContent},`:""} {local.device.querySelector("MerchantAddress>State")?.textContent} {local.device.querySelector("MerchantAddress>PostalCode")?.textContent} {local.device.querySelector("MerchantAddress>Country")?.textContent==="US"?"🇺🇸":"🇨🇦"}
                            </div>
                        </>
                        :<>Device is owned by user, and has not been linked to a deployment yet.</>
                    }
                </Card>
            </div>
        </div>
        <div class="tw-flex flex-row gap-2 ">
            {local.deviceGroups
                .map((dg,i)=><div
                        id={`device-group-${local.id}-${i}`}
                        title={dg}
                        class={`
                            tw-bg-neutral-500 text-white tw-text-sm 
                            tw-rounded-full tw-px-2 tw-h-fit tw-py-1 tw-truncate 
                            tw-max-w-100
                        `}
                    >
                        {dg}
                    </div>
                )
            }
        </div>
        {props.children}
    </CardLinkOptional>
};

export default DeviceCard;