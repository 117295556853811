import {createSignal} from "solid-js"
// import {createPortal} from 'react-dom'
import {Modal} from 'solid-bootstrap'
import {defaultCreateAppData, ICreateAppData} from './IAppModels'
import {StepperComponent} from '../../../assets/ts/components'
import {KTIcon} from '../../../helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {Portal} from "solid-js/web";

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({show, handleClose}: Props) => {
    let stepperRef!: HTMLDivElement | null;
    const [stepper, setStepper] = createSignal<StepperComponent | null>(null)
    const [data, setData] = createSignal<ICreateAppData>(defaultCreateAppData)
    const [hasError, setHasError] = createSignal(false)

    const loadStepper = () => {
        setStepper(StepperComponent.createInsance(stepperRef as HTMLDivElement))
    }

    const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
        const updatedData = {...data(), ...fieldsToUpdate}
        setData(updatedData)
    }

    const checkAppBasic = (): boolean => {
        if (!data().appBasic.appName || !data().appBasic.appType) {
            return false
        }
        return true
    }

    const checkAppDataBase = (): boolean => {
        if (!data().appDatabase.databaseName || !data().appDatabase.databaseSolution) {
            return false
        }

        return true
    }

    const prevStep = () => {
        if (!stepper) {
            return
        }

        stepper().goPrev()
    }

    const nextStep = () => {
        setHasError(false)
        if (!stepper) {
            return
        }

        if (stepper().getCurrentStepIndex() === 1) {
            if (!checkAppBasic()) {
                setHasError(true)
                return
            }
        }

        if (stepper().getCurrentStepIndex() === 3) {
            if (!checkAppDataBase()) {
                setHasError(true)
                return
            }
        }

        stepper().goNext()
    }

    const submit = () => {
        window.location.reload()
    }

    return <Portal><Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogclass='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
    >
        <div class='modal-header'>
            <h2>Create App</h2>
            {/* begin::Close */}
            <div class='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTIcon class='fs-1' iconName='cross'/>
            </div>
            {/* end::Close */}
        </div>

        <div class='modal-body py-lg-10 px-lg-10'>
            {/*begin::Stepper */}
            <div
                ref={stepperRef}
                class='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
            >
                {/* begin::Aside*/}
                <div class='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                    {/* begin::Nav*/}
                    <div class='stepper-nav ps-lg-10'>
                        {/* begin::Step 1*/}
                        <div class='stepper-item current' data-kt-stepper-element='nav'>
                            {/* begin::Wrapper*/}
                            <div class='stepper-wrapper'>
                                {/* begin::Icon*/}
                                <div class='stepper-icon w-40px h-40px'>
                                    <i class='stepper-check fas fa-check'></i>
                                    <span class='stepper-number'>1</span>
                                </div>
                                {/* end::Icon*/}

                                {/* begin::Label*/}
                                <div class='stepper-label'>
                                    <h3 class='stepper-title'>Details</h3>

                                    <div class='stepper-desc'>Name your App</div>
                                </div>
                                {/* end::Label*/}
                            </div>
                            {/* end::Wrapper*/}

                            {/* begin::Line*/}
                            <div class='stepper-line h-40px'></div>
                            {/* end::Line*/}
                        </div>
                        {/* end::Step 1*/}

                        {/* begin::Step 2*/}
                        <div class='stepper-item' data-kt-stepper-element='nav'>
                            {/* begin::Wrapper*/}
                            <div class='stepper-wrapper'>
                                {/* begin::Icon*/}
                                <div class='stepper-icon w-40px h-40px'>
                                    <i class='stepper-check fas fa-check'></i>
                                    <span class='stepper-number'>2</span>
                                </div>
                                {/* begin::Icon*/}

                                {/* begin::Label*/}
                                <div class='stepper-label'>
                                    <h3 class='stepper-title'>Frameworks</h3>

                                    <div class='stepper-desc'>Define your app framework</div>
                                </div>
                                {/* begin::Label*/}
                            </div>
                            {/* end::Wrapper*/}

                            {/* begin::Line*/}
                            <div class='stepper-line h-40px'></div>
                            {/* end::Line*/}
                        </div>
                        {/* end::Step 2*/}

                        {/* begin::Step 3*/}
                        <div class='stepper-item' data-kt-stepper-element='nav'>
                            {/* begin::Wrapper*/}
                            <div class='stepper-wrapper'>
                                {/* begin::Icon*/}
                                <div class='stepper-icon w-40px h-40px'>
                                    <i class='stepper-check fas fa-check'></i>
                                    <span class='stepper-number'>3</span>
                                </div>
                                {/* end::Icon*/}

                                {/* begin::Label*/}
                                <div class='stepper-label'>
                                    <h3 class='stepper-title'>Database</h3>

                                    <div class='stepper-desc'>Select the app database type</div>
                                </div>
                                {/* end::Label*/}
                            </div>
                            {/* end::Wrapper*/}

                            {/* begin::Line*/}
                            <div class='stepper-line h-40px'></div>
                            {/* end::Line*/}
                        </div>
                        {/* end::Step 3*/}

                        {/* begin::Step 4*/}
                        <div class='stepper-item' data-kt-stepper-element='nav'>
                            {/* begin::Wrapper*/}
                            <div class='stepper-wrapper'>
                                {/* begin::Icon*/}
                                <div class='stepper-icon w-40px h-40px'>
                                    <i class='stepper-check fas fa-check'></i>
                                    <span class='stepper-number'>4</span>
                                </div>
                                {/* end::Icon*/}

                                {/* begin::Label*/}
                                <div class='stepper-label'>
                                    <h3 class='stepper-title'>Storage</h3>

                                    <div class='stepper-desc'>Provide storage details</div>
                                </div>
                                {/* end::Label*/}
                            </div>
                            {/* end::Wrapper*/}

                            {/* begin::Line*/}
                            <div class='stepper-line h-40px'></div>
                            {/* end::Line*/}
                        </div>
                        {/* end::Step 4*/}

                        {/* begin::Step 5*/}
                        <div class='stepper-item' data-kt-stepper-element='nav'>
                            {/* begin::Wrapper*/}
                            <div class='stepper-wrapper'>
                                {/* begin::Icon*/}
                                <div class='stepper-icon w-40px h-40px'>
                                    <i class='stepper-check fas fa-check'></i>
                                    <span class='stepper-number'>5</span>
                                </div>
                                {/* end::Icon*/}

                                {/* begin::Label*/}
                                <div class='stepper-label'>
                                    <h3 class='stepper-title'>Completed</h3>

                                    <div class='stepper-desc'>Review and Submit</div>
                                </div>
                                {/* end::Label*/}
                            </div>
                            {/* end::Wrapper*/}
                        </div>
                        {/* end::Step 5*/}
                    </div>
                    {/* end::Nav*/}
                </div>
                {/* begin::Aside*/}

                {/*begin::Content */}
                <div class='flex-row-fluid py-lg-5 px-lg-15'>
                    {/*begin::Form */}
                    <form noValidate id='kt_modal_create_app_form'>
                        <Step1 data={data()} updateData={updateData} hasError={hasError()}/>
                        <Step2 data={data()} updateData={updateData} hasError={hasError()}/>
                        <Step3 data={data()} updateData={updateData} hasError={hasError()}/>
                        <Step4 data={data()} updateData={updateData} hasError={hasError()}/>
                        <Step5/>

                        {/*begin::Actions */}
                        <div class='d-flex flex-stack pt-10'>
                            <div class='me-2'>
                                <button
                                    type='button'
                                    class='btn btn-lg btn-light-primary me-3'
                                    data-kt-stepper-action='previous'
                                    onClick={prevStep}
                                >
                                    <KTIcon iconName='arrow-left' class='fs-3 me-1'/> Previous
                                </button>
                            </div>
                            <div>
                                <button
                                    type='button'
                                    class='btn btn-lg btn-primary'
                                    data-kt-stepper-action='submit'
                                    onClick={submit}
                                >
                                    Submit <KTIcon iconName='arrow-right' class='fs-3 ms-2 me-0'/>
                                </button>

                                <button
                                    type='button'
                                    class='btn btn-lg btn-primary'
                                    data-kt-stepper-action='next'
                                    onClick={nextStep}
                                >
                                    Next Step <KTIcon iconName='arrow-right' class='fs-3 ms-1 me-0'/>
                                </button>
                            </div>
                        </div>
                        {/*end::Actions */}
                    </form>
                    {/*end::Form */}
                </div>
                {/*end::Content */}
            </div>
            {/* end::Stepper */}
        </div>
    </Modal>
    </Portal>
}

export {CreateAppModal}
