import {render} from "solid-js/web";
import {Route, Router} from "@solidjs/router";

import "./index.scss";
import App from "./App";
let themeMode = 'system'

if (localStorage.getItem('kt_theme_mode_value')) {
    themeMode = localStorage.getItem('kt_theme_mode_value')
}

if (themeMode === 'system') {
    themeMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

document.documentElement.setAttribute('data-bs-theme', themeMode)


const root = document.getElementById("root");

const lazyReloadIfFail = (funct) => {
    try{
        return lazy(funct);
    } catch {
        window.location.replace("/");
        //window.location.reload();
        //console.log("failed page load")
    }
}
const Job = lazyReloadIfFail(()=>import("./page/Job"));
const Device = lazyReloadIfFail(()=>import("./page/Device"));

const DashBoard = lazyReloadIfFail(()=>import("./page/DashBoard"));
const DeploymentList = lazyReloadIfFail(()=>import("./page/DeploymentList"));
const DeviceList = lazyReloadIfFail(()=>import("./page/DeviceList"));
const Payloads = lazyReloadIfFail(()=>import("./page/PayLoads/Payloads"));
const Payload = lazyReloadIfFail(()=>import("./page/Payload/Payload"));
const PayloadCreate = lazyReloadIfFail(()=>import("./page/PayloadCreate"));
const Deployment = lazyReloadIfFail(()=>import("./page/Deployment/Deployment"));
const ManageAccount = lazyReloadIfFail(()=>import("./page/Account"));
const JobCreate = lazyReloadIfFail(()=>import("./page/JobCreate"));

import JobHistory from "./page/JobHistory";
import Upgrade from "./page/UpgradePayloader";
import ItemsList from "./page/ItemsList";
import Unknown from "./page/Unknown";
import Spinner from "../../../../lib/components/loading/Spinner";
import {lazy} from "solid-js";
import {QueryClient, QueryClientProvider} from "@tanstack/solid-query";

const queryClient = new QueryClient();

render(()=>{
    return <QueryClientProvider client={queryClient}>
        <Router root={App}>
        <Route path={"/app"}>
            <Route path={"/"} component={()=><Spinner id={"loading-base-spinner"}/>}/>

            <Route path={[`/Home`,`/user`]} component={DashBoard}/>

            {/* DEVICES */}
            <Route path={`/Devices`} component={DeviceList}/>
            <Route path={`/Devices`}>
                {/* <Route path={`Create`}
                component={Deployment}
                load={loadEmptyDeployment}
            /> */}
                <Route path={"/:id"}
                       component={Device}
                />
            </Route>


            {/* DEPLOYMENTS */}
            <Route path={`/Deployments`} component={DeploymentList}/>
            <Route path={`/Deployments`}>
                {/* <Route path={`Create`}
                component={Deployment}
                load={loadEmptyDeployment}
            /> */}
                <Route path={"/:id"}
                       component={Deployment}
                />
            </Route>

            {/* PAYLOADS */}
            <Route path={`/Payloads`} component={Payloads}/>
            <Route path={`/Payloads`}>
                <Route path={`/create`} component={PayloadCreate}/>
                <Route path={`/:id`} component={Payload}/>
            </Route>

            <Route path={`/Jobs`}>
                <Route path={"/"} component={JobHistory}/>
                <Route path={"/Create"} component={JobCreate}/>
                <Route path={`/:id`} component={Job}/>
            </Route>
            <Route path={`/Account`} component={ManageAccount}/>
            <Route path={`/Upgrade`} component={Upgrade}/>
            <Route path={`/Items`} component={ItemsList}/>

            <Route path="*" component={Unknown}/>

        </Route>
        </Router>
    </QueryClientProvider>
}, root)