import { JSX, JSXElement, splitProps } from "solid-js";
import { HTMLProps } from "../types/props";
import { BaseComponent } from "../types/components";
import SearchBar, { SearchBarClassList, SearchBarProps } from "./SearchBar";

/* TYPES */

type SearchAreaClassList = {
    "flex":boolean,
    "flex-column":boolean,
    "justify-center":boolean,
    "items-center":boolean,
    "gap-2":boolean
};

export type SearchAreaProps = HTMLProps<
    JSX.FormHTMLAttributes<HTMLFormElement>,
    {
        children?:JSXElement,
        ref?:HTMLFormElement,
        label?:JSXElement,
        formClass?:string,
        classList?:Partial<SearchAreaClassList>,
        searchBarClassList?:Partial<SearchBarClassList>,
        onSubmit?:JSX.EventHandler<HTMLFormElement,Event&{
            submitter:HTMLElement
        }>,
        searchRef?:HTMLInputElement
    } & Omit<SearchBarProps,"name">
>; 
export type SearchAreaComponent = BaseComponent<SearchAreaProps>;

/* COMPONENT */

const defaultClassList : SearchAreaClassList = {
    "flex":true,
    "flex-column":true,
    "justify-center":true,
    "items-center":true,
    "gap-2":true
};

const SearchArea : SearchAreaComponent = (props) => {

    const [local, searchBarProps] = splitProps(
        props,
        ["onSubmit", "id", "children", "classList", "searchBarClassList","label", "formClass"]
    );

    return <form
        onsubmit={(event)=>{
            event.preventDefault();
            if(local.onSubmit){
                local.onSubmit(event);
            }
        }}
        data-kt-search-element="form" class={`w-100 position-relative mb-5 ${local.formClass??""}`} autocomplete="off"
    >
        <SearchBar
            id={`search-bar-${props.id}`}
            label={local.label}
            name={`SearchBar`}
            classList={local.searchBarClassList}
            {...searchBarProps}
        />
    </form>;
};

export default SearchArea;