import {createEffect, onMount, ParentComponent} from "solid-js";
import {PageDataProvider, usePageData} from "../../../../../../lib/_metronic/layout/core";
import {HeaderWrapper} from "../../../../../../lib/_metronic/layout/components/header";
import {Sidebar} from "../../../../../../lib/_metronic/layout/components/sidebar";
import {FooterWrapper} from "../../../../../../lib/_metronic/layout/components/footer";
import {useAccount} from "../../context/Account/AccountProvider";
import {createResource} from "solid-js";
import {useToken} from "../../context/Token/TokenProvider";
import {reInitMenu} from "../../../../../../lib/_metronic/helpers";
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from "../../../../../../lib/_metronic/partials";
import {RightToolbar} from "../../../../../../lib/_metronic/partials/layout/RightToolbar";
import {ScrollTop} from "../../../../../../lib/_metronic/layout/components/scroll-top";
import {DrawerComponent, StickyComponent, ToggleComponent} from "../../../../../../lib/_metronic/assets/ts/components";
import PayLoaderSidebar from "./PayLoaderSidebar";
import PayLoaderHeader from "./PayLoaderHeader";
import {PageTitleWrapper} from "../../../../../../lib/_metronic/layout/components/toolbar/page-title";
import {Content} from "../../../../../../lib/_metronic/layout/components/content";
import {useLocation} from "@solidjs/router";
import {pageDetailMap} from "../../util/routing";
import {ToolbarWrapper} from "../../../../../../lib/_metronic/layout/components/toolbar";
import AlertOverlay from "../../../../../../lib/components/alert/AlertOverlay";

const reinitComponents = () => {
    setTimeout(() => {
        StickyComponent.reInitialization()
        setTimeout(() => {
            ToggleComponent.reinitialization()
            DrawerComponent.reinitialization()
        }, 70)
    }, 140)
}

const Layout : ParentComponent = (props) =>{

    const accountService = useAccount();
    const [{authApiToken}] = useToken();
    window.addEventListener("resize",() => {
        reInitMenu();
        reinitComponents();
    })

    onMount(()=>{
        reinitComponents()
    })

    // const location = useLocation();
    // const pageData = usePageData();

    // createEffect(() => {
    //     location.pathname
    //     pageData.setPageTitle(pageDetailMap["/Home"]?.title)
    //     pageData.setPageDescription(pageDetailMap["/Home"]?.title)
    // })

    const userInfo = createResource(accountService.loadUserAcctInfo, authApiToken);

    return <PageDataProvider>
        <div class='tw-flex flex-column flex-root app-root' id='kt_app_root'>
            <div class='app-page flex-column flex-column-fluid' id='kt_app_page'>
                <PayLoaderHeader/>
                <div class='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                    <PayLoaderSidebar/>
                    <div class='app-main flex-column flex-row-fluid tw-gap-2' id='kt_app_main'>
                        {/*<DefaultTitle/>*/}

                        <ToolbarWrapper/>
                        <AlertOverlay/>
                        <Content>
                            {props.children}
                        </Content>
                        {/*<div class='tw-flex flex-column flex-column-fluid tw-p-4'>*/}
                        {/*</div>*/}
                        <FooterWrapper/>
                    </div>
                </div>
            </div>
        </div>

        {/*<ScrollTop/>*/}
    </PageDataProvider>
};

export default Layout;