import {A} from '@solidjs/router'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Component, createEffect, createMemo, JSXElement} from "solid-js"
import {ToggleComponent} from '../../../assets/ts/components'

type PropsType = {
    sidebarRef: HTMLDivElement,
    href?:string,
    logoDark?:string,
    logoLight?:string,
    logoSmall?:string,
}

const SidebarLogo : Component<PropsType> = (props: PropsType) => {
    const {config} = useLayout()
    let toggleRef!: HTMLDivElement;

    const appSidebarDefaultMinimizeDesktopEnabled =
        createMemo(()=>config()?.app?.sidebar?.default?.minimize?.desktop?.enabled)
    const appSidebarDefaultCollapseDesktopEnabled =
        createMemo(()=>config()?.app?.sidebar?.default?.collapse?.desktop?.enabled)
    const toggleType = createMemo(()=>appSidebarDefaultCollapseDesktopEnabled()
        ? 'collapse'
        : appSidebarDefaultMinimizeDesktopEnabled()
            ? 'minimize'
            : '');
    const toggleState = createMemo(()=>appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '')
    const appSidebarDefaultMinimizeDefault = createMemo(()=>config().app?.sidebar?.default?.minimize?.desktop?.default)

    createEffect(() => {
        toggleState();
        setTimeout(() => {
            const toggleObj = ToggleComponent.getInstance(toggleRef!) as ToggleComponent | null

            if (toggleObj === null) {
                return
            }

            // Add a class to prevent sidebar hover effect after toggle click
            toggleObj.on('kt.toggle.change', function () {
                // Set animation state
                props.sidebarRef!.classList.add('animating')

                // Wait till animation finishes
                setTimeout(function () {
                    // Remove animation state
                    props.sidebarRef!.classList.remove('animating')
                }, 300)
            })
        }, 600)
    })

    return (
        <div class='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
            <A href={props.href??"/dashboard"}>
                {config().layoutType === 'dark-sidebar' ? (
                    <img
                        alt='Logo'
                        src={props.logoDark??toAbsoluteUrl('media/logos/default-dark.svg')}
                        class='h-30px app-sidebar-logo-default'
                    />
                ) : (
                    <>
                        <img
                            alt='Logo'
                            src={props.logoLight??toAbsoluteUrl('media/logos/default.svg')}
                            class='h-30px app-sidebar-logo-default theme-light-show'
                        />
                        <img
                            alt='Logo'
                            src={props.logoDark??toAbsoluteUrl('media/logos/default-dark.svg')}
                            class='h-30px app-sidebar-logo-default theme-dark-show'
                        />
                    </>
                )}
                {/*TODO: maybe parameterize this? should be tw-h-12*/}
                <img
                    alt='Logo'
                    src={props.logoSmall??toAbsoluteUrl('media/logos/default-small.svg')}
                    class='h-30px app-sidebar-logo-minimize'
                />
            </A>

            {(appSidebarDefaultMinimizeDesktopEnabled() || appSidebarDefaultCollapseDesktopEnabled()) && (
                <div
                    ref={toggleRef}
                    id='kt_app_sidebar_toggle'
                    class={clsx(
                        'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
                        {active: appSidebarDefaultMinimizeDefault()}
                    )}
                    data-kt-toggle='true'
                    data-kt-toggle-state={toggleState()}
                    data-kt-toggle-target='body'
                    data-kt-toggle-name={`app-sidebar-${toggleType()}`}
                >
                    <KTIcon iconName='black-left-line' class='fs-3 rotate-180 ms-1'/>
                </div>
            )}
        </div>
    )
}

export {SidebarLogo}
