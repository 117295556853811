import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {Accessor, Component, JSXElement, ParentComponent} from "solid-js";
import {User} from "../../../types/User";

const itemClass = 'ms-1 ms-md-4'
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
import {A} from "@solidjs/router";
import {MenuComponent} from "../../../assets/ts/components";

window.addEventListener("resize",()=>{
    MenuComponent.hideDropdowns();
})

const Dropdown : ParentComponent<{id:string}> = (props) => {
    let ref !:HTMLDivElement;

    return <div
        id={props.id}
        class={"menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"}
        data-kt-menu={"true"}
        ref={ref}
    >
        {props.children}
    </div>
};

export const NavbarItem : ParentComponent<{targetId:string,customItemClass?:string, mdiPath?:string, ktiName?:string, customIconClass?:string}> = (props) => {

    return <div class={`app-navbar-item ${itemClass} ${props.customItemClass??""}`}>
        <div class={clsx('cursor-pointer symbol', btnClass)}
             data-kt-menu-trigger="{default: 'click'}"
             data-kt-menu-attach='parent'
             data-kt-menu-placement='bottom-end'
             data-kt-menu-target={`#${props.targetId}`}
        >
            {props.ktiName
                ?<KTIcon iconName={props.ktiName} iconType={"duotone"} class={"fs-3x"}/>
                :<svg viewBox={"0 0 24 24"} class={`h-100 w-100 ${props.customItemClass??""}`}>
                    <path fill={"currentColor"} d={props.mdiPath}/>
                </svg>
            }
        </div>
        <Dropdown id={props.targetId}>
            {props.children}
        </Dropdown>
    </div>
}
// export const NavbarItem : ParentComponent<{customItemClass?:string, customIconClass?:string, iconName:string}> = (props) => {
//     return <div class={`app-navbar-item ${itemClass} ${props.customItemClass??""}`}>
//         <div class={clsx('cursor-pointer symbol', btnClass)}
//              data-kt-menu-trigger="{default: 'click'}"
//              data-kt-menu-attach='parent'
//              data-kt-menu-placement='bottom-end'
//         >
//             <KTIcon iconName={props.iconName} class={`fs-2 ${props.customIconClass??""}`}/>
//         </div>
//         {props.children}
//     </div>
// }

const Navbar: ParentComponent = (props) => {
    const {config} = useLayout()
    return <div class={"app-navbar flex-shrink-0"}>
        {props.children}
        {/*{config().app?.header?.default?.menu?.display && (*/}
        {/*    <div class='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>*/}
        {/*        <div*/}
        {/*            class='btn btn-icon btn-active-color-primary w-35px h-35px'*/}
        {/*            id='kt_app_header_menu_toggle'*/}
        {/*        >*/}
        {/*            <KTIcon iconName='text-align-left' class={btnIconClass}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*)}*/}
    </div>
    // return (
    //     <div class='app-navbar flex-shrink-0'>
    //         <div class={clsx('app-navbar-item align-items-stretch', itemClass)}>
    //             <Search/>
    //         </div>
    //
    //         <div class={clsx('app-navbar-item', itemClass)}>
    //             <div id='kt_activities_toggle' class={btnClass}>
    //                 <KTIcon iconName='chart-simple' class={btnIconClass}/>
    //             </div>
    //         </div>
    //
    //         <div class={clsx('app-navbar-item', itemClass)}>
    //             <div
    //                 data-kt-menu-trigger="{default: 'click'}"
    //                 data-kt-menu-attach='parent'
    //                 data-kt-menu-placement='bottom-end'
    //                 class={btnClass}
    //             >
    //                 <KTIcon iconName='element-plus' class={btnIconClass}/>
    //             </div>
    //             <HeaderNotificationsMenu/>
    //         </div>
    //
    //         <div class={clsx('app-navbar-item', itemClass)}>
    //             <div class={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
    //                 <KTIcon iconName='message-text-2' class={btnIconClass}/>
    //                 <span
    //                     class='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'/>
    //             </div>
    //         </div>
    //
    //         <div class={clsx('app-navbar-item', itemClass)}>
    //             <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')}/>
    //         </div>
    //
    //         <div class={clsx('app-navbar-item', itemClass)}>
    //             <div
    //                 class={clsx('cursor-pointer symbol', userAvatarClass)}
    //                 data-kt-menu-trigger="{default: 'click'}"
    //                 data-kt-menu-attach='parent'
    //                 data-kt-menu-placement='bottom-end'
    //             >
    //                 <img src={toAbsoluteUrl('media/avatars/300-3.jpg')} alt=''/>
    //             </div>
    //             {/*<HeaderUserMenu currentUser={props.user()} logout={props.logout}/>*/}
    //         </div>
    //
    //
    //     </div>
    // )
}

export {Navbar}
