import {useLayout} from '../../../core'
import {PageTitle} from './PageTitle'

const PageTitleWrapper = () => {
    const {config} = useLayout()

    return <>{
        config().app?.pageTitle?.display
            ?<PageTitle/>
            : <></>
    }</>
}

export {PageTitleWrapper}
