import {createEffect, ParentComponent} from "solid-js"
import {useLocation} from '@solidjs/router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'

const Content : ParentComponent = (props) => {
    const {config, classes} = useLayout()
    const location = useLocation()
    createEffect(() => {
        location.pathname
        DrawerComponent.hideAll()
    })

    const appContentContainer = config().app?.content?.container
    return (
        <div
            id='kt_app_content'
            class={clsx(
                'app-content flex-column-fluid',
                classes().content.join(' '),
                config()?.app?.content?.class
            )}
        >
            {/*TODO: consider making this a wrapped row and putting a max and min width on all cards*/}
            {appContentContainer ? (
                <div
                    id='kt_app_content_container'

                    class={clsx('app-container d-flex flex-column tw-gap-4', classes().contentContainer.join(' '), {
                        'container-xxl': appContentContainer === 'fixed',
                        'container-fluid': appContentContainer === 'fluid',
                    })}
                >
                    {props.children}
                </div>
            ) : (
                <>{props.children}</>
            )}
        </div>
    )
}

export {Content}
