import {JSX, JSXElement, splitProps} from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps } from "../types/props";
import {Card as BSCard} from "solid-bootstrap";


/* TYPES */

export type CardClassList = {
    "rounded-md":boolean,
    "border-2":boolean,
    "border-neutral-600":boolean,
    "overflow-hidden":boolean,
    "relative":boolean,
    "flex":boolean,
    "flex-column":boolean,
} & Record<string,boolean>;

export type CardContentClassList = {
    "tw-flex":boolean,
    "flex-column":boolean,
    "gap-2":boolean,
    "w-100":boolean,
    // "p-2":boolean
} & Record<string,boolean>;

export type LabelClassList = {
    "bg-neutral-300":boolean,
    // "p-2":boolean,
    "font-bold":boolean,
} & Record<string,boolean>;

export type CardProps<P={}> = HTMLProps<
    JSX.HTMLAttributes<HTMLDivElement>,
    {
        ref?:HTMLDivElement,
        type?:string,
        classList?: Partial<CardClassList>,
        labelClassList?: Partial<LabelClassList>,
        contentClassList?:Partial<CardContentClassList>
        label?:JSXElement,
        children?: JSXElement,
        controls?:JSXElement
    }
> & P;

export type CardComponent<P={}> = BaseComponent<CardProps<P>>;

/* COMPONENT */

const defaultClassList : CardClassList = {
    "rounded-md":true,
    "border-2":true,
    "relative":true,
    "border-neutral-600":true,
    "bg-white":true,
    "flex":true,
    "flex-column":true,
    "overflow-hidden":true,
    "break-words":true,
    "w-full":true
};

const defaultCardContentClassList : CardContentClassList = {
    "tw-flex":true,
    "flex-column":true,
    "gap-2":true,
    // "p-2":true,
    "w-100":true
};

const defaultLabelClassList : LabelClassList = {
    "bg-neutral-300":true,
    // "p-2":true,
    "font-bold":true,
    "w-full":true,
    "break-words":true
} ;

const Card : CardComponent = (props) => {

    const [local, rest] = splitProps(
        props,
        ["id","ref","classList","controls","label","children","labelClassList", "contentClassList", "class"]
    );

    return <div
        class={`card ${local.class}`}
        id={local.id}
        ref={local.ref}
        classList={local.classList}
        {...rest}
    >
        {local.label
            ?<div class={"card-header"} classList={{
                ...defaultLabelClassList,
                ...(local.labelClassList)
            }}>
                <h3 class={"card-title"} style={{"color":"inherit !important"}}>{local.label}</h3>
                {local.controls
                    ?<div id={`${local.id}-controls`} class="card-toolbar tw-flex flex-row gap-2 flex-wrap tw-z-10">
                        {local.controls}
                    </div>
                    :<></>
                }
            </div>
            :local.controls
                ?<div id={`${local.id}-controls`} class="card-toolbar position-absolute top-0 end-0 tw-z-10">
                    {local.controls}
                </div>
                :<></>
        }
        <div class={"card-body"} classList={{
            ...defaultCardContentClassList,
            ...(local.contentClassList)
        }}>
            {local.children}
        </div>
    </div>
};

export default Card;