import {Variant} from "solid-bootstrap";
import {Accessor, createMemo} from "solid-js";



export const strToVariant = (str: string) : Variant  => {
    switch(str?.toLowerCase()){
        case "success":
        case "succeeded":
        case "successful":
        case "activated":
            return "success";
        case "error":
        case "failed":
            return "danger";
        case "warning":
        case "paid":
        case "pending":
            return "warning";
        case "alert":
        case "started":
        case "info":
            return "info";
            // return "dark";
        case "cancelled":
        case "deactivated":
            return "muted"
        case "scheduled":
        case "created":
            return "light";
        case "completed":
        case "staged":
        default:
            return "primary";
    }
};

export const createVariant = (str:Accessor<string>)=>createMemo(()=>strToVariant(str()));