import clsx from 'clsx'
import {A} from '@solidjs/router'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {LayoutSetup, useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {Accessor, ParentComponent} from "solid-js";
import {User} from "../../../types/User";
import PayloaderLogo from "../../../../../projects/client/src/app/components/branding/PayloaderLogo";

export const HeaderWrapper : ParentComponent<{homeUrl:string, logoDark?:string,logoLight?:string,logoSmall?:string}> = (props)=> {
    const {config, classes} = useLayout()
    if (config().app?.header?.default?.container === 'fluid') {
        LayoutSetup.classes.headerContainer.push("container-fluid");
    } else {
        LayoutSetup.classes.headerContainer.push("container-xxl");
    }
    if (!config().app?.header?.display) {
        return null
    }

    return (
        <div
            id='kt_app_header'
            class='app-header'
            data-kt-sticky='true'
            data-kt-sticky-activate='{default: true, lg: true}'
            data-kt-sticky-name='app-header-minimize'
            data-kt-sticky-offset='{default: "200px", lg: "0"}'
            data-kt-sticky-animation='false'
        >
            <div
                id='kt_app_header_container'
                class={clsx(
                    'app-container',
                    classes().headerContainer.join(' '),
                    config().app?.header?.default?.containerClass
                )}
            >
                {config().app.sidebar?.display && (
                    <>
                        {config().layoutType !== 'dark-header' && config().layoutType !== 'light-header' ? (
                            <div
                                class='d-flex align-items-center d-lg-none ms-n2 me-2'
                                title='Show sidebar menu'
                            >
                                <div
                                    class='btn btn-icon btn-active-color-primary w-35px h-35px'
                                    id='kt_app_sidebar_mobile_toggle'
                                >
                                    <KTIcon iconName='abstract-14' class=' fs-1'/>
                                </div>
                                <div class='d-flex align-items-center flex-grow-1 flex-lg-grow-0 mx-4'>
                                    <A href={props.homeUrl} class='d-lg-none'>
                                        <img
                                            alt='Logo'
                                            src={props.logoLight??toAbsoluteUrl('media/logos/default-small.svg')}
                                            class='h-30px'
                                        />
                                    </A>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}

                {!(config().layoutType === 'dark-sidebar' || config().layoutType === 'light-sidebar') && (
                    <div class='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
                        <A href={props.homeUrl}>
                            {config().layoutType === 'dark-header' ? (
                                <PayloaderLogo logoUrl={props.logoDark}/>
                            ) : (
                                <>
                                    <img
                                        alt='Logo'
                                        src={props.logoLight??toAbsoluteUrl('media/logos/default.svg')}
                                        class='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                                    />
                                    <img
                                        alt='Logo'
                                        src={props.logoDark??toAbsoluteUrl('media/logos/default-dark.svg')}
                                        class='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                                    />
                                </>
                            )}
                        </A>
                    </div>
                )}

                <div
                    id='kt_app_header_wrapper'
                    class='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
                >
                    {props.children}
                    {/*<div class='app-header-menu app-header-mobile-drawer align-items-stretch'*/}
                    {/*     data-kt-drawer='true'*/}
                    {/*     data-kt-drawer-name='app-header-menu'*/}
                    {/*     data-kt-drawer-activate='{default: true, lg: false}'*/}
                    {/*     data-kt-drawer-overlay='true'*/}
                    {/*     data-kt-drawer-width='225px'*/}
                    {/*     data-kt-drawer-direction='end'*/}
                    {/*     data-kt-drawer-toggle='#kt_app_header_menu_toggle'*/}
                    {/*     data-kt-swapper='true'*/}
                    {/*     data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"*/}
                    {/*     data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"*/}
                    {/*>*/}
                    {/*    <Header/>*/}
                    {/*</div>*/}
                    {/*/!*<Navbar user={props.user} logout={props.logout}/>*!/*/}
                </div>
            </div>
        </div>
    )
}
