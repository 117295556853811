/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Component, createContext, useContext, createSignal, createEffect, Accessor, onMount} from "solid-js"
import {DefaultConfig} from './_LayoutConfig'
import {
    setLayoutIntoLocalStorage,
    getEmptyCssClasses,
    getEmptyCSSVariables,
    getEmptyHTMLAttributes,
    LayoutSetup,
} from './_LayoutSetup'
import {
    ILayout,
    ILayoutCSSVariables,
    ILayoutCSSClasses,
    ILayoutHTMLAttributes,
    LayoutType,
    ToolbarType,
} from './_Models'
import {WithChildren} from '../../helpers'

export interface LayoutContextModel {
    config: Accessor<ILayout>
    classes: Accessor<ILayoutCSSClasses>
    attributes: Accessor<ILayoutHTMLAttributes>
    cssVariables: Accessor<ILayoutCSSVariables>
    setLayout: (config: LayoutSetup) => void
    setLayoutType: (layoutType: LayoutType) => void
    setToolbarType: (toolbarType: ToolbarType) => void
}

export interface LayoutContextModelStatic {
    config: Accessor<ILayout>
    classes: Accessor<ILayoutCSSClasses>
    attributes: Accessor<ILayoutHTMLAttributes>
    cssVariables: Accessor<ILayoutCSSVariables>
    setLayout: (config: LayoutSetup) => void
    setLayoutType: (layoutType: LayoutType) => void
    setToolbarType: (toolbarType: ToolbarType) => void
}

const [config, setConfig] = createSignal(DefaultConfig)
const [classes, setClasses] = createSignal(LayoutSetup.classes)
const [attributes, setAttributes] = createSignal(LayoutSetup.attributes)
const [cssVariables, setCSSVariables] = createSignal(LayoutSetup.cssVariables)

const LayoutContext = createContext<LayoutContextModel>({
    config,
    classes,
    attributes,
    cssVariables,
    setLayout: (_config: LayoutSetup) => {
    },
    setLayoutType: (_layoutType: LayoutType) => {
    },
    setToolbarType: (_toolbarType: ToolbarType) => {
    },
})

const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'flex')
    }
}

const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'none')
    }
}

const LayoutProvider: Component<WithChildren> = ({children}) => {

    const setLayout = (_themeConfig: Partial<ILayout>) => {
        enableSplashScreen()
        const bodyClasses = Array.from(document.body.classList)
        bodyClasses.forEach((cl) => document.body.classList.remove(cl))
        const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
        setConfig(Object.assign({}, updatedConfig))
        setClasses(LayoutSetup.classes)
        setAttributes(LayoutSetup.attributes)
        setCSSVariables(LayoutSetup.cssVariables)
        setTimeout(() => {
            disableSplashScreen()
        }, 500)
    }

    const setToolbarType = (toolbarType: ToolbarType) => {
        const updatedConfig = {...config()}
        if (updatedConfig.app?.toolbar) {
            updatedConfig.app.toolbar.layout = toolbarType
        }

        setLayoutIntoLocalStorage(updatedConfig)
        window.location.reload()
    }

    const setLayoutType = (layoutType: LayoutType) => {
        const updatedLayout = {...config, layoutType}
        setLayoutIntoLocalStorage(updatedLayout)
        window.location.reload()
    }

    const value: LayoutContextModel = {
        config,
        classes,
        attributes,
        cssVariables,
        setLayout,
        setLayoutType,
        setToolbarType,
    }

    onMount(() => {
        disableSplashScreen();
    })

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
    return useContext(LayoutContext)
}
