import { splitProps } from "solid-js";
import Icon from "../../icons/Icon";
import Button, { ButtonComponent } from "./Button";

const ButtonWithIcon : ButtonComponent<{
    mdiPath:string,
    iconFill?:string
}> = (props) => {

    const [local, rest] = splitProps(props,[
        "iconFill","mdiPath","id", "children","title"
    ]);

    return <Button
        id={`${local.id}-btn`}
        {...rest}
    >
        <Icon id={`${local.id}-btn-icon`}
            mdiPath={local.mdiPath}
            title={local.title}
        />
        <div>
            {local.children}
        </div>
    </Button>
};

export default ButtonWithIcon;