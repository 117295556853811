import { Component, ParentProps, createContext, useContext } from "solid-js";

import manager from "./manager";

const AlertContext = createContext(manager);
const AlertProvider : Component<ParentProps> = (props) => {
    return <AlertContext.Provider value={manager}>
        {props.children}
    </AlertContext.Provider>
};

const useAlerts = () => {
    return useContext(AlertContext);
};

export {useAlerts, AlertProvider};