import axiosWrapper from "../util/axiosWrapper";
import type * as Payloader from "./types/tms";
import type {Getter, Poster} from "./types/service";

const base_url = `${import.meta.env.VITE_PAYLOADER_URL}`;

const {runDelete, runGet, runPut, runPatch, runPost} = axiosWrapper
    .useAxiosBearer(base_url);

/**
 * 
 * VERSION 2
 * 
 */

const tmsCaller = (
    tmsToken:()=>Promise<string>
) => ({
    getLinkingCode : async () : Promise<Payloader.Response.LinkingCode> =>{
        await new Promise(resolve => setTimeout(resolve, 1700));
        return await runGet(
            await tmsToken(), "Account/GetLinkingCode"
        );
    },
    uploadImageItem : async (body : FormData) : Promise<
        Payloader.Response.ItemCreate<Payloader.Base.ImageItem>
    > => {
        return await runPut(
            await tmsToken(), "Items/UploadImageItem", 
            body
        );
    },
    uploadPaxPackageItem : async (body : FormData) : Promise<
        Payloader.Response.ItemCreate<Payloader.Base.PaxFormPackageItem>
    > => {
        return await runPost(
            await tmsToken(), "Items/SubmitApplication", 
            body
        );
    },
    downloadItem : async (queries:{Id:string}) : Promise<
        any /*TODO: add blank response type*/
    > => {
        return await runGet(
            await tmsToken(), "Items/DownloadItem", 
            queries
        );
    },
    deleteItem : async (queries:{id:string}) : Promise<
        any /*TODO: add blank response type*/
    > => {
        return await runDelete(
            await tmsToken(), "Items/DeleteItem", 
            queries
        );
    },
    getItems : async () : Promise <
        Payloader.Response.ListedItems
    > => {
        return await runGet(
            await tmsToken(),
            "Items/GetItems"
        );
    },
    getItemsByIds : async (queries:{ids:string|Array<string>}) : Promise <
        Payloader.Response.ListedItems
    > => {
        // const que
        return await runGet(
            await tmsToken(),
            "Items/GetItems",
            queries
        );
    },
    getSlottableItems : async(queries:{deviceModelId:string,slotId}) : Promise<
        Payloader.Response.ListedSlottableItems
    > => {
        return await runGet(
            await tmsToken(), 
            "DeviceModels/GetSlottableItems",
            queries
        );
    },
    createDeviceGroup : async (body : Payloader.Request.DeviceGroupCreate) : Promise<
        Payloader.Response.DeviceGroupDetails
    > => {
        return await runPut(
            await tmsToken(),
            "DeviceGroups/CreateDeviceGroup", 
            body
        );
    },
    deleteDeviceGroup : async (queries:Payloader.Request.ForcibleById) : Promise<
        any /*TODO: add blank response type*/
    > => {
        return await runDelete(
            await tmsToken(),
            "DeviceGroups/DeleteDeviceGroup", 
            queries
        );
    },
    addDeviceGroupClients : async (body:Payloader.Request.DeviceGroupBase): Promise<
        any /*TODO: add blank response type*/
    > => {
        return await runPatch(
            await tmsToken(),
            "DeviceGroups/AddDeviceGroupClients",
            body
        );
    },
    setDeviceGroupClients : async (body:Payloader.Request.DeviceGroupBase): Promise<
        Payloader.Base.DeviceGroupDetails
    > => {
        return await runPatch(
            await tmsToken(),
            "DeviceGroups/SetDeviceGroupClients",
            body
        );
    },
    removeDeviceGroupClients : async (body:Payloader.Request.DeviceGroupBase): Promise<
        any /*TODO: add blank response type*/
    > => {
        return await runPatch(
            await tmsToken(),
            "DeviceGroups/RemoveDeviceGroupClients",
            body
        );
    },
    getDeviceGroupsList : async () : Promise<Payloader.Response.ListedDeviceGroups> => {
        return await runGet(
            await tmsToken(),
            "DeviceGroups/GetDeviceGroups"
        );
    },
    getJobs : async () : Promise<Payloader.Response.Jobs> => {
        return await runGet(
            await tmsToken(),
            `Jobs/Get${import.meta.env.VITE_JOB_NAME??"JobInstance"}s`
        );
    },
    getJob : async (queries:{ids:string}) : Promise<Payloader.Response.Jobs> => {
        return await runGet(
            await tmsToken(),
            `Jobs/Get${import.meta.env.VITE_JOB_NAME??"JobInstance"}s`,
            queries
        );
    },
    cancelJob : async(queries:{id:string}) : Promise<any> => {
        return await runPatch(
            await tmsToken(),
            `Jobs/Cancel${import.meta.env.VITE_JOB_NAME??"JobInstance"}`,
            undefined,
            undefined,
            queries
        );
    },
    requestLegacyPayloaderUpgrades: async (clients: Payloader.Base.ClientGrouping): Promise<
        Payloader.Response.UpgradeScheduled
    > =>{
        return runPost(
            await tmsToken(),
            "DeviceModels/RequestLegacyPayloaderUpgrades",
            clients
        );
    },
    scheduleCodedJob : async (body:Payloader.Request.CodedJobCreate) : Promise<
        Payloader.Response.JobCreated
    > => {
        return await runPut(
            await tmsToken(),
            "Jobs/ScheduleCodedJob", 
            body
        );

    },
    scheduleApplyPayloadJob : async (body:Payloader.Request.PayloadJobCreate) : Promise<
        Payloader.Response.JobCreated
    > => {
        return await runPut(
            await tmsToken(),
            "Jobs/ScheduleApplyPayloadJob", 
            body
        );

    },
    createPayload : async (body:Payloader.Request.PayloadCreate) : Promise<
        Payloader.Response.PayloadCreated
    > => {
        return await runPut(
            await tmsToken(),
            "Payloads/CreatePayload", 
            body
        );
    },
    editPayload : async (body:Payloader.Request.PayloadEdit) : Promise<
        Payloader.Response.PayloadCreated
    > => {
        return await runPatch(
            await tmsToken(),
            "Payloads/EditPayload", 
            body
        );
    },
    setPayloadSlottedItems : async (body:Payloader.Request.PayloadAddSlotItems) : Promise<
        Payloader.Response.PayloadCreated
    > => {
        return await runPatch(
            await tmsToken(),
            "Payloads/SetPayloadSlots", 
            {   replace:true,
                ...body
            }
        );
    },
    addPayloadSlottedItems : async (body:Payloader.Request.PayloadAddSpecItems) : Promise<
        Payloader.Response.PayloadCreated
    > => {

        // delete body["deviceModelId"];
        return await runPatch(
            await tmsToken(),
            "Payloads/AddPayLoadItem",
            {
                ...body
            }
        );
    },
    removePayloadSlottedItems : async (body:Payloader.Request.PayloadRemoveItems) : Promise<
        Payloader.Response.PayloadCreated
    > => {
        return await runPatch(
            await tmsToken(),
            "Payloads/RemovePayloadItem", 
            {   
                ...body
            }
        );
    },
    deletePayload : async (queries : Payloader.Request.ForcibleById) : Promise <
        any /*TODO: add blank response type*/
    > => {
        return await runDelete(
            await tmsToken(),
            "Payloads/DeletePayload", 
            queries
        );
    },
    getPayloads : async () : Promise<
        Payloader.Response.ListedPayloads
    > => {
        return await runGet(
            await tmsToken(),
            "Payloads/GetPayloads"
        );
    },
    getPayload : async (id:string) : Promise<
        Payloader.Response.SinglePayload
    > => {
        return (await runGet(
            await tmsToken(),
            `Payloads/GetPayloads`,
            {ids:id}
        ))[0];
    },
    getModelsList : async () : Promise<
        Payloader.Response.ListedModelsOnly
    > => {
        const modelsResponse : Payloader.Response.ListedModelsOnly = (await runGet(
            await tmsToken(),
            "DeviceModels/GetDeviceModels?IncludeSlots=true"
        ));

        return modelsResponse;

    },
    getModelSlotsList : async (queries:{DeviceModel:string}) : Promise<
        Payloader.Response.ListedModelSlots
    > => {
        return await runGet(
            await tmsToken(),
            "DeviceModels/GetDeviceModelSlots",
            queries
        )
    },
    getSlotsList : async () : Promise<
        Payloader.Response.ListedModelSlots
    > => {
        return await runGet(
            await tmsToken(),
            "DeviceModels/GetDeviceModels"
        )
    },
    getPayLoaderCodes : async (queries?:{ids:string}) : Promise<
        Payloader.Response.GetPayLoaderCodes
    > => {
        return await runGet(
            await tmsToken(),
            "CodedSequences/GetCodedSequences",
            queries
        )
    },
    getAllPayLoaderCodes : async () : Promise<
        Payloader.Response.GetPayLoaderCodes
    > => {
        return await runGet(
            await tmsToken(),
            "CodedSequences/GetCodedSequences"
        )
    }
})

const tmsService = {
    // TMS CALLER
    tmsCaller
};



export default tmsService;
