import clsx from 'clsx'
import {A} from '@solidjs/router'
import {useLayout} from '../../../core'
import {usePageData, PageTitle as KTPageTitle} from '../../../core/PageData'
import {createEffect, createMemo} from "solid-js";

const PageTitle = () => {
    const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
    const {config, classes} = useLayout()
    const appPageTitleDirection = createMemo(()=>config().app?.pageTitle?.direction);

    return <>
        <KTPageTitle/>
        <div
            id='kt_page_title'
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class={clsx(
                ' page-title ',
                classes().pageTitle.join(' '),
                config().app?.pageTitle?.class,
                {
                    'flex-column justify-content-center': appPageTitleDirection() === 'column',
                    'align-items-center': appPageTitleDirection() !== 'column',
                }
            )}
        >
            {/* begin::Title */}
            {config().app?.pageTitle?.display && pageTitle && (
                <h1
                    class={clsx('page-heading d-flex text-gray-900 fw-bold fs-3 my-0', {
                        'flex-column justify-content-center': appPageTitleDirection(),
                        'align-items-center': !appPageTitleDirection(),
                    })}
                >
                    {pageTitle()}
                    {pageDescription() && config().app?.pageTitle && config().app?.pageTitle?.description && (
                        <span
                            class={clsx('page-desc text-muted fs-7 fw-semibold', {
                                'pt-2': appPageTitleDirection() === 'column',
                            })}
                        >
                            {config().app?.pageTitle?.direction === 'row' && (
                                <span class='h-20px border-1 border-gray-300 border-start ms-3 mx-2'></span>
                            )}
                                {pageDescription()}{' '}
                        </span>
                    )}
                </h1>
            )}
            {/* end::Title */}

            {pageBreadcrumbs &&
                pageBreadcrumbs.length > 0 &&
                config().app?.pageTitle &&
                config().app?.pageTitle?.breadCrumb && (
                    <>
                        {config().app?.pageTitle?.direction === 'row' && (
                            <span class='h-20px border-gray-300 border-start mx-4'></span>
                        )}
                        <ul class='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0'>
                            {Array.from(pageBreadcrumbs()).map((item, index) => (
                                <li
                                    class={clsx('breadcrumb-item', {
                                        'text-gray-900': !item.isSeparator && item.isActive,
                                        'text-muted': !item.isSeparator && !item.isActive,
                                    })}
                                    id={`${item.path}${index}`}
                                >
                                    {!item.isSeparator ? (
                                        <A class='text-muted text-hover-primary' href={item.path}>
                                            {item.title}
                                        </A>
                                    ) : (
                                        <span class='bullet bg-gray-500 w-5px h-2px'></span>
                                    )}
                                </li>
                            ))}
                            <li class='breadcrumb-item text-gray-900'>{pageTitle()}</li>
                        </ul>
                    </>
                )}
        </div>
    </>
}

export {PageTitle}
