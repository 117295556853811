import { Component } from "solid-js";
import { SwitchComponentProps } from "../types/props";

const CustomSwitch : Component<SwitchComponentProps> = (props)=>{

    props.caseComponents["default"] = props.default;

    return <>
        {props.caseComponents[props.switchValue()??"default"]}
    </>;
};

export default CustomSwitch;