import {Component, Setter, createEffect, createMemo, createSignal, JSX} from "solid-js";
import { Payloader } from "../../services/types";
import { useTMS } from "../../context/TMS/TMSProvider";

import Cropper from "cropperjs";
import { Alert } from "../../../../../../lib/context/alert/manager";
import Button from "../../../../../../lib/components/form/controls/Button";
import Form from "../../../../../../lib/components/form/Form";
import AlertCard from "../../../../../../lib/components/alert/AlertCard";
import InputText from "../../../../../../lib/components/form/inputs/InputText";
import IconButton from "../../../../../../lib/components/form/controls/IconButton";
import { mdiCheck, mdiCropFree, mdiPencil, mdiUndo, mdiUpload } from "@mdi/js";
import Icon from "../../../../../../lib/components/icons/Icon";
import ChangeEventHandler = JSX.ChangeEventHandler;
import ChangeEventHandlerUnion = JSX.ChangeEventHandlerUnion;
import InputEventHandlerUnion = JSX.InputEventHandlerUnion;
import InputFileLabeled from "../../../../../../lib/components/form/inputs/InputFileLabeled";
import ImageCropper from "../../../../../../lib/components/form/controls/ImageCropper";


const UploadItemForm :Component<{
    id:string,
    slot:Payloader.Base.ImageSlot,
    // refetchItems:()=>void,
    // setSelectedItem:Setter<Payloader.Base.Item>,
    onComplete:(item?: Payloader.Base.Item)=>void
}> = (props) => {

    const [file, setFile] = createSignal<File>();

    const [statusMessage, setStatusMessage] = createSignal<Alert>();
    const {calls:{sendUploadImageItem}} = useTMS();



    const [imageDimensions , setImageDimensions] = createSignal<{height?:number,width?:number, fit:boolean}>();

    createEffect(()=>{
        if(!file()) return {};

        const img = new Image();
        const tempImageUrl = URL.createObjectURL(file());

        img.onload = ()=>{
            setImageDimensions({
                height:img.height,width:img.width,
                fit:img.width/img.height===props.slot.maxWidth/props.slot.maxHeight
            });
            URL.revokeObjectURL(tempImageUrl);
        };

        img.src = tempImageUrl;

    });

    const uploadItem = async (event:SubmitEvent) => {
        event.preventDefault();

        if(!imageDimensions()?.fit){
            return;
        }

        try{
            const formData=new FormData(event.currentTarget as HTMLFormElement);
            const fileResp = await sendUploadImageItem(formData);
            setTimeout(()=>{
                props.onComplete(fileResp)
            },500);
        } catch (err) {
            console.log(err);
        }
    };
    

    return <>
        <Form
            id="upload-item-form"
            onSubmit={uploadItem}
        >
            {/* START ImageUpload component */}
            <>
                <div class="tw-text-cyan-800 w-100 tw-text-wrap">
                    <h3>{props.slot.description}</h3>
                    <div>
                        All images uploaded will be converted to <code>.gif</code> format 
                        on terminals. There may be a slight loss in quality if images are 
                        edited online or provided in a format other than <code>.gif</code>. 
                        We recommend editing and converting locally if at all possible.
                    </div>
                    <div><span class="fw-bold">Aspect Ratio:</span> {props.slot.aspectRatio}</div>
                    <div><span class="fw-bold">Minimum Dimensions:</span> {props.slot.minWidth}px (width) by {props.slot.minHeight}px (height)</div>
                    <div><span class="fw-bold">Maximum Dimensions:</span> {props.slot.maxWidth}px by {props.slot.maxHeight}px</div>
                    {file()
                        ? <>
                            <div><span class="fw-bold">Image Dimensions:</span>{imageDimensions()?.height}px by {imageDimensions()?.width}</div>
                        </>
                        : <></>
                    }
                </div>

                {file()
                    ? <AlertCard
                        alert={{
                            type:imageDimensions()?.fit ? "success" : "error",
                            code:"Image Notice",
                            message: `${imageDimensions()?.width}px by ${imageDimensions()?.height}px ${
                                imageDimensions()?.fit
                                    ? "fits the specified slot."
                                    : `does not fit the specified slot please crop this image or upload an image with an aspect ratio of ${props.slot?.aspectRatio}.`
                            }`,
                            show:true
                            
                        }}
                    />
                    // ? <>
                    //     <div><span class="fw-bold">Image Dimensions:</span>{imageDimensions()?.height}px by {imageDimensions()?.width}</div>
                    //     <div><span class="fw-bold">:</span>{imageDimensions()?.height}px by {imageDimensions()?.width}</div>
                    // </>
                    : <></>
                }

                {statusMessage()
                    ? <AlertCard 
                        id="alert-image-upload"
                        alert={statusMessage()}
                    />
                    :<></>
                }
                <InputFileLabeled 
                    classList={{"w-100":true}} 
                    label={"Upload File"}
                    name={"File"}
                    file={file}
                    setFile={setFile}
                    accept={"image/*"/* TODO: make this match requirements */}
                />
                <ImageCropper
                    file={file}
                    setFile={setFile}
                    aspectRatio={props.slot?.maxWidth/props.slot?.maxHeight}
                    exportOptions={{
                        minWidth:props.slot?.minWidth,
                        maxWidth:props.slot?.maxWidth,
                        maxHeight:props.slot?.maxHeight,
                        minHeight:props.slot?.minHeight
                    }}
                />
            </>
            {/* END ImageUpload component */}
        </Form>
    </>
};

export default UploadItemForm;