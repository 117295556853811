import {Setter, createEffect, createSignal, Component} from "solid-js"
import {Modal} from 'solid-bootstrap'
import {KTIcon} from '../../../helpers'

type Props = {
    data: { location: string; setLocation: Setter<string> }
    show: boolean
    handleClose: () => void
}

const SelectLocationModal: Component<Props> = ({show, handleClose, data}) => {
    createEffect(() => {
        initMap()
    }, [])

    const [location, setLocation] = createSignal(data.location)
    const dissmissLocation = () => {
        setLocation(data.location)
        handleClose()
    }
    const applyLocation = () => {
        data.setLocation(location)
        handleClose()
    }
    const initMap = () => {
    }


    return (
        <Modal
            class='modal fade'
            id='kt_modal_select_location'
            data-backdrop='static'
            tabIndex={-1}
            role='dialog'
            show={show}
            dialogclass='modal-xl'
            aria-hidden='true'
            onHide={dissmissLocation}
        >
            <div class='modal-content'>
                <div class='modal-header'>
                    <h5 class='modal-title'>Select Location</h5>

                    <div
                        class='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        onClick={dissmissLocation}
                    >
                        <KTIcon iconName='cross' class='fs-2x'/>
                    </div>
                </div>
                <div class='modal-body'>
                    <input type='text' value={data.location} onChange={(e) => setLocation(e.target.value)}/>
                    <div id='kt_modal_select_location_map' class='map h-450px'></div>
                </div>
                <div class='modal-footer'>
                    <button type='button' class='btn btn-light-primary' onClick={dissmissLocation}>
                        Cancel
                    </button>
                    <button id='submit' type='button' class='btn btn-primary' onClick={applyLocation}>
                        Apply
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export {SelectLocationModal}
