import {Component, JSX, ParentComponent, Setter, splitProps} from "solid-js";
import HTMLAttributes = JSX.HTMLAttributes;
import {string} from "yup";

export type InputSelectProps =
    { id: string, setSignal?: Setter<string>, name?: string }
    & HTMLAttributes<HTMLSelectElement>;

const InputSelect : ParentComponent<InputSelectProps> = (props) =>{
    const [local, rest] = splitProps(props, ["children", "setSignal", "onChange"]);

    return <select onChange={
        local.setSignal
            ? (event)=>{local.setSignal(event.target.options[event.target.selectedIndex].value);}
            : local.onChange
    } class={"form-select"} {...rest}>
        {props.children}
    </select>
};

export default InputSelect;