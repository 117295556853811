import { splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import Input, { InputClassList, InputProps } from "./Input";

/* TYPES */

export type TextInputProps<T={}> = InputProps<{
    placeholder?:string,
    classList?:Partial<InputClassList>
}>& T;

export type TextInputComponent = BaseComponent<TextInputProps>;


/* COMPONENT */

const InputText : TextInputComponent = (props) => {

    return <Input 
        type="text"
        {...props}
    />
}

export default InputText;