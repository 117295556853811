import { JSX, JSXElement, splitProps } from "solid-js";
import { HTMLProps } from "../types/props";
import { BaseComponent } from "../types/components";

/* TYPES */

type FormClassList = {
    "tw-flex":boolean,
    "flex-column":boolean,
    "tw-justify-center":boolean,
    "tw-items-center":boolean,
    "gap-2":boolean
};

export type FormProps = HTMLProps<
    JSX.FormHTMLAttributes<HTMLFormElement>,
    {
        children?:JSXElement,
        ref?:HTMLFormElement,
        classList?:Partial<FormClassList>
    }
>; 
export type FormComponent = BaseComponent<FormProps>;

/* COMPONENT */

const defaultClassList : FormClassList = {
    "tw-flex":true,
    "flex-column":true,
    "tw-justify-center":true,
    "tw-items-center":true,
    "gap-2":true
};

const Form : FormComponent = (props) => {

    const [local,rest]=splitProps(
        props,
        ["ref","id","classList","children"]
    );



    return <form
        ref={local.ref} 
        id={local.id}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
        {local.children}
    </form>;
};

export default Form;