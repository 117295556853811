import { createEffect, createMemo, createResource, createSignal } from "solid-js";
import authService from "../../services/auth";
import { JWTPayload, decodeJwt, importSPKI, jwtVerify } from "jose";
import { useAlerts } from "../../../../../../lib/context/alert/AlertProvider";

type IDToken = {location:string, company:string, country:string};


const [_, {addAlert}] = useAlerts();

let isRefreshing : Promise<boolean> | null;

// const [refreshToken, setRefreshToken] = createSignal(window.sessionStorage.getItem("refresh_token") ?? window.localStorage.getItem("refresh_token") ?? "");
// const payload = createMemo<Record<string,any>>(()=>{
//     try{
//         return JSON.parse(atob(refreshToken()?.split(".")[1]));
//     } catch {
//         return {};
//     }
// });

interface DCRefreshClaims extends JWTPayload {
    rem?:boolean,
    family?:string,
    env?:string
}

const [refreshInfo, setRefreshInfo] = createSignal<DCRefreshClaims>({});
const [pscsToken, setPscsToken] = createSignal("");
const [tmsToken, setTmsToken] = createSignal("");
const [idToken, setIdToken] = createSignal<IDToken>({
    location:"NA",
    company:"NA",
    country:"NA"
});
const [authApiToken, setAuthApiToken] = createSignal("");

/***
 * 
 * TODO
 * 
 * make auth tokens into some kind of object that better coordinates calls
 * ensure that if a token is blank it causes the request to wait
 * 
 * need to make `await authToken()` rely on isRefreshing, maybe using proxy or callback or something
 * 
 */


const removeRefreshTokens = async() => {
    if(window.sessionStorage.getItem("refresh_token")){
        window.sessionStorage.removeItem("refresh_token");
        return;
    }
    if(window.localStorage.getItem("refresh_token")){
        window.localStorage.removeItem("refresh_token");
        return;
    }
}

const runRefresh = async () => {
    
    const refresh_tok = window.sessionStorage.getItem("refresh_token")??window.localStorage.getItem("refresh_token");

    if(!refresh_tok){
        window.location.replace("/"); 
    }

    const {refresh_token, id_token, api_tokens} = await authService.refresh(
        refresh_tok
    );
    removeRefreshTokens();
    const pubKey = await importSPKI(
        `-----BEGIN PUBLIC KEY-----${import.meta.env.VITE_PUB_KEY}-----END PUBLIC KEY-----`,
        "ES256"
    )
    const token_rs = await jwtVerify(
        refresh_token,
        pubKey
    );

    setRefreshInfo(token_rs.payload);

    if((token_rs.payload??{})["rem"]){
        window.localStorage.setItem("refresh_token", refresh_token);
    } else {
        window.sessionStorage.setItem("refresh_token", refresh_token);
    }

    setPscsToken(api_tokens[import.meta.env.VITE_PSCS_API_NAME]); // should have test build with different api name
    setTmsToken(api_tokens[import.meta.env.VITE_PAYLOADER_API_NAME]);
    setAuthApiToken(api_tokens[import.meta.env.VITE_AUTH_API_NAME]);
    setIdToken(decodeJwt(id_token) as IDToken);

    return true;

}

const refresh = async () =>{
    if(!isRefreshing){
        isRefreshing = runRefresh();
    }
    try{
        const refreshed = await isRefreshing;
        isRefreshing = null;
        return refreshed;
    } catch (error){
        await removeRefreshTokens();
        isRefreshing = null;
        window.location.replace("/"); //TODO: looping error
        //window.location.reload();
    }
    // isRefreshing = false;
}

const revoke = async () =>{
    try{
        await authService.revoke(
            window.sessionStorage.getItem("refresh_token")??window.localStorage.getItem("refresh_token")
        );
        removeRefreshTokens();
        setPscsToken("");
        setTmsToken("");
        setAuthApiToken("");
        setIdToken({
            location:"NA",
            company:"NA",
            country:"NA"
        });
        window.location.replace("/");
    } catch (error){
        addAlert({...error, type:"error"});
    }
}

const usePscsToken = async () => {
    try{
        if(authApiToken() === "" || decodeJwt(pscsToken())?.exp - Date.now()/1000 <= 30){
            await refresh();
        }
        return pscsToken();

    } catch(e) {
        return pscsToken();
        //TODO: make tokens resources?
    }
}
const useTmsToken = async () => {
    try{
        if(authApiToken() === "" || decodeJwt(tmsToken())?.exp - Date.now()/1000  <= 30){
            await refresh();
        }
        return tmsToken();

    } catch(e) {
        return tmsToken();
        //TODO: make tokens resources?
    }
}
const useAuthApiToken = async () => {
    try{
        if(authApiToken() === "" || decodeJwt(authApiToken())?.exp - Date.now()/1000 <= 30){
            await refresh();
        }
        return authApiToken();
    } catch(e) {
        return authApiToken();
        //TODO: make tokens resources?
    }
}

const manager = [
    {
        pscsToken: usePscsToken,
        tmsToken: useTmsToken,
        idToken : idToken,
        payload : refreshInfo,
        authApiToken: useAuthApiToken
    },
    {
        refresh,
        revoke
    }
];

export default manager;
