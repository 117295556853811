

import {Params, useNavigate, useSearchParams, Navigator} from "@solidjs/router";
import {TokenProvider, useToken} from "./context/Token/TokenProvider";
import { onMount } from "solid-js";
import {AlertProvider, useAlerts} from "../../../../lib/context/alert/AlertProvider";
import {MasterInit} from "../../../../lib/_metronic/layout/MasterInit";
import {ThemeModeProvider} from "../../../../lib/_metronic/partials";
import {AccountProvider} from "./context/Account/AccountProvider";
import Layout from "./components/layout/Layout";
import {LayoutProvider} from "../../../../lib/_metronic/layout/core";

const ref = async ( nav : Navigator, searchParams : Partial<Params> ,refresh:()=>Promise<any>) => {
    // making this async is the same as
    // setting a lock on tokened calls when refreshing
    // setTimeout(()=>{
    //     addAlert({message:"test alert"});
    //     addAlert({message:"test error",type:"error"});
    //     addAlert({message:"test success",type:"success"});
    // }, 5000)
    try{
        await refresh();
        const location = searchParams["path"];
        if(location){
            //console.log("rerouting")
            // @ts-ignore not sure what is happening here
            nav(
                `/app${
                    window.atob(
                        location
                            .replace("-", "+")
                            .replace("_", "/")
                    )
                }`,
                {replace: true}
            );
            sessionStorage.removeItem("loc");
        }else{
            nav("/app/user", {replace:true});
        }
    }catch(err){
        window.localStorage.removeItem("id_token");
        window.localStorage.removeItem("refresh_token");
        nav("/", {replace:true});
    }

}

const App = (props) =>{
    const [_, {refresh}] = useToken();

    const [searchParams] = useSearchParams();

    const nav = useNavigate();
    

    //const isRouting = useIsRouting();

    onMount(()=>{ref(nav, searchParams, refresh)});

    return<LayoutProvider>
        <ThemeModeProvider>
            <AlertProvider>
                <TokenProvider>
                    <AccountProvider>
                        <Layout>
                            {props.children}
                        </Layout>
                    </AccountProvider>
                    <MasterInit/>
                </TokenProvider>
            </AlertProvider>
        </ThemeModeProvider>
    </LayoutProvider>

    };

    export default App;