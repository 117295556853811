import { Accessor, Component, For, Setter, createMemo, createSignal } from "solid-js";
import { Payloader } from "../../services/types";
import { SetStoreFunction, createStore, produce } from "solid-js/store";
import SearchArea from "../../../../../../lib/components/search/SearchArea";
import Button from "../../../../../../lib/components/form/controls/Button";
import DeviceCard from "./DeviceCard";
import InputText from "../../../../../../lib/components/form/inputs/InputText";
import Form from "../../../../../../lib/components/form/Form";
import { useTMS } from "../../context/TMS/TMSProvider";
import LoadingButton from "../../../../../../lib/components/form/controls/LoadingButton";

// TODO: add search by model
const DeviceGroupList : Component<{
    devices:Array<{
        deviceInfo:Element,
        deviceModel:string,
        deviceId:string,
        deploymentId:string,
        deviceGroups:Array<string>,
        isSelected:boolean  
    }>,
    setDevices:SetStoreFunction<Array<{
        deviceInfo:Element,
        deviceModel:string,
        deviceId:string,
        deploymentId:string,
        deviceGroups:Array<string>,
        isSelected:boolean  
    }>>
    type:string,
    deviceGroups:Accessor<Array<Payloader.Base.DeviceGroupDetails>>,
    deploymentIds:Array<string>,
    onComplete:()=>Promise<void>,
}> = (props) => {

    const {
        calls:{sendCreateDeviceGroup},
    } = useTMS();

    const selectDeviceByID = (deviceId:string) => {
        props.setDevices(
            device=>device.deviceId===deviceId,
            produce(dev=>dev.isSelected=true)
        )
    };
    const removeDeviceByID = (deviceId:string) => {
        props.setDevices(
            device=>device.deviceId===deviceId,
            produce(dev=>dev.isSelected=false)
        )
    };
    const toggleDeviceByID = (deviceId:string) => {
        props.setDevices(
            device=>device.deviceId===deviceId,
            produce(dev=>dev.isSelected=!dev.isSelected)
        )
    };
    const toggleDeviceByIndex = (deviceIdx:number) => {
        props.setDevices(
            deviceIdx,
            produce(dev=>dev.isSelected=!dev.isSelected)
        )
    };
    
    const addDevicesByDeploymentID = (deploymentID:string)=>{
        props.setDevices(
            produce(devs=>{
                for(const idx in devs){
                    if(devs[idx].deploymentId===deploymentID){
                        devs[idx].isSelected=true
                    }
                }
            }
        ))
    };
    const removeDevicesByDeploymentID = (deploymentID:string)=>{
        props.setDevices(
            produce(devs=>{
                for(const idx in devs){
                    if(devs[idx].deploymentId===deploymentID){
                        devs[idx].isSelected=false
                    }
                }
            }
        ))
    };
    const addDevicesByDeviceGroup = (deviceGroupName:string)=>{
        props.setDevices(
            produce(devs=>{
                for(const idx in devs){
                    if(devs[idx].deviceGroups.includes(deviceGroupName)){
                        devs[idx].isSelected=true
                    }
                }
            }
        ))
    };
    const removeDevicesByDeviceGroup = (deviceGroupName:string)=>{
        props.setDevices(
            produce(devs=>{
                for(const idx in devs){
                    if(devs[idx].deviceGroups.includes(deviceGroupName)){
                        devs[idx].isSelected=false
                    }
                }
            }
        ))
    };

    const [searchVal,setSearchVal] = createSignal<string>();
    const [searchType,setSearchType] = createSignal<string>("DeviceID");

    const deviceCandidates = createMemo(()=>{
        switch(searchType()){
            case "Model":
                return props.devices.filter(dev=>dev.deviceModel.includes(searchVal()?.replaceAll(" ","_")?.toUpperCase()??""));
            
            case "DeploymentID":
                return props.devices.filter(dev=>dev.deploymentId.includes(searchVal()?.toUpperCase()??""));
            case "GroupName":
                return props.devices.filter(dev=>dev.deviceGroups.some(dg=>dg.includes(searchVal()??"")));
            case "DeviceID":
            default:
                return props.devices.filter(dev=>dev.deviceId.includes(searchVal()??""));
        }
        //return devices.filter(dev=>dev.deviceId.includes(searchVal()??""));
    });
    const deviceGroupCandidates = createMemo(()=>{
        switch(searchType()){
            case "Model":
                const d = props.devices.filter(dev=>dev.deviceModel.includes(searchVal()?.replaceAll(" ","_")?.toUpperCase()??""));
                return props.deviceGroups()?.filter(dg=>dg.deviceIds.length===0||d.some(dev=>dev.deviceGroups.includes(dg.name)));

            case "DeploymentID":
                // return device groups containing devices in a given deployment
                const devs = props.devices.filter(dev=>dev.deploymentId.includes(searchVal()?.toUpperCase()??""));
                return props.deviceGroups()?.filter(dg=>dg.deviceIds.length===0||devs.some(dev=>dev.deviceGroups.includes(dg.name)));
            case "GroupName":

                const deviceGroupsArr = [];

                for(const dg of props.deviceGroups()??[]){
                    if(dg.name.includes(searchVal()??"")){
                        deviceGroupsArr.push(dg)
                    }
                }
                return deviceGroupsArr;
            case "DeviceID":
            default:
                return props.deviceGroups()?.filter(dg=>dg.deviceIds.length===0||dg.deviceIds.some(devID=>devID.includes(searchVal()??"")));
        }
    });
    const deploymentCandidates = createMemo(()=>{
        switch(searchType()){
            // case "MerchantName":
            //     return props
            case "Model":
                const d = props.devices.filter(dev=>dev.deviceModel.includes(searchVal()?.replaceAll(" ","_")?.toUpperCase()??""));
                return props.deploymentIds.filter(did=>d.some(dev=>dev.deviceGroups.includes(did)));
            case "DeploymentID":
                // return device groups containing devices in a given deployment
                return props.deploymentIds.filter(depls=>depls.includes(searchVal()?.toUpperCase()??""));
            case "GroupName":
                const devs = props.devices.filter(dev=>dev.deviceGroups.some(dg=>dg.includes(searchVal()??"")));
                return props.deploymentIds.filter(did=>devs.some(dev=>dev.deviceGroups.includes(did)));
            case "DeviceID":
            default:
                return props.deploymentIds.filter(did=>props.devices.filter(dev=>dev.deviceId.includes(searchVal()??"")).some(dev=>dev.deploymentId===did));
        }
        //return devices.filter(dev=>dev.deviceId.includes(searchVal()??""));
    });

    const [isLoading, setIsLoading] = createSignal<boolean>(false);


    // NOTE: need to have both a min-width, set-width, and grow  
    //       enabled for share-when-possible tw-flex item positoning

    return <div class="tw-flex flex-row tw-flex-wrap-reverse gap-4 w-100 justify-content-between">
        <div id="display-unselected-area" class="tw-flex flex-column gap-2 tw-max-h-[70vh] tw-min-w-96 tw-w-1/3 tw-grow">
            {/* <div class="tw-flex flex-row justify-content-between items-start">
                <div class="font-bold text-2xl">Select Devices</div>
            </div> */}
            <label for="search-bar-search-devices" class="font-semibold text-lg">Search Devices</label>
            <SearchArea 
                id="search-devices" 
                placeholder="Search..."
                setType={setSearchType} 
                setValue={setSearchVal}
                typeList={[
                    ["DeviceID","Device ID"],
                    ["GroupName","Group Name"],
                    ["DeploymentID","Deployment ID"],
                    ["Model","Model Name"]
                ]}
            />
            <details id="device-groups-select-details" class="w-100" >
                    <summary class="cursor-pointer">Select By Device Group</summary>
                    
                    <div class="tw-flex flex-row flex-wrap gap-2 max-h-32 overflow-y-auto">
                    {/* {creatingDeviceGroup()
                        ? <Form id="create-dev-group"
                            classList={{
                                "w-100":true,
                                "flex-col":false,
                                "flex-row":true
                            }}
                            onSubmit={(event)=>{
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);

                                createDeviceGroup(formData.get("GroupName")?.toString());
                            }}
                        >
                            <InputText 
                                id="name-dev-group"
                                name="GroupName"
                                placeholder="new group name"
                                classList={{"grow":true}}
                            />
                            <Button
                                id="submit-new-group-name"
                                type="submit"
                            >
                                Confirm new Group Name
                            </Button>
                        </Form>
                        :<div
                            class={`
                                transition-all p-1 cursor-pointer
                                 rounded-full
                                bg-green-500 hover:bg-green-800
                            `}
                            onClick={()=>{
                                setCreatingDeviceGroup(true);
                            }}
                        >
                            + Create Device Group
                        </div>
                        } */}
                        {deviceGroupCandidates()?.length
                            ?<For each={deviceGroupCandidates()}>
                                {(dg)=>{
                                    const [isSelected, setIsSelected] 
                                        = createSignal<boolean>(false);

                                    return <div
                                        class={`
                                            tw-transition-all tw-p-1 tw-cursor-pointer
                                            tw-rounded-full
                                            tw-text-white 
                                            tw-select-none
                                            ${isSelected()
                                                ?"tw-bg-darkred-700 hover:tw-bg-red-400"
                                                :"tw-bg-neutral-500 hover:tw-bg-neutral-700"
                                            }
                                        `}
                                        onClick={()=>{
                                            setIsSelected(s=>{
                                                if(s){
                                                    removeDevicesByDeviceGroup(dg.name);
                                                } else {
                                                    addDevicesByDeviceGroup(dg.name);
                                                }
                                                return !s
                                            });
                                        }}
                                    >
                                        {dg.name}
                                    </div>
                                }}
                            </For>
                            :"No device groups."
                        }
                    </div>
                </details>
                <details id="device-depl-ids-select-details" class="w-100">
                    <summary class="cursor-pointer">Select By Deployment ID</summary>
                    <div class="tw-flex flex-row flex-wrap gap-2 max-h-32 overflow-y-auto">
                        {deploymentCandidates()?.sort()?.length
                            ?<For each={deploymentCandidates()}>
                                {(depl,idx)=>{
                                    const [isSelected, setIsSelected] 
                                        = createSignal<boolean>(false);
                                    return <div
                                        class={`
                                            tw-transition-all tw-p-1 tw-cursor-pointer
                                            tw-rounded-full
                                            tw-text-white 
                                            tw-select-none
                                            ${isSelected()
                                                ?"tw-bg-darkred-700 hover:tw-bg-red-400"
                                                :"tw-bg-neutral-500 hover:tw-bg-neutral-700"
                                            }
                                        `}
                                        onClick={()=>{
                                            setIsSelected(s=>{
                                                if(s){
                                                    removeDevicesByDeploymentID(depl);
                                                } else {
                                                    addDevicesByDeploymentID(depl);
                                                }
                                                return !s
                                            });
                                        }}
                                    >
                                        {depl}
                                    </div>
                                }}
                            </For>
                            :"No deployments."
                        }
                    </div>
                </details>
            <div class="overflow-y-auto overscroll-none grow">
                <div id="selection-display-area" class="tw-flex flex-column gap-2" >
                    <For each={deviceCandidates()}>{(device,id)=>{
                        return<DeviceCard
                                id={`selected-device-${id()}`}
                                device={device.deviceInfo}
                                class={`${device.isSelected?"hide":""} tw-cursor-pointer tw-hover:bg-red-100`}
                                deviceGroups={device.deviceGroups}
                                title="Select Device"
                                onClick={()=>{selectDeviceByID(device.deviceId)}}
                            />
                    }}</For>
                </div>
            </div>
        </div>
        <div id="display-area" class="tw-flex flex-column gap-6 tw-max-h-[70vh] tw-min-w-96 tw-w-1/3 tw-bg-slate-200 tw-rounded-lg tw-p-2 tw-grow">
            <div class="tw-flex flex-row justify-content-between tw-items-start">
                <div class="tw-font-bold tw-text-2xl">Selected Devices for {props.type}</div>
                <LoadingButton isLoading={isLoading} id="continue-to-schedule" onClick={async()=>{
                    setIsLoading(true);

                    setTimeout(async ()=>{
                        await props.onComplete();
                        setIsLoading(false);

                    },500)
                }}>
                    Confirm Selections
                </LoadingButton>
            </div>
            <div>
                {props.devices.filter(dev=>dev.isSelected)?.length} Total
            </div>
            <div class="tw-overflow-y-auto tw-overscroll-none tw-grow">
                <div id="selection-display-area" class="tw-flex flex-column gap-2" >
                    <For each={props.devices}>{(device,id)=>{
                        return<DeviceCard
                                id={`selected-device-${id()}`}
                                device={device.deviceInfo}
                                class={`${device.isSelected?"":"hide"} tw-cursor-pointer tw-hover:bg-red-100`}
                                deviceGroups={device.deviceGroups}
                                title="Remove Device"
                                onClick={()=>{removeDeviceByID(device.deviceId)}}
                            />
                    }}</For>
                </div>
            </div>
        </div>
        
    </div>
};

export default DeviceGroupList;