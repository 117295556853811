import { JSXElement } from "solid-js";
import {BaseComponent} from "../types/components";

const SimpleRow : BaseComponent<{label:string|JSXElement,value:string|JSXElement, even:boolean}> = (props)=>{
    return <div id={props.id} class={`tw-transition-colors tw-flex tw-flex-row justify-content-between tw-items-center px-4 py-2 tw-w-full ${props.even?"tw-bg-neutral-100":""} hover:tw-bg-neutral-200`}>
        <div class="font-semibold">{props.label}</div>
        <div>{props.value}</div>
    </div>
}

export default SimpleRow;