import type * as AxiosWrapper from "./types/axiosWrapper";
import axios, { AxiosResponse } from "axios";

const constructQueryString = (queries?:any) : string => {
    if(queries){
        return Object.entries(queries).reduce(
            (prev:string, [key, val] : [string, string|Array<string>]
            ) : string => {
                if(val === undefined){
                    return prev;
                }

                if(Array.isArray(val)){
                    val.forEach((qv:string)=>{
                        prev = `${prev}${encodeURIComponent(key)}=${
                            encodeURIComponent(qv)}&`;
                    })
                } else {
                    prev = `${prev}${encodeURIComponent(key)}=${
                        encodeURIComponent(val)}&`;
                }
            return prev;
        }, "?");
    }
    return "";
};

const runGet : AxiosWrapper.QueryRequestFunction = (
    baseUrl
) => async(token, endpoint, queries) => {

    const response : AxiosResponse = await axios.get(
        `${baseUrl}/${endpoint??""}${constructQueryString(queries)}`, {
            headers: {"Authorization":`Bearer ${token}`}
        }
    );

    return response.data;
}

const runDelete : AxiosWrapper.QueryRequestFunction = (
    baseUrl
) => async(token, endpoint, queries) => {

    const response : AxiosResponse = await axios.delete(
        `${baseUrl}/${endpoint??""}${constructQueryString(queries)}`, {
            headers: {"Authorization":`Bearer ${token}`}
        }
    );

    return response.data;
}

const runPut : AxiosWrapper.BodyRequestFunction = (
    baseUrl
) => async (token, endpoint, body, headers) : Promise<any> => {
    const response = await axios.put(`${baseUrl}/${endpoint??""}`,
            body, 
            {headers :{
                    "Authorization": `Bearer ${token}`,
                    ...(headers)
                }
            }
        );
    return response.data;
};

const runPost : AxiosWrapper.BodyRequestFunction = (
    baseUrl
) => async (token, endpoint, body, headers) : Promise<any> => {
    const response = await axios.post(`${baseUrl}/${endpoint??""}`,
            body, 
            {headers :{
                    "Authorization": `Bearer ${token}`,
                    ...(headers)
                }
            }
        );
    return response.data;
};

const runPatch : AxiosWrapper.BodyRequestFunction = (
    baseUrl
) => async (token, endpoint, body, headers, queries) : Promise<any> => {
    const response = await axios.patch(`${baseUrl}/${endpoint??""}${constructQueryString(queries)}`,
            body, 
            {headers :{
                    "Authorization": `Bearer ${token}`,
                    ...(headers)
                }
            }
        );
    return response.data;
};

const useAxiosBearer = (baseUrl:string) => {
    return {
        runGet:runGet(baseUrl),
        runPut:runPut(baseUrl),
        runPost:runPost(baseUrl),
        runPatch:runPatch(baseUrl),
        runDelete:runDelete(baseUrl)
    }
};

const axiosWrapper = {
    useAxiosBearer
};

export default axiosWrapper;
