import {Component} from "solid-js";
import {HeaderWrapper} from "../../../../../../lib/_metronic/layout/components/header";
import {Header} from "../../../../../../lib/_metronic/layout/components/header/Header";
import PayloaderLogo, {
    payloaderDarkLogoUrl,
    payloaderLogoUrl,
    payloaderIconLogoUrl,
    payloaderTextLogoUrl
} from "../branding/PayloaderLogo";
import Pill from "../../page/pill/Pill";
import {Navbar} from "../../../../../../lib/_metronic/layout/components/header/Navbar";
import PayLoaderNavbar from "./PayLoaderNavbar";

const PayLoaderHeader : Component = (props) =>{
    return <HeaderWrapper
        homeUrl={"/app/Home"}
        logoDark={payloaderDarkLogoUrl}
        logoLight={payloaderLogoUrl}
        logoSmall={payloaderIconLogoUrl}
    >
        <div class='app-header-menu app-header-mobile-drawer align-items-stretch'
             data-kt-drawer='true'
             data-kt-drawer-name='app-header-menu'
             data-kt-drawer-activate='{default: true, lg: false}'
             data-kt-drawer-overlay='true'
             data-kt-drawer-width='225px'
             data-kt-drawer-direction='end'
             data-kt-drawer-toggle='#kt_app_header_menu_toggle'
             data-kt-swapper='true'
             data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
             data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
        >
            <Header>
                {/*<div class={"tw-flex tw-flex-row tw-items-center justify-content-between w-100 tw-height-full"}>*/}

                    <div class={"tw-h-12"}>
                        <PayloaderLogo logoUrl={payloaderTextLogoUrl}/>
                    </div>
                {/*</div>*/}
            </Header>
        </div>
        <PayLoaderNavbar/>
        {/*<Navbar user={props.user} logout={props.logout}/>*/}
    </HeaderWrapper>
};

export default PayLoaderHeader;