import { Component, ParentProps, createContext, useContext } from "solid-js";
import manager from "./manager";

const AccountContext = createContext(manager);

const AccountProvider : Component<ParentProps> = (props) => {
    return <AccountContext.Provider value={manager}>
        {props.children}
    </AccountContext.Provider>
};

const useAccount = () => {
    return useContext(AccountContext);
};

export {useAccount, AccountProvider};
