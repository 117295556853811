import { createEffect, createMemo, createSignal, JSXElement } from "solid-js";
import { createStore, produce, reconcile } from "solid-js/store";

let alertCount:number = 0;

export type Alert = {
    code:string,
    message:string | JSXElement,
    type:"alert"|"success"|"error"|"info",
    show:boolean,
    time?:number
};

export type PartAlert = Omit<Omit<Alert,"show">,"time">

const [alerts, setAlerts] = createStore<Array<Alert>>([]);
const [recentAlerts, setRecentAlerts] = createStore<Array<Alert>>([]);

const deleteRecentAlert = (id:number) => {
    setRecentAlerts(
        (alerts)=>alerts?.filter((_,i)=>i!==id)
    );
}

const addAlert = (alert:PartAlert) => {
    setRecentAlerts(
        alerts=>{

            setTimeout(()=>{
                deleteRecentAlert(0);
            },3000);

            return [
                { 
                    show:true,
                    time:Date.now(),
                    type:"alert",
                    ...alert
                }, 
                ...alerts
            ]
        }
    );

    setAlerts(
        alerts=>{
            return [
                { 
                    show:true,
                    time:Date.now(),
                    type:"alert",
                    ...alert
                }, 
                ...alerts
            ]
        }
    );
};

const pushAlert = (alert:PartAlert) => {
    setAlerts(
        alerts=>{
            alerts?.push({ 
                show:true,
                time:Date.now(),
                ...alert
            });
            return alerts;
        }
        
    );
};

const hideAlert = (id:number) => {
    setAlerts(
        id,
        {
            show: false
        }
    );
}

const deleteAlert = (id:number) => {
    setAlerts(
        (alerts)=>alerts?.filter((_,i)=>i!==id)
    );
}

const deleteFirstAlert = () => {
    setAlerts(
        ([first,...alerts])=>alerts
    );
}
const popAlert = () => {
    setAlerts(
        (alerts)=>{alerts.pop();return alerts}
    );
}

const clearAlerts = () => {
    setAlerts(reconcile([]));
}

const manager = [
    {
        alerts,
        recentAlerts
    },
    {
        addAlert,
        pushAlert,
        popAlert,
        hideAlert,
        deleteAlert,
        deleteFirstAlert,
        clearAlerts,
        deleteRecentAlert
    }
];

export default manager;