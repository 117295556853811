import { BaseComponent } from "../../../../../../lib/types";
import { useAlerts } from "../../../../../../lib/context/alert/AlertProvider";
import AlertCard from "../../../../../../lib/components/alert/AlertCard";
import {Button} from "solid-bootstrap";

const AlertsList : BaseComponent = (props) => {
    // const [{errors}, {clearErrors, deleteError}]
    //     = useErrors();

    const [{alerts}, {clearAlerts, deleteAlert}] = useAlerts();
    return <div
        id={`alerts-list`}
        class={
            `tw-h-96 `+
            `tw-fold-out-height-delay `+
            `tw-overflow-y-auto`
        }
    >
        <div class={`tw-flex flex-column tw-p-4 tw-gap-2`}>
        {alerts.length
            ? <>
                <Button id={"clear-alerts-btn"} onClick={()=>setTimeout(()=>clearAlerts(),100)}>
                    Clear Alerts
                </Button>
                {alerts?.reverse()?.map((alert, i)=>{
                    return <AlertCard
                        alert={alert}
                        id={`alert-${i}`}
                        onExOut={()=>{setTimeout(()=>deleteAlert(i),100)}}
                    >
                        <div class={`w-100 break-words`}>{alert?.message ?? "Unknown error."}</div>
                        {/*<div class={`text-xs`}>{(new Date(alert?.time))?.toString()}</div>*/}
                    </AlertCard>
                })
                }
            </>
            :<>No Alerts</>
        }
        </div>
    </div>
};

export default AlertsList;

