import {Component, createMemo, JSXElement} from "solid-js"
import clsx from 'clsx'
import {A} from '@solidjs/router'
import {useLocation} from '@solidjs/router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import Icon from "../../../../../components/icons/Icon";
import {mdiStorefront} from "@mdi/js";

type Props = {
    href: string
    title: string
    icon?: string
    fontIcon?: string
    mdiIcon?:string
    hasBullet?: boolean,
    target?:string
}

const SidebarMenuItem: Component<Props & WithChildren> = ({
    children,
    href,
    title,
    icon,
    fontIcon,
    mdiIcon,
    hasBullet = false,
    target
}) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, href)
    const {config} = useLayout()
    const app = createMemo(() => config()?.app);

    return (
        <div class='menu-item'>
            <A class={clsx('menu-link without-sub', {active: isActive})} target={target} href={href}>
                {hasBullet && (
                    <span class='menu-bullet'>
                        <span class='bullet bullet-dot'></span>
                    </span>
                )}
                {icon && app()?.sidebar?.default?.menu?.iconType === 'svg' && (
                    <span class='menu-icon'>
                        {' '} <KTIcon iconName={icon} class='fs-2'/>
                    </span>
                )}
                {fontIcon && app()?.sidebar?.default?.menu?.iconType === 'font' && (
                    <i class={clsx('bi fs-3', fontIcon)}></i>
                )}
                {mdiIcon && !icon && !fontIcon && (
                    <span class='menu-icon'>
                        <Icon mdiPath={mdiIcon}
                              fill={"currentColor"}
                              classList={{
                                  "tw-w-5":false,
                                  "tw-h-5":false,
                                  "tw-w-6":true,
                                  "tw-h-6":true
                              }}
                              pathClassList={{"tw-fill-black":false}}
                        />
                    </span>
                )}
                <span class='menu-title'>{title}</span>
            </A>
            {children}
        </div>
    )
}

export {SidebarMenuItem}
