import { JSX, splitProps } from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps } from "../types/props";

/* TYPES */

export type IconClassList = {
    "tw-w-5":boolean,
    "tw-h-5":boolean
} & Record<string,boolean>;
export type PathClassList = Record<string,boolean>;

export type IconProps<P={}> = HTMLProps<
    JSX.SvgSVGAttributes<SVGSVGElement>,
    {
        title?:string,
        mdiPath:string,
        classList?: Partial<IconClassList> & {[key:string]:boolean},
        pathClassList?: Partial<PathClassList> & {[key:string]:boolean},

    }
> & P;

export type IconComponent = BaseComponent<IconProps>;


/* COMPONENT */

const defaultClassList : IconClassList = {
    "tw-w-5":true,
    "tw-h-5":true
};


const Icon : IconComponent = (props) => {

    const [local, rest] = splitProps(props, ["id","title","mdiPath","classList","pathClassList"]);

    return <svg 
        viewBox="0 0 24 24"
        id={local.id}
        class={"svg-icon"}
        fill={"currentColor"}
        classList={{
            ...defaultClassList,
            ...local.classList
        }}
        {...rest}
    >
        <g>
            <title>{local.title}</title>
            <path
                classList={local.pathClassList}
                d={local.mdiPath}
            />
        </g>
    </svg>
};

export default Icon;