import {ParentComponent, splitProps} from "solid-js";
import InputSelect, {InputSelectProps} from "./InputSelect";
import {InputLabeledComponent, InputLabeledProps} from "./InputLabeled";

const defaultClassList = {
    "tw-flex":true,
    "flex-column":true,
    "tw-gap-1":true,
    "tw-w-fit":true
}

const InputSelectLabeled : ParentComponent<InputSelectProps & InputLabeledProps & {selectClassList?:{[keyof: string]:boolean}}> = (props) => {
    const [local, rest] = splitProps(props, [
        "children", "id", "hint", "hintClassList", "label", "required", "classList", "selectClassList"
    ]);

    return <div id={`labeled-${local.id}`}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
    >
        <label for={local.id} class={`form-label ${local.required ? "required" : ""}`}>
            {local.label}
        </label>
        <InputSelect id={local.id} classList={local.selectClassList} {...rest}>
            {props.children}
        </InputSelect>
        {local.hint
            ?<div classList={{
                ...{"text-xs": true, "text-neutral-600": true},
                ...(local.hintClassList)
            }}>{local.hint}</div>
            : <></>
        }
    </div>
}

export default InputSelectLabeled;