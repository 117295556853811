import { JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import {BaseComponentProps, LabeledComponentProps} from "../../types/props";
import Input, { InputProps } from "./Input";
import Label from "./Label";

/* TYPES */



export type InputLabeledProps<T={}> = InputProps<{
    label?:JSXElement,
    hint?:JSXElement,
    hintClassList?:Record<string,boolean>,
    inputClassList?:Record<string,boolean>,
    labelClassList?:Record<string,boolean>
}>&T;

export type InputLabeledComponent<T={}> = BaseComponent<InputLabeledProps<T>>;

/* COMPONENT */


const InputLabeled : InputLabeledComponent = (props) => {

    const [local,rest] = splitProps(
        props,
        ["id","classList","label", "required","hintClassList","hint", "inputClassList", "labelClassList"]
    );

    return <div 
        id={`labeled-${local.id}`}
        classList={local.classList}
    >
        <Label for={local.id} required={local.required} classList={local.labelClassList}>{local.label}</Label>
        <Input id={local.id} required={local.required} classList={local.inputClassList} {...(rest)}/>
        {local.hint
            ?<div classList={{
                ...{"tw-text-xs": true, "text-muted": true, "tw-mt-2":true},
                ...(local.hintClassList)
            }}>{local.hint}</div>
            : <></>
        }
    </div>
};

export default InputLabeled;