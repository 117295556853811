import { Accessor, ResourceReturn, createResource, createSignal } from "solid-js";
import pscsService from "../../services/pscs";

import type {PSCS} from "../../services/types";

import { useToken } from "../Token/TokenProvider";
import { RouteLoadFunc, RouteLoadFuncArgs, useSearchParams } from "@solidjs/router";
import { Fetcher, GatedFetcher, ResourceReturningFunction, ResourceSearchParamsReturn, ResourceSignalReturn } from "../types/context";
import tmsService from "../../services/tms";
import { useAlerts } from "../../../../../../lib/context/alert/AlertProvider";
import {Request} from "../../services/types/pscs";
import DeploymentLinkPinPad = Request.DeploymentLinkPinPad;


const [_, {addAlert}] = useAlerts();
const [{pscsToken, tmsToken}] = useToken();

const checkResponse = async (response:Document) => {
    if(response?.querySelector("Response>Status")?.textContent === "Error"){
        throw {message:response?.querySelector("ErrorDescription")?.textContent,code:response?.querySelector("ErrorType")?.textContent};
    }
}

// fetchers/calls
const fetchDeployments = async ({
    page_size,
    page_number,
    sort_by,
    search_by,
    search_val,
    direction
}:PSCS.Request.DeploymentList) : Promise<Array<Element>> => {
    try{
        const response = (await pscsService.getDeploymentList(
            await pscsToken(), {
                page_size, 
                page_number, 
                sort_by, 
                search_by, 
                search_val, 
                direction
            }));
        await checkResponse(response);
        return Array.from(response?.querySelectorAll("Deployment"));
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};

const fetchDeployment = async (id:string) => {
    try{
        const response = (
            await pscsService.getDeployment(await pscsToken(), {id})
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};

const fetchApplicationInfo : Fetcher<
    PSCS.Request.ApplicationInfo,
    Document
> = async (args) => {
    try{
        const response = await pscsService.getApplicationInfo(
            await pscsToken(), 
            args 
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};

const fetchApplicationList = async () => {
    try{
        const response = (
            await pscsService.getApplicationList(await pscsToken())
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};

const handleAppSelection = (dependencies:{isEmbedded:boolean}) : Fetcher<
    Element,
    Document | Element
> => async (args) => {
    const partNo = args?.querySelector("PartNo")?.textContent;
    const version = args?.querySelector("Version")?.textContent;
    if(partNo&&args?.tagName==="Application"){
        return await fetchApplicationInfo({
            partNo, version, isEmbedded : dependencies.isEmbedded
        });
    }
    if(dependencies.isEmbedded){
        return args;
    }
    return await fetchApplicationList();
}

const fetchPackageList = async () => {
    try{
        const response = await pscsService.getPackageList(await pscsToken());
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error);
        addAlert({...error,type:"error"});
        throw error;
    }

};
const fetchDealerList = async () => {
    try{
        const response = await pscsService.getDealerList(await pscsToken());
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error);
        addAlert({...error,type:"error"});
        throw error;
    }

};

const fetchEmptyDeployment = async () => {
    try{
        const response = await pscsService.getEmptyDeployment(await pscsToken());
        //await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error);
        addAlert({...error,type:"error"});
        throw error;
    }

};


const addLinkedPinPads = async (pinpads:Array<DeploymentLinkPinPad>) => {
    const res: {failures:Array<DeploymentLinkPinPad & {error?:string}>,successes:Array<DeploymentLinkPinPad & {error?:string}>} = {
        failures : [],
        successes : []
    }

    for(const pinpad of pinpads??[]){
        try{
            const response = await pscsService.addLinkedPinPad(await pscsToken(), pinpad);
            await checkResponse(response);
            res.successes.push(pinpad);
            //return response;
        }catch (error){
            addAlert({...error,type:"error"});
            res.failures.push({...pinpad, error:error.message});
        }
    }

    if(res.successes?.length){
        addAlert({code:"Success",message:`Successfully added ${res.successes.length} device${res.successes.length>1?"s":""}.`,type:"success"});
    }

    return res;
};

const deactivateStageFile : Fetcher<
    {deploymentID:string}, Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="DeactivateFile"></Request>`,"text/xml"
        );
        const root = request.querySelector("Request");
        const deplElem = request.createElement("DeploymentID");
        deplElem.textContent = args.deploymentID;
        root.appendChild(deplElem);
        // if(args.orderInfo){
        //     const orderElem = request.createElement("OrderInformation");
        //     for(const [key, value] of args.orderInfo.entries()){
        //         const newElem = request.createElement(key);
        //         const newElemValue = request.createTextNode(value?.toString());
        //         newElem.appendChild(newElemValue);
        //         orderElem.appendChild(newElem);
        //     }
        //     root.appendChild(orderElem);
        // }

        const response = await pscsService.deactivateStageFile(await pscsToken(), request);
        await checkResponse(response);
        return response     
    } catch (err) {
        //console.log(err);
        addAlert({...err,type:"error"});
        throw err;
    }
}
const activateStageFile : Fetcher<
    {deploymentID:string, orderInfo?:FormData}, Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="BoardMerchantFromStageFile"></Request>`,"text/xml"
        );
        const root = request.querySelector("Request");
        const deplElem = request.createElement("DeploymentID");
        deplElem.textContent = args.deploymentID;
        root.appendChild(deplElem);
        const deviceIDElems : Array<Element> = [];
        
        if(args.orderInfo){
            const orderElem = request.createElement("OrderInformation");
            for(const [key, value] of args.orderInfo.entries()??[]){
                if(key.startsWith("DeviceID")){
                    const deviceIDElem = request.createElement("DeviceID");
                    const deviceIDTextElem = request.createTextNode(value?.toString());
                    deviceIDElem.appendChild(deviceIDTextElem);
                    deviceIDElems.push(deviceIDElem);
                    continue;
                }
                const newElem = request.createElement(key);
                const newElemValue = request.createTextNode(value?.toString());
                newElem.appendChild(newElemValue);
                orderElem.appendChild(newElem);
            }
            root.appendChild(orderElem);
        }

        if(deviceIDElems.length){
            const activationElem = request.createElement("Activation");
            activationElem.replaceChildren(...deviceIDElems);
            root.appendChild(activationElem);
        }

        const response = await pscsService.activateStageFile(await pscsToken(), request);
        await checkResponse(response);
        return response     
    } catch (err) {
        //console.log(err);
        addAlert({...err,type:"error"});
        throw err;
    }
}

const sendAddDCDDevice : Fetcher <{
    deploymentId:string,
    deviceId:string,
    lane:string,
    isNewLane?:boolean,
}, Document> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"></Request>`,"text/xml"
        );
        const deploymentIdElem = request.createElement("DeploymentID");
        deploymentIdElem.appendChild(
            request.createTextNode(args.deploymentId)
        );
        request.querySelector("Request").appendChild(
            deploymentIdElem
        );

        const activationElem = request.createElement("Activation");
        activationElem.appendChild(
            request.createElement("DeviceID").appendChild(
                request.createTextNode(args.deviceId)
            )
        );
        request.querySelector("Request").appendChild(
            activationElem
        );
        //console.log(request);

        const orderElem = request.createElement("OrderInformation");

        //TODO: finish this function with Order info
        

        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;

    } catch(error) {
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;

    }
}

const sendChangeMerchantInfo : Fetcher<
    {deploymentId:string, 
        changes:FormData
    },
    Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"><DeploymentID></DeploymentID><MerchantInformation></MerchantInformation></Request>`,"text/xml"
        );

        
        request.querySelector("DeploymentID").appendChild(
            request.createTextNode(args.deploymentId)
        );

        const merchInfo = request.querySelector("MerchantInformation");
        for(const [key, value] of args.changes.entries()??[]){

            if(value){
                const newElem = request.createElement(key);
                newElem.appendChild(request.createTextNode(value?.toString()));
                merchInfo.appendChild(newElem);
            }
        }

        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};
const sendSetReportingUsers : Fetcher<
    {deploymentId:string, 
        reportingUsers:Array<{FirstName:string,LastName:string,Email:string}>
    },
    Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"><DeploymentID></DeploymentID><MerchantInformation></MerchantInformation></Request>`,"text/xml"
        );

        
        request.querySelector("DeploymentID").appendChild(
            request.createTextNode(args.deploymentId)
        );

        const merchInfo = request.querySelector("MerchantInformation");
        for(const reportingUser of args.reportingUsers??[]){
            const newElem = request.createElement("ReportingUser");
            const firstNameElem = request.createElement("FirstName");
            firstNameElem.appendChild(request.createTextNode(reportingUser.FirstName));
            const lastNameElem = request.createElement("LastName");
            lastNameElem.appendChild(request.createTextNode(reportingUser.LastName));
            const emailElem = request.createElement("Email");
            emailElem.appendChild(request.createTextNode(reportingUser.Email));
            merchInfo.appendChild(newElem);
        }

        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};
const sendAddLane : Fetcher<
    {   deploymentId:string, 
        newLaneCount:number,
        installType:string,
        currentLaneIds:NodeListOf<Element>,
        baseInstallElem?:Element
    },
    Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"><DeploymentID></DeploymentID><AccountInformation><LaneCount></LaneCount></AccountInformation></Request>`,"text/xml"
        );

        const acctInfo = request.querySelector("AccountInformation");
        switch(args.installType){
            case "SINGLE":
                request.querySelector("LaneCount")?.appendChild(
                    request.createTextNode(args.newLaneCount?.toString())
                );
                for(const lid of args.currentLaneIds??[]){
                    acctInfo?.appendChild(
                        request.importNode(lid)
                    );
                }
                const newLaneID = request.createElement("LaneID");
                newLaneID.appendChild(request.createTextNode(`L${args.newLaneCount?.toString()}`))
                acctInfo?.appendChild(
                    newLaneID
                );
                break;
            case "SEPARATE":
                const newInstallElem = request.importNode(args.baseInstallElem,true);
                newInstallElem?.setAttribute("alt",(args.newLaneCount-1)?.toString());
                acctInfo.appendChild(newInstallElem);
                break;
            default:
                throw {code:"Incorrect Request", message:"Cannot add a lane to a non-multilane deployment."}
        }

        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};
const sendChangeAppParams : Fetcher<
    {   deploymentId:string, 
        newLaneCount:number,
        installType:string,
        currentLaneIds:NodeListOf<Element>,
        baseInstallElem?:Element
    },
    Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"><DeploymentID></DeploymentID><AccountInformation></AccountInformation></Request>`,"text/xml"
        );

        const acctInfo = request.querySelector("AccountInformation");
        switch(args.installType){
            case "SINGLE":
                request.querySelector("LaneCount")?.appendChild(
                    request.createTextNode(args.newLaneCount?.toString())
                );
                for(const lid of args.currentLaneIds??[]){
                    acctInfo?.appendChild(
                        request.importNode(lid)
                    );
                }
                const newLaneID = request.createElement("LaneID");
                newLaneID.appendChild(request.createTextNode(`L${args.newLaneCount?.toString()}`))
                acctInfo?.appendChild(
                    newLaneID
                );
                break;
            case "SEPARATE":
                const newInstallElem = request.importNode(args.baseInstallElem,true);
                newInstallElem?.setAttribute("alt",(args.newLaneCount-1)?.toString());
                acctInfo.appendChild(newInstallElem);
                break;
            default:
                throw {code:"Incorrect Request", message:"Cannot add a lane to a non-multilane deployment."}
        }

        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};
const sendChangeFile : Fetcher<
    {deployment:Document, section:string},
    Document
> = async (args) => {
    try{
        const parser = new DOMParser();
        const request = parser.parseFromString(
            `<Request type="ChangeFile"></Request>`,"text/xml"
        );
        request.querySelector("Request").appendChild(
            request.importNode(
                args.deployment.querySelector("DeploymentID"), true
            )
        );
        request.querySelector("Request").appendChild(
            request.importNode(
                args.deployment.querySelector(args.section), true
            )
        );
        const response = await pscsService.changeFile(
            await pscsToken(), request
        );
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};

// need to clone document only because of inconsistent API surface for params vs fields
const sendCreateStageFile = async (origDoc:Document) => {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString("<Request type='CreateStageFile'></Request>", "text/xml");
    // const docRoot = doc.querySelector("Request");

    // Array.from(origDoc.querySelector("Response").children).forEach(child=>{
    //     const node = doc.importNode(child, true);
    //     docRoot.appendChild(node);
    // });

    // // turn these into a function like "cleanInputElemForRequest"
    // doc?.querySelectorAll("MerchantInformation>*").forEach(merchItem=>{
    //     merchItem.replaceChildren(merchItem.querySelector("Value").textContent)
    // })
    // doc?.querySelectorAll("LaneCount").forEach(lc=>{
    //     lc.replaceChildren(lc.querySelector("Value").textContent)
    // })
    // doc?.querySelectorAll("LaneID").forEach(lid=>{
    //     lid.replaceChildren(lid.querySelector("Value").textContent)
    // })

    const laneCountElem = origDoc.createElement("LaneCount");
    laneCountElem.innerText ="1";
    origDoc.querySelector("AccountInformation").appendChild(laneCountElem);
    try{
        const response = await pscsService.createStageFile(await pscsToken(), origDoc);
        await checkResponse(response);
        return response;
    }catch(error){
        //console.log(error);
        addAlert({...error,type:"error"});
        throw error;
    }
};

const addLinkedPinPad = async ({pinpad, deployment}:{
    pinpad:string,
    deployment:string
}) => {
    try{
        const response = await pscsService.addLinkedPinPad(await pscsToken(), {pinpad, deployment});
        await checkResponse(response);
        return response;
    }catch (error){
        addAlert({...error,type:"error"});
        throw error;
    }
};
const removeLinkedPinPad = async ({pinpad}:{pinpad:string}) => {
    try{
        const response = await pscsService.removeLinkedPinPad(await pscsToken(), {pinpad});
        await checkResponse(response);
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }
};


const fetchLinkedPinPads = async () : Promise<Document> => {
    let resp : Document;
    try{
        resp = await pscsService.getLinkedPinPadList(await pscsToken());
        const deviceGroupsResp = await tmsService?.tmsCaller(tmsToken)?.getDeviceGroupsList();

        // should just return after this
        const pads = resp?.querySelectorAll("LinkedPinPad");
        for(const pad of pads??[]){
            const deviceGroupsElem = resp.createElement("DeviceGroups");
            pad.appendChild(deviceGroupsElem);
            for(const group of deviceGroupsResp??[]){
                if(group?.deviceIds?.includes(pad?.querySelector("PinPadSerialNum")?.textContent)){
                    const newDeviceGroupElem = resp.createElement("DeviceGroup");
                    newDeviceGroupElem.textContent = group?.name;
                    deviceGroupsElem.appendChild(newDeviceGroupElem);
                }
                if(group?.deploymentIds?.includes(pad?.querySelector("DeploymentID")?.textContent)){
                    const newDeviceGroupElem = resp.createElement("DeviceGroup");
                    newDeviceGroupElem?.setAttribute("deployment", "true");
                    newDeviceGroupElem.textContent = group?.name;
                    deviceGroupsElem.appendChild(newDeviceGroupElem);
                }
            }
        }
    } catch (error){
        addAlert({...error,type:"error"});
        //throw error;
    }
    return resp;
};
const pinpadsResource = createResource(()=>true, fetchLinkedPinPads);

const fetchMerchantRequirements = async () : Promise<Document>=>{
    try{
        const response = await pscsService.getMerchantRequirements(await pscsToken());
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }

};
const fetchOrderInfoRequirements = async ()=>{
    try{
        const response = await pscsService.getOrderInfoRequirements(await pscsToken());
        return response;
    }catch (error){
        //console.log(error)
        addAlert({...error,type:"error"});
        throw error;
    }

};

const fetchSecuredParameter : GatedFetcher<
    string, string
> = (basis, defaultValue) => async (args) => {
    
    if(args){
        try{
            const response = await pscsService.getParamValue(
                await pscsToken(),{lookup_id:basis}
            );
            return response.querySelector("ClearValue")?.textContent;
        } catch (error) {
            addAlert({...error,type:"error"});
            throw error;
        }
    }
    return defaultValue;
}

// datafunctions

/** this arrow function may cause nullptr error. need to fix */
const loadDeployments = ():ResourceSearchParamsReturn<
    PSCS.Request.DeploymentList,
    Array<Element>
> => { // RETURN TYPE
    const [searchTerms, setSearchTerms] = useSearchParams<PSCS.Request.DeploymentList>();
    return  [
        [searchTerms, setSearchTerms],
        createResource(()=>({
            page_size:searchTerms.page_size ?? "10", 
            page_number: searchTerms.page_number?? "1", 
            sort_by: searchTerms.sort_by ?? "DateLastModified", 
            search_by: searchTerms.search_by ?? "DeploymentIDContains", 
            search_val: searchTerms.search_val ?? "",
            direction: searchTerms.direction ?? "Desc"
        }),fetchDeployments)
    ];
};

const loadDeploymentsList = ():ResourceSearchParamsReturn<
    PSCS.Request.DeploymentList,
    Array<Element>
> => { // RETURN TYPE
    const [searchTerms, setSearchTerms] = useSearchParams<PSCS.Request.DeploymentList>();
    return  [
        [searchTerms, setSearchTerms],
        createResource(()=>({
            page_size:searchTerms.page_size ?? "10", 
            page_number: searchTerms.page_number?? "1", 
            sort_by: searchTerms.sort_by ?? "DateLastModified", 
            search_by: searchTerms.search_by ?? "DeploymentIDContains", 
            search_val: searchTerms.search_val ?? "",
            direction: searchTerms.direction ?? "Desc"
        }),fetchDeployments)
    ];
};

const loadDeployment = (
    args: RouteLoadFuncArgs // FIXED: correct args for data func @MK
) : ResourceReturn<Document> => {
    //const [id] = createSignal(args.params.id);
    return createResource(()=>args.params.id, fetchDeployment);
}
const loadSingleDeployment = (
    id:string // FIXED: correct args for data func @MK
) : ResourceReturn<Document> => {
    //const [id] = createSignal(args.params.id);
    return createResource(()=>id, fetchDeployment);
}

const loadPackageList = () : ResourceReturn<Document> => {
    return createResource(()=>true, fetchPackageList);
}
const loadDealerList = () : ResourceReturn<Document> => {
    return createResource(()=>true, fetchDealerList);
}
/*
const loadApplicationInfo = (deployment:Accessor<string>) => {

    return createResource(
        deployment, 
        fetchApplicationInfo
    );
    //
}
*/

const loadApplicationInfo : ResourceReturningFunction<
    Accessor<PSCS.Request.ApplicationInfo>,
    Document
> = (args) => {
    return createResource<
        Document, 
        PSCS.Request.ApplicationInfo
    >(
        args,
        fetchApplicationInfo
    );
}

const loadApplicationList = () : ResourceReturn<Document> => {
    return createResource(()=>true, fetchApplicationList);
}

const loadEmptyDeployment = () : ResourceReturn<Document> => {
    return createResource(()=>true, fetchEmptyDeployment);
};

const loadMerchantRequirements = () : ResourceReturn<Document>=>{
    return createResource(()=>true, fetchMerchantRequirements);  
};
const loadOrderInfoRequirements = () : ResourceReturn<Document> =>{
    return createResource(()=>true, fetchOrderInfoRequirements);  
};

const loadLinkedPinPads = () : ResourceReturn<Document> => {
    pinpadsResource[1]?.refetch();
    return pinpadsResource;
}

const fetchDeviceDetailsList = async (deviceIds?:Array<string>) => {
    try{
        const deviceList = await pscsService.getLinkedPinPadList(await pscsToken());
        const devices : Array<Element> = [];
        for(const device of deviceList?.querySelectorAll("LinkedPinPad")??[]){
            if(deviceIds.includes(device?.querySelector("PinPadSerialNum")?.textContent)){
                devices.push(device);
            }
        }
        return devices;
        
    }catch(err){
        addAlert({...err,type:"error"})
    }
}
const loadDeviceDetailsList = (deviceIds?:Array<string>)=>{
    return createResource(()=>deviceIds, fetchDeviceDetailsList);
}
const loadLinkedPinPadsNew = ()=>{
    return createResource(()=>true, fetchLinkedPinPads);
}

const manager = {
    state:{

    },
    calls:{
        sendChangeFile,
        sendChangeMerchantInfo,
        sendSetReportingUsers,
        sendAddDCDDevice,
        sendCreateStageFile,
        fetchDeployments,
        addLinkedPinPad,
        removeLinkedPinPad,
        fetchApplicationInfo,
        fetchMerchantRequirements,
        fetchOrderInfoRequirements,
        handleAppSelection,
        activateStageFile,
        deactivateStageFile,
        fetchSecuredParameter,
        fetchLinkedPinPads,
        addLinkedPinPads
    },
    resources:{
        loadMerchantRequirements,
        loadOrderInfoRequirements,
        loadPackageList,
        loadDealerList,
        loadApplicationInfo,
        loadApplicationList,
        loadSingleDeployment,
        loadDeviceDetailsList,
        loadLinkedPinPadsNew,
        loadDeploymentsList
    },
    dataFunctions : {
        loadLinkedPinPads,
        loadDeployments,
        loadDeployment,
        loadEmptyDeployment,
    }
};

export default manager;
