import {Accessor, JSX, Setter, createSignal, splitProps, JSXElement} from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps, ValueLabel } from "../types/props";
import IconButton from "../form/controls/IconButton";
import { mdiMagnify, mdiSearchWeb } from "@mdi/js";
import { WideInput } from "../form/types";

/* TYPES */


export type SearchBarClassList = Record<string,boolean>
export type SearchSelectClassList = WideInput;

export type SearchBarProps<P={}> = HTMLProps<
    JSX.InputHTMLAttributes<HTMLInputElement>,
    {
        name:string,
        ref?:HTMLInputElement,
        type?:string,
        label?:JSXElement,
        classList?: Partial<SearchBarClassList>,
        dataList?:Accessor<{
            id:string,
            list:Array<ValueLabel>
        } | null>,
        typeList?:Array<ValueLabel>,
        setValue:Setter<string>,
        setType?:Setter<any>,
    }
> & P;

export type SearchBarComponent = BaseComponent<SearchBarProps>;


/* COMPONENT */

const defaultClassList : SearchBarClassList = {

};

const selectClassList : SearchSelectClassList = {

};



const SearchBar : SearchBarComponent = (props) => {

    const [local, rest] = splitProps(
        props,
        [
            "id","name","type","dataList","setValue","classList",
            "typeList","setType", "label"
        ]
    );

    let inputRef !: HTMLInputElement;

    const handleValue = (value:string) => {
        local.setValue(value);
    }

    return <div class="tw-flex flex-column tw-gap-1 tw-w-full">
        <label for={local.id} id={`${local.id}-id`} class={"form-label"}>{local.label??"Search"}</label>
        <div class={"input-group"}>
            <span class="input-group-text"><i class="ki-solid ki-magnifier"></i></span>
            <input
                autocomplete="off"
                id={local.id}
                ref={inputRef}
                name={local.name}
                class={"form-control"}
                type={local.type??"text"}
                list={local.dataList ? local.dataList()?.id :""}
                onInput={(event)=>{handleValue(event.target.value)}}
                onChange={(event)=>{handleValue(event.target.value)}}
                classList={{
                    ...defaultClassList,
                    ...(local.classList)
                }}
                {...rest}
            >
            </input>
            {local.typeList
                ?<select
                    id={`select-type-${local.id}`}
                    class="input-group-text"
                    // class={"text-muted bg-transparent tw-max-w-15 px-2"}
                    // classList={selectClassList}
                    onchange={(event)=>{
                        local.setType(event.target.selectedOptions[0].value)
                    }}
                >
                    {local.typeList.map(([value,label])=>{
                        return <option value={value}>{label}</option>
                    })}
                </select>
                :<></>
            }
        </div>
        {local.dataList && local.dataList()
            ?<datalist
                id={local.dataList().id}
                class="absolute max-h-64 overflow-x-hidden overflow-y-auto z-[1000]"
                
                tabIndex={-1}
            >
                {local.dataList().list.map(([value,label])=>{
                    return <option
                        value={value}
                        class={`
                            p-2 cursor-pointer bg-red-100 
                             hover:bg-darkred-700
                             focus:bg-darkred-700
                        `}
                    >
                        {label
                            ?`${label} (${value})`
                            :value
                        }
                    </option>
                })}
            </datalist>
            :<></>
        }
    </div>
};

export default SearchBar;