import { JSX, JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../types/components";
import { HTMLProps } from "../types/props";

/* TYPES */

export type TitleClassList = {
    "font-bold":boolean,
    "text-3xl":boolean
};

export type TitleProps<P={}> = HTMLProps<
    JSX.HTMLAttributes<HTMLDivElement>,
    {
        classList?: Partial<TitleClassList>,
    }
> & P;

export type TitleComponent = BaseComponent<TitleProps>;


/* COMPONENT */

const defaultClassList : TitleClassList = {
    "font-bold":true,
    "text-3xl":true
};

const Title : TitleComponent = (props) => {

    const [local, rest] = splitProps(props, ["classList", "children"])

    return <div
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
        {local.children}
    </div>
};

export default Title;