import { JSX, JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import { HTMLProps } from "../../types/props";
import {Button as BSButton, ButtonVariant} from "solid-bootstrap";

/* TYPES */

export type ButtonClassList = Record<string,boolean>;

export type ButtonProps<P={}> = HTMLProps<
    JSX.ButtonHTMLAttributes<HTMLButtonElement>,
    {
        name?:string,
        type?:"button"|"submit"|"reset",
        classList?: Partial<ButtonClassList>
        children?:JSXElement,
        variant?:ButtonVariant,
    }
> & P;

export type ButtonComponent<T={}> = BaseComponent<ButtonProps&T>;


/* COMPONENT */

const defaultClassList : ButtonClassList = {
    // "text-nowrap":true,
    "d-flex":true,
    "flex-row":true,
    "tw-gap-2":true,
    "align-items-center":true,
};

const Button : ButtonComponent = (props) => {

    const [local, rest] = splitProps(
        props,
        ["id","name","type","classList","children", "variant", "class"]
    );

    return <button
        id={local.id}
        name={local.name}
        type={local.type??"button"}
        class={`btn btn-${local.variant??"primary"} ${local.class??""}`}
        classList={{
            ...defaultClassList,
            ...(local.classList)
        }}
        {...rest}
    >
        {local.children}
    </button>
};

export default Button;