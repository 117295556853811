import { JSXElement } from "solid-js";
import {BaseComponent} from "../types/components";
import Badge from "./Badge";
import {Variant} from "solid-bootstrap";

const LabeledSpanPair : BaseComponent<{
    divClass?:string,
    labelBgColor?:string, //TODO: remove this
    title:string,
    labelText:string,
    valueText:string,
    type?: Variant
}> = (props)=>{
    return <div id={props.id} class={`tw-flex flex-row gap-2 flex-wrap align-items-start ${props.divClass??""}`}>
        <Badge type={props.type??"secondary"} title={props.title} id={`${props.id}-label`}>
            {props.labelText}
        </Badge>
        <span id={`${props.id}-value`} class={"tw-truncate"} title={`${props.valueText}`}>
            {props.valueText}
        </span>
    </div>
};

export default LabeledSpanPair;