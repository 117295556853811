import { JSXElement, splitProps } from "solid-js";
import { BaseComponent } from "../../types/components";
import InputText, { TextInputProps } from "./InputText";
import { LabeledComponentProps } from "../../types/props";
import { InputProps } from "./Input";
import InputLabeled, {InputLabeledComponent, InputLabeledProps} from "./InputLabeled";

/* TYPES */


type ClassList = {
    "flex":boolean,
    "flex-column":boolean,
    "gap-1":boolean,
    "w-fit":boolean
};

export type InputTextLabeledProps<T={}> = InputLabeledProps<TextInputProps<T>>;

export type InputTextLabeledComponent<T={}> = InputLabeledComponent<InputTextLabeledProps<T>>;

/* COMPONENT */

const defaultClassList : ClassList = {
    "flex":true,
    "flex-column":true,
    "gap-1":true,
    "w-fit":true
}

const InputTextLabeled : InputTextLabeledComponent = (props) => {

    return <InputLabeled type={"text"} {...props}/>
};

export default InputTextLabeled;