import {createEffect, createSignal} from "solid-js"
import {Tab} from 'bootstrap'
import {
    MenuComponent,
    DrawerComponent,
    ScrollComponent,
    ScrollTopComponent,
    StickyComponent,
    ToggleComponent,
    SwapperComponent,
} from '../assets/ts/components'
import {ThemeModeComponent} from '../assets/ts/layout'

import {useLayout} from './core'

export function MasterInit() {
    const {config} = useLayout()
    const [initialized, setInitialized] = createSignal(false)
    const pluginsInitialization = () => {
        ThemeModeComponent.init()
        setTimeout(() => {
            ToggleComponent.bootstrap()
            ScrollTopComponent.bootstrap()
            DrawerComponent.bootstrap()
            StickyComponent.bootstrap()
            MenuComponent.bootstrap()
            ScrollComponent.bootstrap()
            SwapperComponent.bootstrap()
            document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
                Tab.getOrCreateInstance(tab)
            })
        }, 500)
    }

    createEffect(() => {
        config()
        if (!initialized()) {
            setInitialized(true)
            pluginsInitialization()
        }
    })

    return <></>
}
