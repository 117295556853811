/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    Component,
    createContext,
    useContext,
    createEffect,
    createSignal,
    Accessor,
    JSXElement,
    onMount,
    ParentComponent, Setter, ResourceReturn
} from "solid-js"
import {WithChildren} from '../../helpers'
import {useLocation} from "@solidjs/router";
import {pageDetailMap} from "../../../../projects/client/src/app/util/routing";
import {Base} from "../../../../projects/client/src/app/services/types/tms";

export type ToolbarObj = {title:JSXElement,description:JSXElement,toolbar:JSXElement};
export type ToolbarFactory<T extends {}> = (props:T) => Accessor<ToolbarObj>;


export interface PageLink {
    title: string
    path: string
    isActive: boolean
    isSeparator?: boolean
}

export interface PageDataContextModel {
    pageTitle?: Accessor<string>
    setPageTitle: (_title: JSXElement) => void
    pageDescription?: Accessor<string>
    setPageDescription: (_description: JSXElement) => void
    pageBreadcrumbs?: Accessor<Array<PageLink>>
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void,
    pageToolbar?: Accessor<JSXElement>,
    setPageToolbar?: Setter<JSXElement>
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: string) => {
    },
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {
    },
    setPageDescription: (_description: string) => {
    },
})

const PageDataProvider: ParentComponent = (props) => {
    const [pageTitle, setPageTitle] = createSignal<string>('')
    const [pageDescription, setPageDescription] = createSignal<string>('')
    const [pageBreadcrumbs, setPageBreadcrumbs] = createSignal<Array<PageLink>>([])
    const [pageToolbar, setPageToolbar] = createSignal<JSXElement>([])
    const value: PageDataContextModel = {
        pageTitle,
        setPageTitle,
        pageDescription,
        setPageDescription,
        pageBreadcrumbs,
        setPageBreadcrumbs,
        pageToolbar,
        setPageToolbar
    }
    return <PageDataContext.Provider value={value}>{props.children}</PageDataContext.Provider>
}

function usePageData() {
    return useContext(PageDataContext)
}

const createPageToolbarEffect = (
    pageToolbar:Accessor<ToolbarObj>,
    excludeToolbar?:boolean
) => {

    const pageDataContext = usePageData();
    createEffect(()=>{
        if(excludeToolbar) return;

        pageDataContext.setPageToolbar(pageToolbar()?.toolbar)
        pageDataContext.setPageTitle(pageToolbar()?.title)
        pageDataContext.setPageDescription(pageToolbar()?.description)
    });
}

type Props = {
    description?: string
    breadcrumbs?: Array<PageLink>
}

const PageTitle: Component<Props & WithChildren> = (props) => {

    const location = useLocation();

    const {setPageTitle, setPageDescription, setPageBreadcrumbs, setPageToolbar} = usePageData()
    onMount(() => {
        if (props.children) {
            setPageTitle(props.children)
        }
        return () => {
            setPageTitle('')
        }
    })

    onMount(() => {
        if (props.description) {
            setPageDescription(props.description)
        }
        return () => {
            setPageDescription('')
        }
    })

    onMount(() => {
        if (props.breadcrumbs) {
            setPageBreadcrumbs(props.breadcrumbs)
        }
        return () => {
            setPageBreadcrumbs([])
        }
    })

    createEffect(()=>{
        if(location.pathname){
            setPageTitle(pageDetailMap[location.pathname]?.title??"");
            setPageDescription(pageDetailMap[location.pathname]?.description??"");
            setPageToolbar(<></>);
        }
    })

    return <></>
}

const PageDescription: Component<WithChildren> = (props) => {
    const {setPageDescription} = usePageData();
    createEffect(() => {
        if (props.children) {
            setPageDescription(props.children)
        }
        return () => {
            setPageDescription('')
        }
    })
    return <></>
}

export {
    PageDescription,
    PageTitle,
    PageDataProvider,
    createPageToolbarEffect,
    usePageData
}
