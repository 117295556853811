import axios from "axios";
import axiosWrapper from "../util/axiosWrapper";


const authUrl = import.meta.env.VITE_AUTH_URL;
const clientId = import.meta.env.VITE_CLIENT_ID;

const bearer = axiosWrapper.useAxiosBearer(authUrl);

const runPost = async (endpoint:string, body:{[key:string]:any}) => {
    return (await axios.post(`${authUrl}/${endpoint}`, body)).data;
};

const refresh = async (token:string) => {
    return await runPost("token/refresh", {
        refresh_token:token
    });
};

const revoke = async (token:string) => {
    return await runPost("token/revoke", {
        refresh_token:token
    });

};

const getCompanyInfo = async (token:string) =>{
    return await bearer.runGet<{[key:string]:any}>(token, "user/company");
};
const getUserInfo = async (token:string) =>{
    return await bearer.runGet<{[key:string]:any}>(token, "user");
};

const initiateAddMFAKey = async (token:string, method:string) => {
    return await bearer.runPost<{message:string}>(
        token, `mfa/init/add/gauth`, {method}
    );
}

const initiateSupportLogin = async (token:string, family:string) => {
    const raw_array = new Uint8Array(16);
    crypto.getRandomValues(raw_array);
    const state = btoa(raw_array.toString());
    const url = `${authUrl}/support/auth?state=${
        state
    }&client_id=${clientId}&auth=${
        encodeURIComponent(token)
    }&family=${encodeURIComponent(family)}`;
    window.location.href=url;
    // window.open(url);
    // console.log(url);
}

const acquireApiKey = async (token:string) =>{
    return await bearer.runGet<{api_key:string}>(token, "apikey/init");
};
const resetApiKey = async (token:string) =>{
    return await bearer.runGet<{api_key:string}>(token, "apikey/reset");
};

const authService = {
    refresh,
    revoke,
    getUserInfo,
    getCompanyInfo,
    initiateAddMFAKey,
    initiateSupportLogin,
    acquireApiKey,
    resetApiKey
};

export default authService;
