export const bytesToSize = (bytes: number) => {


    if(bytes > 1000000000){
        return `${(bytes/1000000000)?.toFixed(2)} gb`
    }

    if(bytes > 1000000){
        return `${(bytes/1000000)?.toFixed(2)} mb`
    }

    return `${(bytes/1000)?.toFixed(2)} kb`
}