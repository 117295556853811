interface IIcons {
    [key: string]: number
}

const icons: IIcons = {
    'abstract-33': 2,
    'abstract-27': 2,
    'abstract-26': 2,
    'abstract-32': 2,
    'abstract-18': 2,
    'abstract-24': 2,
    'abstract-30': 2,
    'abstract-8': 2,
    'abstract-9': 2,
    'abstract-31': 2,
    'abstract-25': 2,
    'abstract-19': 2,
    'abstract-21': 2,
    'abstract-35': 2,
    'abstract-34': 2,
    'abstract-20': 2,
    'abstract-36': 2,
    'abstract-22': 2,
    'abstract-23': 2,
    'abstract-37': 2,
    'abstract-44': 2,
    abstract: 2,
    'abstract-45': 2,
    'abstract-47': 2,
    'abstract-46': 2,
    'abstract-42': 2,
    'abstract-43': 2,
    'abstract-41': 2,
    'abstract-40': 2,
    'abstract-48': 3,
    'abstract-49': 3,
    'abstract-12': 2,
    'abstract-2': 2,
    'abstract-3': 2,
    'abstract-13': 2,
    'abstract-39': 2,
    'abstract-11': 2,
    'abstract-1': 2,
    'abstract-10': 2,
    'abstract-38': 2,
    'abstract-14': 2,
    'abstract-28': 2,
    'abstract-4': 2,
    'abstract-5': 2,
    'abstract-29': 2,
    'abstract-15': 2,
    'abstract-17': 2,
    'abstract-7': 2,
    'abstract-6': 0,
    'abstract-16': 2,
    'toggle-on': 2,
    'toggle-on-circle': 2,
    'toggle-off': 2,
    category: 4,
    setting: 2,
    'toggle-off-circle': 2,
    'more-2': 4,
    'setting-4': 0,
    'setting-2': 2,
    'setting-3': 5,
    eraser: 3,
    paintbucket: 3,
    'add-item': 3,
    'design-2': 2,
    brush: 2,
    size: 2,
    design: 2,
    copy: 0,
    text: 0,
    'design-frame': 2,
    bucket: 4,
    glass: 3,
    feather: 2,
    pencil: 2,
    'colors-square': 4,
    'design-mask': 2,
    'bucket-square': 3,
    'copy-success': 2,
    'color-swatch': 21,
    instagram: 2,
    snapchat: 2,
    classmates: 2,
    facebook: 2,
    whatsapp: 2,
    'social-media': 2,
    youtube: 2,
    dribbble: 6,
    twitter: 2,
    tiktok: 2,
    behance: 2,
    underlining: 3,
    disconnect: 5,
    code: 4,
    gear: 2,
    loading: 2,
    scroll: 3,
    wrench: 2,
    'square-brackets': 4,
    frame: 4,
    'message-programming': 4,
    data: 5,
    fasten: 2,
    click: 5,
    'tech-wifi': 2,
    joystick: 4,
    faceid: 6,
    'technology-3': 4,
    'technology-2': 2,
    electricity: 10,
    'fingerprint-scanning': 5,
    'technology-4': 7,
    'artificial-intelligence': 8,
    technology: 9,
    'basket-ok': 4,
    cheque: 7,
    handcart: 0,
    shop: 5,
    tag: 3,
    purchase: 2,
    discount: 2,
    package: 3,
    percentage: 2,
    barcode: 8,
    'lots-shopping': 8,
    basket: 4,
    'book-square': 3,
    'receipt-square': 2,
    'save-2': 2,
    'archive-tick': 2,
    'shield-search': 3,
    'password-check': 5,
    'shield-tick': 2,
    lock: 3,
    key: 2,
    shield: 2,
    'shield-cross': 3,
    'key-square': 2,
    'eye-slash': 4,
    'security-check': 4,
    'lock-3': 3,
    'scan-barcode': 8,
    'lock-2': 5,
    eye: 3,
    'shield-slash': 3,
    'security-user': 2,
    triangle: 3,
    subtitle: 5,
    ghost: 3,
    information: 3,
    milk: 3,
    home: 0,
    'happy-emoji': 2,
    'mouse-square': 2,
    'filter-tick': 2,
    'filter-search': 3,
    'wifi-home': 4,
    'trash-square': 4,
    'paper-clip': 0,
    archive: 3,
    pin: 2,
    'wifi-square': 4,
    'auto-brightness': 3,
    coffee: 6,
    icon: 3,
    'emoji-happy': 4,
    'general-mouse': 2,
    ranking: 4,
    slider: 4,
    'crown-2': 3,
    rescue: 2,
    'flash-circle': 2,
    'safe-home': 2,
    'cloud-change': 3,
    crown: 2,
    'filter-edit': 2,
    picture: 2,
    verify: 2,
    send: 2,
    'tag-cross': 3,
    'cloud-add': 2,
    'home-3': 2,
    disk: 2,
    trash: 5,
    star: 0,
    cd: 2,
    'home-2': 2,
    'mouse-circle': 2,
    'home-1': 2,
    call: 8,
    gift: 4,
    share: 6,
    sort: 4,
    magnifier: 2,
    'filter-square': 2,
    tree: 3,
    filter: 2,
    switch: 2,
    cloud: 0,
    cup: 2,
    diamonds: 2,
    status: 3,
    rocket: 2,
    'cloud-download': 2,
    menu: 4,
    chrome: 2,
    'geolocation-home': 2,
    map: 3,
    'telephone-geolocation': 3,
    satellite: 6,
    flag: 2,
    focus: 2,
    pointers: 3,
    compass: 2,
    route: 4,
    geolocation: 2,
    'brifecase-timer': 3,
    briefcase: 2,
    clipboard: 3,
    'bookmark-2': 2,
    note: 2,
    'note-2': 4,
    'book-open': 4,
    book: 4,
    teacher: 2,
    award: 3,
    'brifecase-tick': 3,
    'brifecase-cros': 3,
    bookmark: 2,
    'chart-line': 2,
    chart: 2,
    'graph-3': 2,
    'chart-pie-3': 3,
    'graph-2': 3,
    'chart-line-down': 2,
    'chart-pie-too': 2,
    'chart-pie-4': 3,
    'chart-line-down-2': 3,
    'graph-4': 2,
    'chart-line-up-2': 2,
    badge: 5,
    'chart-line-up': 2,
    'chart-simple-3': 4,
    'chart-pie-simple': 2,
    'chart-simple-2': 4,
    'graph-up': 6,
    'chart-line-star': 3,
    graph: 4,
    'chart-simple': 4,
    'tablet-delete': 3,
    'file-added': 2,
    'file-up': 2,
    'minus-folder': 2,
    file: 2,
    'delete-files': 2,
    'add-folder': 2,
    'file-left': 2,
    'file-deleted': 2,
    'some-files': 2,
    'file-right': 2,
    notepad: 5,
    'notepad-bookmark': 6,
    document: 2,
    'like-folder': 2,
    'folder-up': 2,
    'folder-added': 2,
    'file-down': 2,
    'filter-tablet': 2,
    'tablet-book': 2,
    'update-file': 4,
    'add-notepad': 4,
    'questionnaire-tablet': 2,
    'tablet-up': 3,
    'tablet-ok': 3,
    'update-folder': 2,
    'files-tablet': 2,
    'folder-down': 2,
    'notepad-edit': 2,
    'tablet-text-up': 2,
    'search-list': 3,
    'tablet-text-down': 4,
    'add-files': 3,
    'tablet-down': 3,
    'delete-folder': 2,
    folder: 2,
    'file-sheet': 2,
    bootstrap: 3,
    figma: 5,
    dropbox: 5,
    xaomi: 2,
    microsoft: 4,
    android: 6,
    vue: 2,
    js: 2,
    'spring-framework': 0,
    github: 2,
    dj: 0,
    'google-play': 2,
    angular: 3,
    'soft-3': 2,
    python: 2,
    'soft-2': 2,
    ts: 3,
    xd: 3,
    spotify: 2,
    'js-2': 2,
    laravel: 7,
    css: 2,
    google: 2,
    photoshop: 2,
    twitch: 3,
    illustrator: 4,
    pails: 9,
    react: 2,
    html: 2,
    slack: 8,
    soft: 6,
    yii: 3,
    apple: 2,
    vuesax: 3,
    'calendar-add': 6,
    'calendar-search': 4,
    'calendar-2': 5,
    'calendar-tick': 6,
    time: 2,
    watch: 2,
    'calendar-edit': 3,
    calendar: 2,
    'calendar-8': 6,
    timer: 3,
    'calendar-remove': 6,
    'heart-circle': 2,
    like: 2,
    'information-4': 3,
    'information-5': 3,
    'information-2': 3,
    'information-3': 3,
    question: 3,
    dislike: 2,
    'message-question': 3,
    'medal-star': 4,
    'like-tag': 2,
    'like-2': 2,
    'support-24': 3,
    'question-2': 3,
    lovely: 2,
    'like-shapes': 2,
    heart: 2,
    user: 2,
    'user-square': 3,
    'user-tick': 3,
    people: 5,
    'user-edit': 3,
    'profile-user': 4,
    'profile-circle': 3,
    capsule: 2,
    virus: 3,
    bandage: 2,
    thermometer: 2,
    flask: 2,
    'test-tubes': 2,
    syringe: 3,
    mask: 3,
    pill: 0,
    pulse: 2,
    'burger-menu': 4,
    'burger-menu-6': 0,
    'burger-menu-5': 0,
    'burger-menu-4': 0,
    'burger-menu-1': 4,
    'burger-menu-3': 9,
    'burger-menu-2': 10,
    'text-align-center': 4,
    'text-italic': 4,
    'text-bold': 3,
    'text-strikethrough': 3,
    'text-underline': 3,
    'text-number': 6,
    'text-align-left': 4,
    'text-align-right': 4,
    'text-circle': 6,
    'text-align-justify-center': 4,
    theta: 2,
    dollar: 3,
    binance: 5,
    nexo: 2,
    euro: 3,
    avalanche: 2,
    bitcoin: 2,
    wallet: 4,
    'price-tag': 3,
    'finance-calculator': 7,
    dash: 2,
    lts: 2,
    vibe: 2,
    'credit-cart': 2,
    paypal: 2,
    bill: 6,
    ocean: 19,
    celsius: 2,
    educare: 4,
    'enjin-coin': 2,
    'two-credit-cart': 5,
    bank: 2,
    'binance-usd': 4,
    wanchain: 2,
    trello: 3,
    'save-deposit': 4,
    xmr: 2,
    'financial-schedule': 4,
    'office-bag': 4,
    'night-day': 10,
    sun: 9,
    drop: 2,
    moon: 2,
    'exit-right-corner': 2,
    'dots-circle-vertical': 4,
    'check-square': 2,
    'right-left': 3,
    'arrow-down': 2,
    'dots-horizontal': 3,
    'arrow-right-left': 2,
    'up-down': 3,
    'double-check': 2,
    'arrow-up-left': 2,
    down: 0,
    'exit-up': 2,
    'up-square': 2,
    'down-square': 2,
    'plus-square': 3,
    'dots-circle': 4,
    'arrow-down-left': 2,
    'double-check-circle': 3,
    up: 0,
    'entrance-right': 2,
    'arrow-right': 2,
    'arrow-two-diagonals': 5,
    'minus-square': 2,
    'arrow-diagonal': 3,
    'black-left': 0,
    'arrow-down-refraction': 2,
    'black-right': 0,
    'double-left': 2,
    'arrow-circle-left': 2,
    'arrow-zigzag': 2,
    plus: 0,
    check: 0,
    'exit-left': 2,
    'arrow-circle-right': 2,
    'cross-square': 2,
    'entrance-left': 2,
    'left-square': 2,
    'arrows-loop': 2,
    'black-left-line': 2,
    'double-left-arrow': 2,
    'check-circle': 2,
    right: 0,
    'dots-square-vertical': 4,
    'arrow-up-right': 2,
    'exit-down': 2,
    'dots-square': 4,
    'to-left': 0,
    'double-down': 3,
    'plus-circle': 2,
    'black-down': 0,
    'double-up': 3,
    'black-up': 0,
    'double-right-arrow': 2,
    'arrow-up': 2,
    'black-right-line': 2,
    'arrow-up-refraction': 2,
    'arrow-left': 2,
    cross: 2,
    'minus-circle': 2,
    'arrow-down-right': 2,
    'exit-right': 2,
    'to-right': 0,
    'double-right': 2,
    'arrow-mix': 2,
    'right-square': 2,
    'arrows-circle': 2,
    'cross-circle': 2,
    left: 0,
    minus: 0,
    'dots-vertical': 3,
    'arrow-up-down': 2,
    'message-text-2': 3,
    'message-notif': 5,
    'message-add': 3,
    sms: 2,
    'directbox-default': 4,
    'message-text': 3,
    messages: 5,
    'address-book': 3,
    'message-edit': 2,
    'message-minus': 2,
    'notification-circle': 2,
    'notification-favorite': 3,
    'notification-2': 2,
    notification: 3,
    'notification-bing': 3,
    'notification-status': 4,
    'notification-on': 5,
    'scooter-2': 0,
    parcel: 5,
    'delivery-time': 5,
    delivery: 5,
    'delivery-24': 4,
    ship: 3,
    courier: 3,
    logistic: 7,
    trailer: 5,
    'car-2': 6,
    'car-3': 3,
    'airplane-square': 2,
    scooter: 7,
    truck: 5,
    'cube-3': 2,
    bus: 5,
    'cube-2': 3,
    'delivery-door': 4,
    'delivery-3': 3,
    'delivery-2': 9,
    car: 5,
    'courier-express': 7,
    airplane: 2,
    'delivery-geolocation': 5,
    'parcel-tracking': 3,
    'monitor-mobile': 2,
    devices: 5,
    keyboard: 2,
    'devices-2': 3,
    bluetooth: 2,
    wifi: 4,
    airpod: 3,
    'simcard-2': 2,
    speaker: 3,
    printer: 5,
    simcard: 5,
    router: 2,
    phone: 2,
    'electronic-clock': 4,
    'external-drive': 5,
    laptop: 2,
    tablet: 3,
    screen: 4,
    calculator: 6,
    mouse: 2,
    grid: 2,
    'slider-vertical-2': 3,
    maximize: 5,
    'slider-vertical': 3,
    'row-horizontal': 2,
    kanban: 2,
    'row-vertical': 2,
    'fat-rows': 2,
    'grid-2': 2,
    'element-8': 2,
    'element-9': 2,
    'element-12': 3,
    'element-4': 2,
    'element-5': 2,
    'grid-frame': 3,
    'element-11': 4,
    'element-7': 2,
    'element-6': 2,
    'element-10': 3,
    'element-2': 2,
    'element-3': 2,
    'element-equal': 5,
    'element-1': 4,
    'slider-horizontal-2': 3,
    'slider-horizontal': 3,
    'element-plus': 5,
}

export default icons
